/**
 * 侧边菜单组件
*/
import app from './core.js'

// 工具函数对象
const dom   = app.dom,
	  utils = app.utils;

// 屏幕界限
const INNERWIDTH = 960;

// 缓存实例
let menuModules = {};

/*模块核心*/
const menuCore = function(options){

	// 配置参数
	this.config = {
		id: '',
		elem: '',
		moduleId:'',
		moduleElem: '',
		type: 'single',
		click: ''
	}

	// 初始化
	this.init(options);

	// 添加事件
	this.onClick();
}

// 初始化数据
menuCore.prototype.init = function(options){

	const that = this;

	// 默认参数
	let defaults = {
		id: '',
		elem: '',
		moduleId: '',
		type: 'single',
		click: ''
	}

	// 合并参数
	defaults = utils.merge(defaults,options);

	// 合并配置参数
	that.config = utils.merge(that.config,defaults);

	// 获取组件dom对象
	that.config.moduleElem = that.config.elem;

	if(options.static){

		// 设置组件id
		dom.attr(that.config.elem,'openui-id',that.config.moduleId);
	}

}

// 添加点击事件
menuCore.prototype.onClick = function(){

	const that = this;

	// 获取组件下所有a标签
	let links =	dom.childs(that.config.elem,'.open-menu-link');

	// 遍历a标签
	links.forEach(link=>{

		// 查询兄弟元素
		let next = dom.next(link);

		// 兄弟元素是否存在，并且class是open-submenu
		if(next && utils.existClass(next,'open-submenu')){
			
			// 有下级菜单的a标签事件
			link.onclick = ()=>{

				// 获取a标签父级li标签元素
				let parentLi = dom.parent(link); 

				// 判断当前菜单状态如果是展开
				if(utils.existClass(parentLi,'open-submenu-opened')){

					// 折叠子菜单列表
					that.collapseChildList(parentLi);

					return false;
				}

				// 清除其他按钮样式
				that.removeActive();

				// 添加菜单展开状态以及父级菜单的状态
				that.addActive(parentLi);

				// 打开子列表
				that.openChildList();


				// 单页面模式，禁止a标签跳转
				if(that.config.type !== 'multi')return false;
			}

			return false;
		}

		// 单级菜单点击添加选中状态
		link.onclick = ()=>{

			// 回调函数
			if(that.config.click){

				let url = dom.attr(link,'href');

				that.config.click(url);
			}

			// 如果是移动屏幕，点击之后，隐藏菜单
			if(utils.getInnerWidth() < INNERWIDTH){

				let aside = dom.parent(that.config.moduleElem);
				
				hideMenu(that.config.moduleElem,aside);
			}

			// 当前菜单的顶级菜单
			let topMenu = findCurrentTopMenu(link);

			// 获取a标签父级li标签元素
			let parent = dom.parent(link); 

			// 如果当前菜单是选中状态，则停止执行
			if(utils.existClass(parent,'open-menu-active'))return false;

			// 清除其他按钮样式,但是当前菜单栏除外
			that.removeActive(topMenu);

			// 添加选中状态
			dom.addClass(parent,'open-menu-active');

			// 添加父级选中状态
			// 遍历查找上级openMenuItem菜单元素
			function findPrevMenu(menu,fn){

				// 获取菜单上级ul元素
				let parentUl = dom.parent(menu);

				// 如果ul不是submenu元素，停止继续查找
				if(!utils.existClass(parentUl,'open-submenu')) return false;

				// 如果是submenu元素，继续查找上级li的menuitem元素
				let ulParentLi = dom.parent(parentUl);

				// 如果li不是submenu元素,停止继续查找上级
				if(!utils.existClass(ulParentLi,'open-menu-item')) return false;

				// 如果调用回调函数，返回li菜单元素
				fn(ulParentLi);

				// 继续查找
				findPrevMenu(ulParentLi,fn);
			}

			// 查找到的菜单元素添加展开状态
			findPrevMenu(parent,function(menu){
				dom.addClass(menu,'open-submenu-opened');
			})

			// 单页面模式，禁止a标签跳转
			if(that.config.type !== 'multi')return false;
		}

	})

	// 阻止菜单事件冒泡
	that.config.moduleElem.onclick = function(e){
		e.stopPropagation();
		return false;
	}
}

// 多级菜单添加选中或展开状态
menuCore.prototype.addActive = function(menu){

	// 当前菜单添加展开状态
	dom.addClass(menu,'open-submenu-opened');

	// 遍历查找上级openMenuItem菜单元素
	function findPrevMenu(menu,fn){

		// 获取菜单上级ul元素
		let parentUl = dom.parent(menu);

		// 如果ul不是submenu元素，停止继续查找
		if(!utils.existClass(parentUl,'open-submenu')) return false;

		// 如果是submenu元素，继续查找上级li的menuitem元素
		let ulParentLi = dom.parent(parentUl);

		// 如果li不是submenu元素,停止继续查找上级
		if(!utils.existClass(ulParentLi,'open-menu-item')) return false;

		// 如果调用回调函数，返回li菜单元素
		fn(ulParentLi);

		// 继续查找
		findPrevMenu(ulParentLi,fn);
	}

	// 查找到的菜单元素添加展开状态
	findPrevMenu(menu,function(menuItem){
		dom.addClass(menuItem,'open-submenu-opened');
	})
}

// 清除所有按钮展开状态
menuCore.prototype.removeActive = function(excludeElem=''){

	const that = this;

	// 获取所有一级菜单
	let topMenus = dom.childs(that.config.elem,'.open-menu-wrapper>.open-menu-item');

	// 遍历一级菜单
	topMenus.forEach(menu=>{

		// 排除菜单
		if(menu === excludeElem){

			// 获取一级菜单下列表元素
			let items = dom.childs(menu,'.open-menu-item');

			if(items.length > 0){

				items.forEach(item=>{

					// 清除以及列表选中状态
					clearActive(item);
				})
			}
			
			return false;
		}

		// 清除一级菜单选中状态
		dom.removeClass(menu,'open-menu-active');

		// 清除二级列表展开状态
		dom.removeClass(menu,'open-submenu-opened');

		// 获取一级菜单下列表元素
		let items = dom.childs(menu,'.open-menu-item');

		if(items.length > 0){

			items.forEach(item=>{

				// 清除以及列表选中状态
				clearActive(item);

				// 清除二级列表展开状态
				clearOpened(item);
			})
		}

		// 获取一级菜单下列表
		let lists = dom.childs(menu,'.open-submenu');

		if(lists.length > 0){

			lists.forEach(list=>{

				// 关闭列表
				collapseList(list);
			})
		}
	})
}

// 展开菜单下子菜单列表
menuCore.prototype.openChildList = function(){

	const that = this;

	// 获取所有添加展开状态的菜单
	let menus = dom.childs(that.config.elem,'.open-submenu-opened');

	// 遍历添加展开状态的菜单
	menus.forEach(menu=>{

		// 获取菜单下的子菜单列表
		let childList = dom.child(menu,'.open-submenu');

		// 获取二级列表的内部高度
		let childListScrollHeight = childList.scrollHeight;

		// 设置子菜单高度，展开子列表
		childList.style.height = `${childListScrollHeight}px`;

		// 设置父级菜单高度
		that.setParentHeight(menu);

	})
} 
// 设置父级元素高度
menuCore.prototype.setParentHeight = function(menu){

	let eachParentElem = function(menu){

		// 获取父级列表
		let parent = dom.parent(menu); 

		// 如果父级元素不是菜单列表元素，则停止查找
		if(!utils.existClass(parent,'open-submenu'))return false;

		// 获取列表scrollheight
		let parentSH = getScrollHeight(parent);

		// 子列表scrollheight
		let childSH = 0;

		// 获取菜单下的子菜单
		let childs = dom.child(menu,'.open-submenu');

		// 获取子菜单高度
		if(childs){
			childSH = getScrollHeight(childs);
		}

		// 计算高度
		let height = parentSH + childSH;

		// 设置父级高度
		setElemHeight(parent,height);

		/*************上级dom处理*************/

		// 查找上一级菜单
		let prevMenu = dom.parent(parent);

		if(prevMenu && utils.existClass(prevMenu,'open-menu-item')){
			eachParentElem(prevMenu);
		}

	}

	eachParentElem(menu)
}

// 折叠的子菜单列表
menuCore.prototype.collapseChildList = function(menu){

	// 获取菜单下的子菜单
	let childs = dom.child(menu,'.open-submenu');

	// 去除submenu菜单的展开类
	clearOpened(menu);

	// 恢复子菜单高度设置为0，收起菜单
	collapseList(childs);

	// 查询父级元素
	let eachParentElem = function(menu){

		/*********当前菜单dom操作********/

		// 获取父级元素
		let parent = dom.parent(menu); 

		// 如果父级元素不是菜单列表，则停止查找
		if(!utils.existClass(parent,'open-submenu'))return false;

		// 获取当前列表scrollheight
		let parentSH = getScrollHeight(parent);

		// 子菜单高度
		let childSH = 0;

		// 获取菜单下的子菜单
		let childs = dom.child(menu,'.open-submenu');

		// 获取子菜单高度
		if(childs){
			childSH = getScrollHeight(childs);
		}

		// 获取当前操作后实际列表高度
		let height = parentSH - childSH;

		// 设置元素高度
		setElemHeight(parent,height);

		/*************上级dom处理*************/

		// 查找上一级菜单
		let prevMenu = dom.parent(parent);

		if(prevMenu && utils.existClass(prevMenu,'open-menu-item')){
			eachParentElem(prevMenu);
		}

	}

	// 遍历父级列表元素，重新计算设置高度
	eachParentElem(menu)

	// 获取当前菜单下所有菜单子列表，关闭子列表
	function eachChildElem(menu){

		// 获取子列表
		let childs = dom.childs(menu,'.open-submenu-opened');

		if(childs.length > 0){

			childs.forEach(child=>{

				// 清除展开样式
				clearOpened(child);

				// 获取子列表
				let childList = dom.child(child,'.open-submenu');

				// 初始化子列表高度
				setElemHeight(childList,0);
			})
		}
	}

	eachChildElem(menu);

}
// 折叠当前菜单列表,高度为0
function collapseList(list){
	list.style.height = '0px';
}
// 清除展开样式
function clearOpened(menu){
	dom.removeClass(menu,'open-submenu-opened');
}
// 清除展开样式
function clearActive(menu){
	dom.removeClass(menu,'open-menu-active');
}
// 获取元素scrollHeight
function getScrollHeight(elem){
	return elem.scrollHeight;
}
// 设置元素高度
function setElemHeight(elem,height){
	elem.style.height = `${height}px`;
}
// 根据当前点击的菜单，遍历查找菜单父级的一级菜单
function findCurrentTopMenu(menu){
	// 当前元素的最近父级菜单子项目
	let parent = dom.parent(menu).closest('.open-menu-item');

	// 如果元素已经是顶级元素，返回item
	if(utils.existClass(dom.parent(parent),'open-menu-wrapper')){
		return parent;
	}else{
		return findCurrentTopMenu(parent);
	}
}

// 组件对象
const menu = {

}

// 渲染
menu.render = ()=>{

}

// 静态渲染
menu.renderStatic = ()=>{

	// 获取页面所有菜单class
	let menus = dom.getAll('.open-menu');

	// 如果页面没有静态组件，中止程序
	if(menus.length < 1) return false;

	// 遍历组件
	menus.forEach(item=>{

		// 生成组件id 
		const templateId = utils.randomId();

		// 自定义参数
		let options = {
			id: templateId,
			moduleId: templateId,
			elem: item,
			static: true
		}

		// 实例化组件
		let instance = new menuCore(options);

		// 缓存实例
		menuModules[templateId] = instance;

	})
}


// 事件监听
menu.on = function(options){

	if(!options || typeof options !== 'object' ) return false;

	if(!options.id) return false;

	// 获取元素
	let elem = dom.get(utils.formatId(options.id));

	if(!elem) return false;

	// 获取组件模版id
	let templateId = dom.attr(elem,'openui-id');

	let instance = menuModules[templateId];

	instance.config.click = options.click;
}

// 菜单横向折叠
menu.collapse = function(id){

	if(!id) return false;

	// 获取视图宽度
	let width = utils.getInnerWidth();

	// 获取元素
	let elem = dom.get(utils.formatId(id));

	// 如果是大屏
	if(width > 960){

		if(utils.existClass(elem,'open-menu-collapse')){
			dom.removeClass(elem,'open-menu-collapse');
		}else{
			dom.addClass(elem,'open-menu-collapse');
		}
	}else{

		// 获取父组件aside容器
		let aside = dom.parent(elem);

		// 如果aside已经显示，清除显示样式 
		if(utils.existClass(aside,'open-aside-react-show')){
			hideMenu(elem,aside);
		}else{
			showMenu(elem,aside)
		}

		// aside容器添加点击事件
		aside.onclick = function(){
			// 隐藏菜单
			hideMenu(elem,aside);
		}
	}

}

// 监听屏幕宽度变化
// window.addEventListener('resize', function(e){

// 	// 获取视图宽度
// 	let width = e.currentTarget.innerWidth;
	
// 	// 宽屏时
// 	if(width < 960){

// 	}else{

// 		// 获取页面菜单组件
// 		let menus = dom.getAll('.open-menu');

// 		if(menus.length < 1)return false;

// 		menus.forEach(menu=>{

// 			// 获取菜单容器aside
// 			let aside = dom.parent(menu);

// 			if(utils.existClass(aside,'open-aside-show') || utils.existClass(menu,'open-menu-show')){
// 				// 隐藏菜单
// 				hideMenu(menu,aside);
// 			}
// 		})
// 	}
// })

/**
 * 显示菜单
 * @param elem:HtmlElement 菜单组件对象
 * @param container:HtmlElement 菜单组件容器对象
*/
function showMenu(elem,container){
	dom.addClass(container,'open-aside-react-show');
	dom.addClass(elem,'open-menu-react-show');
}

/**
 * 隐藏菜单
 * @param elem:HtmlElement 菜单组件对象
 * @param container:HtmlElement 菜单组件容器对象
*/
function hideMenu(elem,container){
	dom.addClass(container,'open-aside-react-hide');
	dom.addClass(elem,'open-menu-react-hide');

	setTimeout(function(){
		dom.removeClass(container,'open-aside-react-show');
		dom.removeClass(elem,'open-menu-react-show');
		dom.removeClass(container,'open-aside-react-hide');
		dom.removeClass(elem,'open-menu-react-hide');
	}, 200)
}

export default menu;

