import {onload,reload,use} from '@/modules/core.js'
// import backtop from '@/modules/backtop.js'
// import router from '@/modules/router.js'
import navbar from '@/modules/navbar.js'
import menu from '@/modules/menu.js'
import breadcrumb from '@/modules/breadcrumb.js'
import tag from '@/modules/tag.js'
import dropdown from '@/modules/dropdown.js'
import form from '@/modules/form.js'
import former from '@/modules/former.js'
import calendar from '@/modules/calendar.js'
import opener from '@/modules/opener.js'
import upload from '@/modules/upload.js'
import table from '@/modules/table.js'
import pagination from '@/modules/pagination.js'
import progress from '@/modules/progress.js'
import tree from '@/modules/tree.js'
import tab from '@/modules/tab.js'
import select from '@/modules/select.js'
import captcha from '@/modules/captcha.js'
import editer from '@/modules/editer.js'
import slot from '@/modules/slot.js'
import template from '@/modules/template.js'
import eler from '@/modules/eler.js'
// import validater from '@/modules/validater.js'

const openui = {
	onload: onload,
	reload: reload,
	use: use,
	// backtop: backtop,
	// router: router,
	navbar: navbar,
	menu: menu,
	breadcrumb: breadcrumb,
	tag: tag,
	dropdown: dropdown,
	form: form,
	former: former,
	calendar: calendar,
	opener: opener,
	upload: upload,
	table: table,
	pagination: pagination,
	progress: progress,
	tree: tree,
	tab: tab,
	select: select,
	captcha: captcha,
	editer: editer,
	slot: slot,
	template: template,
	eler: eler,
	// validater: validater,
}

export {
	onload,
	reload,
	use,
	// backtop,
	navbar,
	menu,
	breadcrumb,
	tag,
	dropdown,
	form,
	former,
	calendar,
	opener,
	upload,
	table,
	pagination,
	progress,
	tree,
	tab,
	select,
	captcha,
	editer,
	slot,
	template,
	eler,
	// validater,
	openui as default
}

