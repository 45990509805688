/**
 * 导航菜单
*/
import { dom,utils } from './core.js'

class navbar{

	// 插件描述
	static name = "navbar";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发导航栏";
	static date = "2024-07-05";

	// 缓存实例
	static #modules = {};

	// 渲染
	static render(){

	}

	// 静态渲染
	static auto(id){

		if(id && typeof id !== 'string' )return false;

		// 获取di对应元素
		let elem = dom.get(id);

		// 如果元素存在
		if(elem){

			// 渲染指定组件
			navbar.#renderSingle(id);
		}else{

			// 渲染所有符合条件组件
			navbar.#renderAll();
		}
	}

	// 渲染指定组件
	static #renderSingle(id){

		// 检测id是否是dom对象类型 
		let isDom = id instanceof HTMLElement;

		// 获取元素对象
		let navbarElem;

		// 如果id不是dom对象
		if(!isDom){
			navbarElem = dom.get(id)
		}else{
			navbarElem = id;
		}

		if(!navbarElem && !isDom)return false;

		// 检测当前组件是否涉及重复渲染
    	let openuiId = dom.attr(navbarElem,'openui-id');
    
    	// 应对vue框架等组件切换重复渲染处理
    	if(openuiId) return false;

    	// 获取元素子组件
    	let childs = navbarElem.children;

    	if(childs.length < 1)return false;

        // 获取组件用户自定义id
        let customId = dom.attr(navbarElem,'id') || '';

        // 配置参数
        let options = {
        	id: customId, // 用户自定义属性id
        }

        // 创建实例
        navbar.#create(options,navbarElem);

	}

	// 渲染所有符合条件组件
	static #renderAll(){

		// 获取页面所有静态组件
	    let navbars = dom.getAll('.open-navbar');

	    // 如果页面没有静态组件，则中止渲染
	    if(navbars.length < 0) return false;

	    // 遍历静态组件
	    navbars.forEach(item=>{

	    	navbar.#renderSingle(item);
	    })
	}

	// 监听点击事件
	static on(id,fn){

		let elem = dom.get(id)

		// id获取元素不存在
		if(!id || !elem) return false;

		if(fn && typeof fn !== 'function') return false;

		// 获取组件模版id
		let openid = dom.attr(elem,'openui-id');

		// 静态模式未获取，则动态模式下获取
    	if(!openid){
        	openid = dom.attr(dom.child(elem,'.open-pagination'),'openui-id');
    	}

		let instance = navbar.#modules[openid];

		instance.config.on = fn;
	}
	
	/**
	* 创建实例
	* @param options:object 参数
	* @param elem: object 静态组件dom对象
	*/
	static #create(options,elem){

		// 生成组件id
	    const templateId = utils.randomId();

	    /**
		 * 合并参数
		 * @attr data.custom: object 自定义数据
		 * @attr data.system: object 系统数据
		*/
	    const data = {
	    	custom: options,
	    	system: {
	    				moduleId:templateId,
	    				staticElem: elem
	    			}
	    };

	    // 创建实例,保存实例
		navbar.#modules[templateId] = new navbar(data);
	}

	// 构造方法
	constructor(options){

		const that = this;

		// 配置参数
		that.config = {
			id: '',
			elem: '',
			moduleId: '',
			moduleElem: '',
			menuElem: '',
			static: false,
			mode: 'multi', // 模式：single单页模式|multi多页模式，默认multi
			click: '',
			on: ''
		}

		// 初始化
		that.#init(options);

		that.#renderElem();

		// 添加事件
		that.#onMaxEvent();

		// 移动端菜单
		that.#tree();

		// 添加事件
		that.#onMiniEvent();

	}

	// 初始化
	#init(options){

		// 缓存实例
		const that = this;

		// 默认参数
		let defaults = {
			id: '',
			mode: '',
			click: ''
		}

		/** 
		 * 解构参数
		 * custom:object 自定义参数
		 * system:object 系统参数
		*/
		let {custom,system} = options;

        // 合并用户自定义参数
        defaults = utils.merge(defaults,custom);

        // 如果是静态组件
        if(system.staticElem){

        	// 当前组件为静态组件
        	that.config.static = true;

        	let config = {
				...defaults,
				moduleId: system.moduleId,
				moduleElem: system.staticElem,
				elem: system.staticElem,
			}

			// 获取类型
			config.mode = dom.attr(config.elem,'open-navbar-mode') || 'multi';

			config.menuElem = dom.child(config.elem,'.open-navbar-menu');

			// 合并配置参数
        	that.config = utils.merge(that.config,config); 

        	// 设置组件id
        	dom.attr(that.config.elem,'openui-id',that.config.moduleId);

        }

	}

	// 移动端菜单
	#tree(){

		const that = this;

		// 创建移动端菜单列表容器
		const miniContainer = document.createElement('div');
		miniContainer.className = 'open-navbar-tree-container'; 

		// 创建title栏
		let treeHeader = `<div class="open-navbar-tree-header">
			<span class="open-navbar-close"></span>
		</div>`;


		// 获取菜单列表元素
		const menu = that.config.menuElem.cloneNode(true);
		menu.className = 'open-navbar-tree';

		// 添加title到容器
		dom.addFirstChild(menu,treeHeader);

		// 添加菜单列表到容器
		miniContainer.appendChild(menu);

		// 添加容器到页面
		that.config.elem.appendChild(miniContainer);

	}

	// 渲染dom
	#renderElem(){

		const that = this;

		// 创建open按钮
		let open = document.createElement('div');
		open.className = "open-navbar-open";

		that.config.elem.appendChild(open);
	}

	// 添加pc事件监听
	#onMaxEvent(){

		// 缓存实例
		const that = this;
		that.#onMaxDropdown();
		that.#addMaxClick();
	}

	// 添加移动端事件监听
	#onMiniEvent(){

		// 缓存实例
		const that = this;
		that.#onMiniDropdown();
		that.#addMiniClick();
		that.#toggleMenuList()
	}

	// 添加点击事件
	#addMaxClick(){

		// 缓存实例
		const that = this;

		// 获取menu列表
		let menuList = that.config.moduleElem.querySelector('.open-navbar-menu');

		// 获取所有a标签
		let linkList = menuList.querySelectorAll('a');

		// 遍历a标签
		linkList.forEach((link,index)=>{

			// 获取下一个兄弟是否是列表元素
			let list = dom.next(link);

			// 如果没有下拉列表，添加点击事件
			if(!list){

				// 添加link下标
				link.setAttribute('open-navbar-index',index);

				// 如果是单页模式
				if(that.config.mode === 'single'){

					// 转换link
					that.#changeSingleLink(link);

					// 添加点击事件
					link.onclick = function(){

						// 清除样式
						that.#removeActive();

						// 获取同下标链接
						let index = dom.attr(link,'open-navbar-index');

						// 获取相同下标的a标签
						let links = dom.childs(that.config.elem,`[open-navbar-index="${index}"]`);

						// 同步选中状态
						links.forEach(item=>{

							let parent = dom.parent(item);

							// 添加选中样式
							dom.addClass(parent,'open-navbar-active');
						})

						if(that.config.mode === 'single'){

							// 获取当前a标签点击的路由信息
							let url = {
								name: link.innerText,
								url: dom.attr(link,'open-navbar-href')
							}

							that.config.click && typeof that.config.click == 'function' && that.config.click(url);
							that.config.on && typeof that.config.on == 'function' && that.config.on(url);
						}
					}
				}
			}
		})

	}

	// 添加下拉框显示事件
	#onMaxDropdown(){

		// 缓存实例
		const that = this;

		// 获取所有二级菜单
		let subnavs = dom.childs(that.config.elem,'.open-subnav');

		// 遍历二级菜单
		subnavs.forEach(subnav=>{

			// 获取二级菜单父元素
			let parent = dom.parent(subnav);

			// 如果父元素是子级元素
			if(utils.existClass(parent,'open-navbar-menu-item')){

				// 添加列表的hover事件
				parent.onmouseenter = function(){

					dom.addClass(subnav,'open-subnav-show');

				}

				// 添加列表的leave事件
				parent.onmouseleave = function(){

					dom.removeClass(subnav,'open-subnav-show');
				}
			}

		})
	}

	// 移动端打开关闭菜单列表
	#toggleMenuList(){

		const that = this;

		// 打开按钮
		let open = document.querySelector('.open-navbar-open');

		// 关闭按钮
		let close = document.querySelector('.open-navbar-close');

		open.onclick = function(){

			// 获取tree容器列表
			let treeContainer = dom.child(that.config.elem,'.open-navbar-tree-container');

			// 获取tree列表
			let tree = dom.child(that.config.elem,'.open-navbar-tree');

			dom.addClass(treeContainer,'open-navbar-tree-show');

			dom.addClass(tree,'open-navbar-menu-show');
		}

		close.onclick = function(){

			// 获取tree容器列表
			let treeContainer = dom.child(that.config.elem,'.open-navbar-tree-container');

			// 获取tree列表
			let tree = dom.child(that.config.elem,'.open-navbar-tree');

			dom.addClass(treeContainer,'open-navbar-tree-hide');

			dom.addClass(tree,'open-navbar-menu-hide');

			setTimeout(function(){

				dom.removeClass(treeContainer,'open-navbar-tree-show');
				dom.removeClass(treeContainer,'open-navbar-tree-hide');
			
				dom.removeClass(tree,'open-navbar-menu-show')
				dom.removeClass(tree,'open-navbar-menu-hide')
			},200)

		}

	}

	// 添加移动端点击事件
	#addMiniClick(){

		// 缓存实例
		const that = this;

		// 获取移动端菜单列表
		let tree = that.config.elem.querySelector('.open-navbar-tree');

		// 获取所有a标签
		let linkList = tree.querySelectorAll('a');

		// 遍历a标签
		linkList.forEach((link,index)=>{

			// 获取下一个兄弟是否是列表元素
			let list = dom.next(link);

			// 如果没有下拉列表，添加点击事件
			if(!list){

				// 如果是单页模式
				if(that.config.mode === 'single'){

					// 转换link
					that.#changeSingleLink(link);

					// 添加点击事件
					link.onclick = function(){

						// 清除样式
						that.#removeActive();

						// 获取同下标链接
						let index = dom.attr(link,'open-navbar-index');

						// 获取相同下标的a标签
						let links = dom.childs(that.config.elem,`[open-navbar-index="${index}"]`);

						// 同步选中状态
						links.forEach(item=>{

							let parent = dom.parent(item);

							// 添加选中样式
							dom.addClass(parent,'open-navbar-active');
						})

						if(that.config.mode === 'single'){

							// 获取当前a标签点击的路由信息
							let url = {
								name: link.innerText,
								url: dom.attr(link,'open-navbar-href')
							}

							that.config.click && typeof that.config.click == 'function' && that.config.click(url);
							that.config.on && typeof that.config.on == 'function' && that.config.on(url);
						}
						
					}
				}
			}
		})

	}

	// 添加下拉框显示事件
	#onMiniDropdown(){

		// 缓存实例
		const that = this;

		// 获取移动端菜单
		let treeMenu = dom.child(that.config.elem,'.open-navbar-tree');

		// 获取所有二级菜单
		let subnavs = dom.childs(treeMenu,'.open-subnav');

		// 遍历二级菜单
		subnavs.forEach(subnav=>{

			// 获取二级菜单父元素
			let parent = dom.parent(subnav);

			// 前一个兄弟元素
			let prevElem = dom.prev(subnav);

			// 如果父元素是子级元素
			if(utils.existClass(parent,'open-navbar-menu-item')){

				// 添加列表的hover事件
				prevElem.onclick = function(){

					if(utils.existClass(subnav,'open-tree-subnav-show')){
						dom.removeClass(subnav,'open-tree-subnav-show');
					}else{
						dom.addClass(subnav,'open-tree-subnav-show');
					}
				}
			}

		})
	}

	// single模式下，修改href值为open-navbar-href
	#changeSingleLink(link){

		// 获取url
		let url = dom.attr(link,'href');

		// 添加自定义属性
		dom.attr(link,'open-navbar-href',url);

		// 隐藏原始href值
		dom.attr(link,'href','#');
	}

	// 清除菜单选中样式
	#removeActive(){

		const that = this;

		const links = dom.childs(that.config.elem,'a');

		links.forEach(link=>{

			let parent = dom.parent(link);
		
			dom.removeClass(parent,'open-navbar-active');

		})
	}

}

export default navbar;