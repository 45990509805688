/**
 * dom操作插件
*/

class Eler{

	// 单例模式
	static instance;
	constructor(){
		if(!Eler.instance){
			Eler.instance = this;
		}
		return Eler.instance;
	}

	create(tag,props,child){

		if(!tag) return false;

		// 创建元素
		if(tag){
			tag = document.createElement(`${tag}`);
		}

		if(props && typeof props == 'object'){

			// 设置属性
			Object.keys(props).forEach(function(prop){

				console.log(prop)

				// 设置data属性
				if(prop.indexOf('data-') !== -1){
					tag.setAttribute(prop,props[prop]);
				}

				// 设置id
				if(prop === 'id'){
					tag.id = props.id;
				}

				// 设置class
				if(prop === 'class'){
					tag.className = props.class;
				}

				// 设置href
				if(prop === 'href'){
					tag.href = prop.href;
				}

				// 设置target
				if(prop === 'target'){
					tag.target = prop.target;
				}

				// 设置src
				if(prop === 'src'){
					tag.src = prop.src;
				}

				// 设置alt
				if(prop === 'alt'){
					tag.alt = prop.alt;
				}

				// 设置title
				if(prop === 'title'){
					tag.title = prop.title;
				}

				// 设置事件
				if(prop === 'onClick'){

				}
			})

			if(child){

				if(Array.isArray(child)){

					child.forEach(function(item){

						tag.appendChild(item);
					})
				}

				if(typeof child == 'string'){
					tag.innerText = child;
				}
			}
		}

		if(props && !child && Array.isArray(props)){

			child = props;

			if(child && Array.isArray(child)){

				child.forEach(function(item){

					tag.appendChild(item);
				})
			}
		}

		return tag;

	}

	// 获取元素
	get(selector){
		return document.querySelector(selector);
	}

	// 获取元素集合
	getAll(selector){
		return document.querySelectorAll(selector);
	}

	// 设置或获取元素html
	html(el,html){
		// 如果html存在，则设置html
		if(html){
			el.innerHTML = html;
			return;
		}
		return el.innerHTML
	}

	// 设置或获取元素text
	text(el,text){
		// 如果text存在，则设置text
		if(text){
			el.innerText = text;
			return;
		}
		return el.innertext
	}

	/**
	 * 获取父元素
	 * @param elem: HTMLElement 元素对象
	 * @return HTMLElement 返回dom元素的父元素
	*/
	parent(el){
		return elem.parentNode;
	}

	// 查找指定父级元素,返回最近查找到的
	closest(el,selector){
		return el.closest(selector);
	}

	// 获取上一个兄弟元素
	prev(el){
		return elem.previousElementSibling;
	}

	// 获取下一个兄弟元素
	next(el){
		return elem.nextElementSibling;
	}

	/**
	 * 获取所有子元素
	 * @param elem: HTMLElement 元素对象
	 * @param child: String 子元素的选择器
	 * @return HTMLElement 返回查询到的dom元素
	*/
	childs(el,child){
		return elem.children;
	}

	/**
	 * 查找指定子元素
	 * @param elem: HTMLElement 元素对象
	 * @param selector: String 子元素的选择器
	 * @return HTMLElement 返回查询到的dom元素
	*/
	find(el,selector){
		return elem.querySelector(selector);
	}

	/**
	 * 查找指定子元素集合
	 * @param elem: HTMLElement 元素对象
	 * @param selector: String 子元素的选择器
	 * @return HTMLElement 返回查询到的dom元素
	*/
	finds(el,selector){
		return elem.querySelectorAll(selector);
	}

	/**
	 * 添加子元素到子元素首尾
	 * @param el:HTMLElement 当前操作元素
	 * @param newEl:HTMLElement 插入的元素
	 * @param type:booleam 是否开启文本字符串元素，默认false
	*/
	prepend(el,newEl,type){
		if(type === false){
			el.insertAdjacentHTML('afterbegin',newEl);
			return;
		}
		el.insertBefore(newEl,el.children[0]);
	}

	/**
	 * 添加子元素到子元素末尾
	 * @param el:HTMLElement 当前操作元素
	 * @param newEl:HTMLElement 插入的元素
	 * @param type:booleam 是否开启文本字符串元素，默认false
	*/
	append(el,newEl,type=false){
		if(type === true){
			el.insertAdjacentHTML('beforeend',newEl);
			return;
		}
		el.appendChild(child);
	}

	/**
	 * 添加元素到当前元素前面
	 * @param el:HTMLElement 当前操作元素
	 * @param newEl:HTMLElement 插入的元素
	 * @param type:booleam 是否开启文本字符串元素，默认false
	*/ 
	before(el,newEl,type){
		if(type === true){
			el.insertAdjacentHTML('beforebegin',newEl);
			return;
		}
		el.parentNode.insertBefore(newEl,el);
	}

	/**
	 * 添加元素到当前元素后面
	 * @param el:HTMLElement 当前操作元素
	 * @param newEl:HTMLElement 插入的元素
	 * @param type:booleam 是否开启文本字符串元素，默认false
	*/ 
	after(el,newEl,type){
		if(type === true){
			el.insertAdjacentHTML('afterend',newEl);
			return;
		}
		el.parentNode.insertBefore(newEl,el.nextSibling);
	}

	/**
	 * 替换元素
	 * @param el:HTMLElement 当前操作元素
	 * @param newEl:HTMLElement 插入的新元素
	 * @param type:booleam 是否开启文本字符串元素，默认false
	*/ 
	replace(el,newEl,type){
		if(type === true){
			// 创建新元素
			let div = document.createElement('div');
			div.appendChild(newEl);
			newEl = div;
		}
		el.replaceWith(newEl);
	}

	// 删除元素
	remove(el){
		el.remove();
	}

	// 删除子元素
	removeChild(el,child){
		el.removeChild(child)
	}

	// 清空元素
	empty(el){
		el.innderHTML = '';
	}

	// 隐藏元素
	hide(el){
		el.style.display = 'none';
	}

	// 显示元素
	show(el){
		el.style.display = 'block';
	}

	// 设置或获取元素宽度度
	width(el,val){
		// 如果值存在，设置值
		if(val){
			// 检测是否添加px后缀
			if(val.indexOf('px') == -1){
				val = `${val}px`;
			}
			el.style.width = val;
			return;
		}
		return el.offsetWidth;
	}

	// 设置或获取元素高度
	height(el,val){
		// 如果值存在，设置值
		if(val){
			// 检测是否添加px后缀
			if(val.indexOf('px') == -1){
				val = `${val}px`;
			}
			el.style.height = val;
			return;
		}
		return el.offsetHeight;
	}

	

	// 设置css样式
	css(el,style){

		let css = '';

		Object.keys(style).forEach(function(key) {
			
			let value = style[key];

			css += `${key}:${value};`
		});

		el.style.cssText += css;
	}

	// 设置或获取元素属性值
	attr(el,name,val){
		// 如果值存在，则设置值
		if(val && typeof val === 'string'){
			// 设置元素属性值
			el.setAttribute(name, val);
			return;
		}
		// 返回获取的属性值
		return el.getAttribute(name);
	}

	// 检测元素是否有指定属性
	hasAttr(el,attr){
		return el.hasAttribute(attr);
	}

	// 删除元素属性
	removeAttr(el,name){
		el.removeAttribute(name);
	}

	// 获取或设置元素class样式名
	class(el,name){
		// 如果name存在，则设置样式名
		if(name){
			el.className = name;
			return;
		}
		return el.classList.toString();
	}

	// 添加元素class样式名
	addClass(el,name){
		el.classList.add(name);
	}

	// 删除元素class样式名
	removeClass(el,name){
		el.classList.remove(name);
	}

	// 检测元素class样式名是否存在
	hasClass(el,name){
		return el.classList.contains(name);
	}

	// 切换元素class名
	toggleClass(el,name){
		// 检查当前元素样式名是否存在
		if(this.hasClass(el,name)){
			this.removeClass(el,name);
		}else{
			this.addClass(el,name);
		}
	}

}


const eler = new Eler;


export default eler;