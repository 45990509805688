/**
 * 日历组件
*/
import app from './core.js'

// 工具函数对象
const dom = app.dom;
const utils = app.utils;

// 缓存实例
let modules = {};

// 视图模版
var view = {
	// 组件渲染
	render: function(options){

		const that = this;

		// 创建周标题
		let week = that.initWeekTitle(options);

		// 创建上月天数差别
		let prev = that.initPrevMonthDay(options);

		// 创建下月天数差别
		let next = that.initNextMonthDay(options);

		// 创建当前月天数
		let current = that.initCurrentMonthDay(options);

		// 拼接组件模版

		let days = `${prev} ${current} ${next}`

		let template = that.initTemplate(week,days,options);

		if(options.type == 'static'){

			dom.addChild(options.elem,template);

			return false;
		}

		dom.addChild(document.body,template);
	},

	// 内容渲染
	reload: function(options){

		const that = this;

		// 创建上月天数差别
		let prev = that.initPrevMonthDay(options);

		// 创建下月天数差别
		let next = that.initNextMonthDay(options);

		// 创建当前月天数
		let current = that.initCurrentMonthDay(options);

		// 拼接日期列表模版
		let days = `${prev} ${current} ${next}`

		let listElem = dom.child(options.moduleElem,'.open-calendar-day-list');
		
		listElem.innerHTML = '';

		dom.addChild(listElem,days);
	},

	/**
	 * 初始化周标题
	 * @param context:object  上下文对象，默认config配置参数
	 * @return html:string 返回组合后的dom字符串
	*/
	initWeekTitle: function(context){
		// dom缓存变量
		let vweek = '';

		// 遍历标题数组
		context.weeks.forEach(function(item){
			// 拼接dom
			vweek += `<span>${item}</span>`;
		})
		// 返回元素
		return vweek;
	},

	/**
	 * 初始化上月剩余天数
	 * @param context:object 上下文对象，默认config配置参数
	 * @return html:string 返回组合后的dom字符串
	*/
	initPrevMonthDay: function(context){

		// dom缓存变量
		let vprev = '';

		// 遍历标题数组
		context.prevDay.forEach(function(prev){

			// 合并拼接日期
			let date = mergeTime(context.year,context.month - 1,prev);

			// 判断开始和截止日期
			if(context.end || context.start){

				// 开始时间
				let startDate = '';

				// 结束时间
				let endDate = ''

				// 判断标识
				let start = context.start ? true : false;
				let end   = context.end ? true : false;

				// 获取当前时间
				let currentDay = getCurrentDate();

				// 计算开始时间戳
				if(start){
					if(context.start == 'today'){
						startDate = convertToTimestamp(currentDay.year,currentDay.month,currentDay.day);
					}else{
						let startArr = context.start.split('-')
						startDate = convertToTimestamp(startArr[0],startArr[1],startArr[2]);
					}
				}

				// 计算结束时间戳
				if(end){
					if(context.end == 'today'){
						endDate = convertToTimestamp(currentDay.year,currentDay.month,currentDay.day);
					}else{
						let endArr = context.end.split('-');
						endDate = convertToTimestamp(endArr[0],endArr[1],endArr[2]);
					}
				}

				// 当前遍历日期
				let time = convertToTimestamp(context.year,context.month - 1,prev);

				// 开始范围
				if(start && time < startDate){

					// 拼接dom
					vprev += `<span class="open-calendar-prev open-calendar-disabled">${prev}</span>`;
				}else if(end && time > endDate){
					// 结束范围
					// 拼接dom
					vprev += `<span class="open-calendar-prev open-calendar-disabled">${prev}</span>`;

				}else{
					// 拼接dom
					vprev += `<span class="open-calendar-prev" data-date="${date}" >${prev}</span>`;
				}
			}else{

				// 拼接dom
				vprev += `<span class="open-calendar-prev" data-date="${date}">${prev}</span>`;
			}

		})

		// 返回元素
		return vprev;
	},

	/**
	 * 初始化下月显示天数
	 * @param context:object 上下文对象，默认config配置参数
	 * @return html:string 返回组合后的dom字符串
	*/
	initNextMonthDay: function(context){

		// dom缓存变量
		let vnext = '';

		// 遍历标题数组
		context.nextDay.forEach(function(next){

			// 合并拼接日期
			let date = mergeTime(context.year,context.month + 1,next);

			// 判断截止日期
			if(context.end || context.start){

				// 开始时间
				let startDate = '';

				// 结束时间
				let endDate = ''

				// 判断标识
				let start = context.start ? true : false;
				let end   = context.end ? true : false;

				// 获取当前时间
				let currentDay = getCurrentDate();

				// 计算开始时间戳
				if(start){
					if(context.start == 'today'){
						startDate = convertToTimestamp(currentDay.year,currentDay.month,currentDay.day);
					}else{
						let startArr = context.start.split('-')
						startDate = convertToTimestamp(startArr[0],startArr[1],startArr[2]);
					}
				}

				// 计算结束时间戳
				if(end){
					if(context.end == 'today'){
						endDate = convertToTimestamp(currentDay.year,currentDay.month,currentDay.day);
					}else{
						let endArr = context.end.split('-');
						endDate = convertToTimestamp(endArr[0],endArr[1],endArr[2]);
					}
				}

				// 当前遍历日期
				let time = convertToTimestamp(context.year,context.month + 1,next);

				// 开始范围
				if(start && time < startDate){
					// 拼接dom
					vnext += `<span class="open-calendar-next open-calendar-disabled">${next}</span>`;
				}else if(end && time > endDate){

					// 结束范围
					// 拼接dom
					vnext += `<span class="open-calendar-next open-calendar-disabled">${next}</span>`;
				}else{
					// 拼接dom
					vnext += `<span class="open-calendar-next" data-date="${date}" >${next}</span>`;
				}

			}else{

				// 拼接dom
				vnext += `<span class="open-calendar-next" data-date="${date}">${next}</span>`;
			}
		})

		// 返回元素
		return vnext;
	},

	/**
	 * 初始化当前月天数
	 * @param currents:array 上月剩余天数的数组 
	 * @param context:object 上下文对象，默认config配置参数
	 * @return html:string 返回组合后的dom字符串
	*/
	initCurrentMonthDay: function(context){

		// dom缓存变量
		let vcurrent = '';

		let currents = context.monthDays;

		// 遍历标题数组
		for(let i = 1;i < currents + 1; i ++){

			// 合并拼接日期
			let date = mergeTime(context.year,context.month,i);

			// 判断截止日期
			if(context.end || context.start){
				// 开始时间
				let startDate = '';
				// 结束时间
				let endDate = ''
				// 判断标识
				let start = context.start ? true : false;
				let end   = context.end ? true : false;
				// 获取当前时间
				let currentDay = getCurrentDate();
				// 计算开始时间戳
				if(start){
					if(context.start == 'today'){
						startDate = convertToTimestamp(currentDay.year,currentDay.month,currentDay.day);
					}else{
						let startArr = context.start.split('-')
						startDate = convertToTimestamp(startArr[0],startArr[1],startArr[2]);
					}
				}
				// 计算结束时间戳
				if(end){
					if(context.end == 'today'){
						endDate = convertToTimestamp(currentDay.year,currentDay.month,currentDay.day);
					}else{
						let endArr = context.end.split('-');
						endDate = convertToTimestamp(endArr[0],endArr[1],endArr[2]);
					}
				}
				// 当前遍历日期
				let time = convertToTimestamp(context.year,context.month,i);
				// 设置开始范围
				if(start && time < startDate){
					// 拼接dom
					vcurrent += `<span class="open-calendar-current open-calendar-disabled">${i}</span>`;
				}else if(end && time > endDate){
					// 设置结束范围
					// 拼接dom
					vcurrent += `<span class="open-calendar-current open-calendar-disabled">${i}</span>`;
				}else {
					// 判断显示日期是否和值相同
					if(date == context.value){
						// 拼接dom,选中状态，默认当前日期添加选中状态
						vcurrent += `<span class="open-calendar-current open-calendar-active" data-date="${date}">${i}</span>`;
					}else{
						// 拼接dom
						vcurrent += `<span class="open-calendar-current" data-date="${date}">${i}</span>`;
					}
				}
			}else{
				// 判断显示日期是否和值相同
				if(date == context.value){
					// 拼接dom,选中状态，默认当前日期添加选中状态
					vcurrent += `<span class="open-calendar-current open-calendar-active" data-date="${date}">${i}</span>`;
				}else{
					// 拼接dom
					vcurrent += `<span class="open-calendar-current" data-date="${date}">${i}</span>`;
				}
			}
		}

		// 返回元素
		return vcurrent;
	},
	/**
	 * 初始化组件dom
	 * @param weekElem:string 一周标题dom元素
	 * @param dateElem:string 日期列表元素
	 * @return vtemplate:string 返回组合后的dom字符串
	*/
	initTemplate: function(weekElem,dateElem,options){

		// dom缓存变量
		let vtemplate =  `<div class="open-calendar" id="${options.id}">
					        <div class="open-calendar-header">
					            <div class="open-calendar-icon">
					                <span class="open-calendar-prev-year"></span>
					               	<span class="open-calendar-prev-month"></span>
					            </div>
					            <div class="open-calendar-date-title"></div>
					            <div class="open-calendar-icon">
					                <span class="open-calendar-next-month"></span>
					                <span class="open-calendar-next-year"></span>
					            </div>
					        </div>
					        <div class="open-calendar-box">
					            <div class="open-calendar-box-header">
					             ${weekElem}
					            </div>
					            <div class="open-calendar-day-list">
					               ${dateElem}
					            </div>
					        </div>
					    </div>`;

		return vtemplate;
	}
}

// 模块核心
const calendarCore = function(options){

	const that = this;

	// 默认配置参数
	that.config = {
		elem: '#rili',//容器选择器
		elemId: '',
		id: '',//组件id自动生成
		moduleElem: '', // 组件dom对象
		type: '',// 类型: 默认input弹窗展示，static:静态展示
		year: getCurrentDate().year,//当前年限
        month: getCurrentDate().month,//当前月份
        day: getCurrentDate().day,//当前日期
        value: '',//当前时间值 
        monthDays: '',//当前月份的总共天数
        prevDay:[], //上月相差天数
        nextDay:[],// 下月相差天数
        start: '',
        end: '',
        position: {
        	top: 0,
        	left: 0,
        },
        weeks: ['日','一','二','三','四','五','六'],
        click: '',//回调事件
        on: ''
	}

	// 初始化
	that.init(options);

}

// 初始化组件
calendarCore.prototype.init = function(options){

	const that = this;

	let defaults = {
		elem: '',
		value: '',
		weeks: ['日','一','二','三','四','五','六'],
		start: '',
		end: '',
		type: '',
		click: '',
	}

	// 合并用户参数
	defaults = utils.merge(options);

	// 初始化日期值时，是否是日期格式
	if(!defaults.value || !isDate(defaults.value)){
		defaults.value = '';
	}

	// 初始化开始日期范围，判断值是否是日期格式
	if(!defaults.start || !isDate(defaults.start)){
		defaults.start = '';
	}

	// 初始化结束日期范围，判断值是否是日期格式
	if(!defaults.end || !isDate(defaults.end)){
		defaults.end = '';
	}

	// 初始化判断结束范围是否大于开始范围
	if(defaults.start && defaults.end && defaults.start != 'today' && defaults.end != 'today'){
		let startArr = defaults.start.split('-');
		let endArr = defaults.end.split('-');
		// 开始时间
		let start = convertToTimestamp(startArr[0],startArr[1],startArr[2]);
		// 结束时间
		let end = convertToTimestamp(endArr[0],endArr[1],endArr[2]);
		// 如果开始日期大于结束日期
		if(start > end){
			console.error("开始日期不能大于结束日期");
			// 初始化为空
			defaults.start = '';
			defaults.end = '';
		}
	}

    // 合并参数
	that.config = utils.merge(that.config,defaults);

	// 保留id选择器
	that.config.elemId = that.config.elem;

	// 如果不是静态模式，则检测input输入框是否有默认设置日期值
	if(that.config.type !== 'static'){

    	// 检测input输入框有默认时间值，同时是日期格式
		let defaultsValue = that.getInputValue();
		
		if(defaultsValue && isDate(defaultsValue)){
			let valueArr = defaultsValue.split('-');

			let year = valueArr[0];
			let month = valueArr[1].replace(/0/g,'');
			let day = valueArr[2].replace(/0/g,'');
			
			that.config.year = year;
			that.config.month = month;
			that.config.day = day;
			that.config.value = `${year}-${month}-${day}`;
		}
    }

	// 初始化是否指定日期值value
	if(options.value && options.value.length == 10 && utils.existString(options.value,'-')){

		let value = options.value.split('-');

		if(value[0].length == 4 && value[1].length <= 2 && value[2].length <= 2){

			let year = value[0];
			let month = value[1].replace(/0/g,'');
			let day = value[2].replace(/0/g,'');

			that.config.year = year;
			that.config.month = month;
			that.config.day = day;
		}
	}

	// 初始化日期值
    that.config.value = mergeTime(that.config.year,that.config.month,that.config.day);

	// 获取dom容器对象
	that.config.elem = dom.get(that.config.elem);

	// 判断是否是静态模式
    if(that.config.type == 'static'){

    	// 如果頁面不存在，則渲染
		if(that.config.moduleElem == ''){

			// 渲染
			that.render();
		};

    	return false;
    }

	// dom容器添加点击事件
	that.config.elem.onclick = function(e){

		// 获取容器相对页面位置
		let elemAttr = that.config.elem.getBoundingClientRect();

		// 设置容器相对页面的位置
		that.config.position = {
			top: elemAttr.top + parseInt(elemAttr.height) + 5,
			left: elemAttr.left
		}

		// 如果頁面不存在，則渲染
		if(!that.config.moduleElem){
			// 渲染
			that.render();
		};

		e.stopPropagation();
	}

	// window.addEventListener('click',function(e){
	// 	that.remove();
	// })
	
}

// 渲染组件
calendarCore.prototype.render = function(){	

	const that = this;

	// 获取当月天数
	that.config.monthDays = that.getDays(that.config.year,that.config.month - 1);

	// 获取上月时间差
	that.config.prevDay = that.getPrevMonth(that.config.year,that.config.month);

	// 获取下月时间差
	that.config.nextDay = that.getNextMonth(that.config.year,that.config.month,that.config.monthDays);

	// 渲染模版
	view.render(that.config);

	// 获取组件dom对象
	that.config.moduleElem = dom.get(`#${that.config.id}`);

	// 判断不是静态模式
	if(that.config.type !== 'static'){
	
		that.config.moduleElem.style.top = that.config.position.top + 'px';
		that.config.moduleElem.style.left = that.config.position.left + 'px';
		dom.addClass(that.config.moduleElem,'open-calendar-show');
	}

	// 初始化日期标题
	that.setDateTitle(that.config.moduleElem,that.config.year,that.config.month);	

	// 添加工具事件
	that.onTool();

	// 添加日期事件
	that.onDay();
}

// 重新渲染组件
calendarCore.prototype.reload = function(){	

	const that = this;

	// 获取当月天数
	that.config.monthDays = that.getDays(that.config.year,that.config.month - 1);

	// 获取上月时间差
	that.config.prevDay = that.getPrevMonth(that.config.year,that.config.month);

	// 获取下月时间差
	that.config.nextDay = that.getNextMonth(that.config.year,that.config.month,that.config.monthDays);

	// 重新加载组件模版
	view.reload(that.config);

	// 初始化日期标题
	that.setDateTitle(that.config.moduleElem,that.config.year,that.config.month);	

	// 添加日期事件
	that.onDay();
}

// 添加事件
calendarCore.prototype.onTool = function(){

	const that = this;

	// 切换上一年
	let prevYear = dom.child(that.config.moduleElem,'.open-calendar-prev-year');
	prevYear.onclick = function(e){
		onYear('prev');
		e.stopPropagation();
	}

	// 切换下一年
	let nextYear = dom.child(that.config.moduleElem,'.open-calendar-next-year');
	nextYear.onclick = function(e){
		onYear('next');
		e.stopPropagation();
	}

	// 切换上月事件
	let prevMonth = dom.child(that.config.moduleElem,'.open-calendar-prev-month');
	prevMonth.onclick = function(e){
		onMonth('prev');
		e.stopPropagation();
	}

	// 切换下月事件
	let nextMonth = dom.child(that.config.moduleElem,'.open-calendar-next-month');
	nextMonth.onclick = function(e){
		onMonth('next');
		e.stopPropagation();
	}

	// 年份切换事件
	function onYear(type){

		// 判断是上一年
		if(type == 'prev'){
			that.config.year = parseInt(that.config.year) - 1;
		}else if(type == 'next'){
			that.config.year = parseInt(that.config.year) + 1;
		}
		that.reload();
	}

	// 月份切换事件
	function onMonth(type){

		// 判断是上一月
		if(type == 'prev'){
			that.config.month = parseInt(that.config.month) - 1;
			if(that.config.month < 1){
				that.config.year = parseInt(that.config.year) - 1;
				that.config.month = 12;
			}
		}else if(type == 'next'){
			that.config.month = parseInt(that.config.month) + 1;
			if(that.config.month > 12){
				that.config.year = parseInt(that.config.year) + 1;
				that.config.month = 1;
			}
		}

		that.reload();
	}
}

// 添加日期点击事件
calendarCore.prototype.onDay = function(){

	const that = this;

	// 添加当月日期点击事件
	let currents = dom.childs(that.config.moduleElem,'.open-calendar-current');
	currents.forEach(function(current){
		current.onclick = function(e){
			onDay(current);
			e.stopPropagation();
		}
	})

	// 添加上个月点击事件
	let prevs = dom.childs(that.config.moduleElem,'.open-calendar-prev');
	prevs.forEach(function(prev){
		prev.onclick = function(e){
			onDay(prev);
			e.stopPropagation();
		}
	})

	// 添加下个月点击事件
	let	nexts = dom.childs(that.config.moduleElem,'.open-calendar-next');
	nexts.forEach(function(next){
		next.onclick = function(e){
			onDay(next);
			e.stopPropagation();
		}
	})

	// 监听点击事件，并解析选择的日期
	function onDay(currentElem){

		// 获取自定义日期属性
		let date = dom.attr(currentElem,'data-date')

		if(!date) return false;

		// 分解参数，获取年月日
		let dateArr = date.split('-');

		that.config.year = parseInt(dateArr[0]);
		that.config.month = parseInt(dateArr[1]);
		that.config.day = parseInt(dateArr[2]);

		// 设置事件值
		that.config.value = mergeTime(that.config.year,that.config.month,that.config.day);

		// 格式化日期
		let month = formatTime(that.config.month);
		let day = formatTime(that.config.day);
		let time = `${that.config.year}-${month}-${day}`;

		// 回调函数
		if(that.config.click && typeof that.config.click == 'function'){

			that.config.click(time);
		}

		// 全局回调函数
		that.config.on && that.config.on(time);

		// 刪除元素
		if(that.config.type !== 'static'){

			// 向输入框添加日期值
			that.setInputValue(time);

			that.remove();

		}else{

			that.reload();
		}
	}
}

// 设置input输入框值
calendarCore.prototype.setInputValue = function(value){
	const that = this;
	that.config.elem.value = value
}

// 获取input输入框值
calendarCore.prototype.getInputValue = function(value){
	const that = this;
	return that.config.elem.value;
}

// 刪除組件元素
// 初始化组件配置参数
calendarCore.prototype.remove = function(){
	const that = this;
	if(that.config.moduleElem){
		dom.removeChild(document.body,that.config.moduleElem);
		that.config.moduleElem = '';
	}
}

/**
 * 初始化设置日期标题
 * @param elem:HTMLElement 日历容器dom对象
 * @param year:string 年份
 * @param month:string 月份
*/
calendarCore.prototype.setDateTitle = function(elem,year,month){

	const that = this;

    // 获取时间标题dom
    let dateTitle = dom.child(elem,'.open-calendar-date-title');
    // 拼接日期
    let vdate = `${year} / ${formatTime(month)}`;
    // 设置日期标题
    dateTitle.innerText = vdate;
}

/**
 * 获取指定日期上个月的时间差
 * @param year:string|number 年份
 * @param month:string|number 月份
*/
calendarCore.prototype.getPrevMonth = function(year,month){
	const that = this;
    let firstWeekDay = that.getDayWeek(year,month,1);
    //获取上个月天数
    let months = month - 2 < 0 ? 11 : month - 2;
    let prevDays = that.getDays(year,months);
    let timeDiff = [];
    if(firstWeekDay < 7){
        for(let i = prevDays; i > 0; i -- ){
            if(timeDiff.length >= firstWeekDay){
                break;
            }
            timeDiff.push(i);
        }
    } 
    timeDiff = timeDiff.reverse();
    return timeDiff;
}

/**
 * 获取指定日期下个月的时间差
 * @param year:string|number 年份
 * @param month:string|number 月份
 * @param day:string|number 当月天数
*/
calendarCore.prototype.getNextMonth = function(year,month,day){
	const that = this;
    let lastWeekDay = that.getDayWeek(year,month,day);
     // 获取下个月天数
    let months = month == 12 ? 11 : month;
    let nextDays = that.getDays(year,months);
    let timeDiff = [];
    if(lastWeekDay < 7){
        for(let i = 1 ; i < nextDays; i ++ ){
            if(timeDiff.length >= 6 - lastWeekDay ){
                break;
            }
            timeDiff.push(i);
        }
    }
    if(lastWeekDay == 7){
        lastWeekDay = 6;
        for(let i = 1 ; i < nextDays; i ++ ){
            if(timeDiff.length >= lastWeekDay){
                break;
            }
            timeDiff.push(i);
        }
    }
    return timeDiff;
}

/**
 * 输入日期获取对应的周几
 * @param date:number 几号
 * @param month:number 几月
 * @param year:number 年份
 * @return day:number 周几
*/
calendarCore.prototype.getDayWeek = function(year,month,date){
		const that = this;
    // 判断年月日必须是要有的，并且是数字类型
    if(!date || !month || !year){
        throw 'Error: The parameter must be both year, month and day; 报错：参数必须是年，月，日';
        return false;
    }
    // 对月份进行格式化,四舍五入，然后判断大于12或者小于1就默认月份为1
    if(Math.round(month) > 12 || Math.round(month) < 1){
        month = 1;
    }
    // 对日期进行格式化,四舍五入，然后判断大于当月天数或者小于1就默认日期为1
    if(Math.round(date) > that.getDays(month - 1,year) || Math.round(date) < 1){
        date = 1;
    }
    // 对年份进行格式化,四舍五入，然后判断年份长度如果不等于4,就默认年份为当前年份
    if(Math.round(year).toString().length != 4){
        year = new Date().getFullYear();
    }
    let dates = "'" + month + "/" + date + "/" + year + "'";
    let day  = new Date(dates);
    return day.getDay();
}

/**
 * 获取本月的天数
 * @param year:number 需要查询的年份
 * @param month:number 需要查询的月份
 * @return days:number 返回查询的月份天数 
*/
calendarCore.prototype.getDays = function(year,month){
	// 月份天数对应数组
    let monthDays = [31,28,31,30,31,30,31,31,30,31,30,31];
    // 计算闰年2月天数
    if((year % 4 === 0) && (year % 100 !== 0 || year % 400 === 0)){
        monthDays[1] = 29;
    }
    return monthDays[month];
}

/**
 * 获取当前日期
 * @return object 返回年月日对象
*/
function getCurrentDate(){
	let date = new Date();
    return {
    	year: date.getFullYear(),
    	month: date.getMonth() + 1,
    	day: date.getDate()
    }
}

/**
 * 拼接日期
 * @param year:string|number 年份
 * @param month:string|number 月份
 * @param day:string|number 月份日期
 * @return string 拼接后的日期
*/
function mergeTime(year,month,day){

	if(month > 12){
		year = parseInt(year) + 1;
		month = 1;
	}else if(month < 1){
		year = parseInt(year) - 1;
		month = 12;
	}
	return `${year}-${month}-${day}`;
}

/**
 * 格式化日期:个位前面补0
 * @param time:string|number 需要格式化的数字
 * @return string 返回补0后的字符串
*/
function formatTime(time){
	return parseInt(time) < 10 ? `0${time}`: time;
}

// 日期转换时间戳
function convertToTimestamp(year, month, day) {
    // month 是从 0 开始的，所以需要减去 1
    var date = new Date(year, month - 1, day);
    return date.getTime();
}

// 判断是否日期类型： yyyy-mm-dd
function isDate(str) {
    var pattern = /^\d{4}-\d{2}-\d{2}$/;
    return pattern.test(str);
}


// 日历组件
const calendar = {
	version: 'v0.1.0',
    author: 'liukaijian',
    date: '2024-04-08 20:38',
    description: '基于js开发的日历组件'
}

// 渲染
calendar.render = function(options){

	 // 生成组件id
    let templateId = utils.randomId();

    options.id = templateId;

    let instance = new calendarCore(options);

    // 保存实例到modules
    modules[templateId] = instance;
}

// 全局监听
calendar.on = function(id,fn){

	if(!id || typeof id !== 'string') return false;

    // 判断回到函数类型
    if(fn && typeof fn !== 'function') return false; 

    // 格式化id
    id = id.replace(/#/g,'');

    // 获取组件自动生成id
    let template = dom.get(`#${id}`);

    // 动态模式下获取id

    let openid = '';

    if(dom.child(template,'.open-calendar')){

    	let openid = dom.attr(dom.child(template,'.open-calendar'),'openui-id');
    }
    // 动态模式未获取，则input选择器模式下获取
    if(!openid){
    	Object.values(modules).forEach(item=>{
    		if(item.config.elemId == `#${id}`){
    			openid = item.config.id;
    		}
    	})
    }

    // 获取实例
    let instance = modules[openid];

    // 添加回调函数到实例配置参数
    instance.config.on = fn;
}

// 返回组件值
calendar.val = function(id){

	if(!id || typeof id !== 'string') return false;

    // 格式化id
    id = id.replace(/#/g,'');

    // 获取组件自动生成id
    let template = dom.get(`#${id}`);

    // 动态模式下获取id

    let openid = '';

    if(dom.child(template,'.open-calendar')){

    	let openid = dom.attr(dom.child(template,'.open-calendar'),'openui-id');
    }
    // 动态模式未获取，则input选择器模式下获取
    if(!openid){
    	Object.values(modules).forEach(item=>{
    		if(item.config.elemId == `#${id}`){
    			openid = item.config.id;
    		}
    	})
    }

    // 获取实例
    let instance = modules[openid];

    let config = instance.config;

	let month = formatTime(config.month);
	let day = formatTime(config.day);

	let result = {
		year: config.year,
		month: config.month,
		date: config.day,
		value: `${config.year}-${month}-${day}`,
	}

	return result;
}

export default calendar;

