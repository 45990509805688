/**
 * 表格组件
*/
import app from './core.js'
import slot from './slot.js'
import template from './template.js'

// 工具函数对象
const dom   = app.dom;
const utils = app.utils;

// 缓存实例
let tableModules = {};

// 视图模版
const tableView = {
	// 动态创建表格头部
	createHeader: function(options){
		// 选择框
		let checkbox = '';
		// 判断是否开启选择框
		if(options.checkbox.on){
			checkbox = tableView.createCheckBox(options.checkbox.width,'checkbox-all');
		}
		// 头部内容
		let ths = '';
		// 根据内容循环输出行内容
		options.clos.forEach(item=>{
			ths += tableView.createTh(item.width,item.title);
		})
		// 创建title行
		let createThead = function(data){
			return `<thead><tr>${data.checkbox + data.ths}</tr></thead>`;
		}
		return tableView.createThead(checkbox,ths);
	},
	// 动态创建表格主体
	createBody: function(options){
		let content = '';
		options.data.forEach(item=>{
	 		content += tableView.createBodyLine(item,options)
		})
		return `<tbody>${content}</tbody>`;;	
	},
	// 创建选择框
	createCheckBox: function(width,type){
		return `<th width="${width}" style="padding-left:14px;padding-right:14px;">
            		<input class="open-table-checkbox ${type}" type="checkbox">
            	</th>`;
	},
	// 创建头部
	createTh: function(width,title){
		return `<th width="${width}"><span class="open-table-text">${title}</span></th>`
	},
	// 创建html
	createThead: function(checkbox,ths){
		return `<thead><tr>${checkbox + ths}</tr></thead>`;
	},
	// 创建一行table数据
	createBodyLine: function(data,options){

		let checkbox = '', // 选择框dom
			tds = ''; // td表格dom

		// 判断是否开启选择框
		if(options.checkbox.on){
			// 创建选择框
			checkbox = tableView.createBodyCheckBox(data)
		}

		// 创建表格主题每个单元格内容
		options.clos.forEach(item=>{

			// 是否自定义模版
			if(item.slot){

				// 获取插槽，并传参
				let template = slot.prop(item.slot,data);;

				tds += `<td>${template}</td>`;
			}else{
				if(data[item.field]){
					tds += `<td><span class="open-table-text">${data[item.field]}</span></td>`;
				}else{
					tds += `<td></td>`;
				}
			}
		})

		return `<tr data-id="${data.id}">${checkbox + tds}</tr>`;
	},
	// 创建表格主题checkbox
	createBodyCheckBox: function(data){
		return  `<td style="padding-left:14px;padding-right:14px;">
            		<input class="open-table-checkbox" value="${data.id}" type="checkbox">
            	</td>`;
	}
}

// 核心组件
const tableCore = function(options){

	// 配置参数
	this.config = {
		id: '',
		elem: '',
		moduleId:'',
		moduleElem:'',
		checkbox: {
			on: false,
			width: 50
		},
		checkboxData: [],
		clos: 0,
		data: [],
		slot: [],
	};

	// 初始化
	this.init(options);

	const that = this;

	// 渲染
	setTimeout(function(){
		
		that.render();
		that.onCheck()

	}, 300)

}

// 初始化
tableCore.prototype.init = function(options){

	const that = this;

	// 默认参数
	let defaults = {
		id: '',
		moduleId:'',
		checkbox: {
			on: false,
			width: 50
		},
		clos: [],
		data: [],
	}

	// 合并默认参数
	defaults = utils.merge(defaults,options);
	// 合并配置参数
	that.config = utils.merge(that.config,defaults);

	if(options.clos){
		that.config.clos = options.clos;
	}

	if(options.data){
		that.config.data = options.data;
	}
	// 组件容器
	that.config.elem = dom.get(that.config.id);

	// 添加自定义的插槽
	slot.add(that.config.elem)

	// 判断是否开启checkbox选择框
	if(that.config.clos[0].checkbox){

		// 获取checkbox宽度
		let width = that.config.clos[0].checkbox.width ? that.config.clos[0].checkbox.width : that.config.checkbox.width;

		// 设置checkbox参数
		that.config.checkbox = {
			on: true,
			width: width
		}

		// 重新过滤clos参数
		that.config.clos.splice(0,1);
	}

}

// 渲染
tableCore.prototype.render = function(options){

	const that = this;

	// table标题
	let header = tableView.createHeader(that.config);

	// table主体
	let body =  tableView.createBody(that.config);

	let border = "";

	if(utils.existClass(that.config.elem,'open-table-border')){
		border = 'open-table-border';

		dom.removeClass(that.config.elem,'open-table-border');
	}

	let container = "<table" + that.config.elem.outerHTML.substring(4,that.config.elem.outerHTML.length - 4) + "table>";

	let index = container.indexOf('>');

	let startContainer = container.substring(0,index + 1);
	let endContainer = container.substring(index + 1,container.length);

	let html = startContainer + header + body + endContainer;

	let tableHeight = that.config.elem.offsetHeight; 

	let tableDom = `<div class="open-table-container ${border}" style="max-height:${tableHeight}px"><div class="open-table-wrapper">${html}</div></div>`;

	dom.addBefore(that.config.elem,tableDom);

	that.config.elem.remove();

	that.config.elem = dom.get(that.config.id);

	that.config.elem.style.height = "auto";

};

// 初始化表格选择框
tableCore.prototype.onCheck = function(){

	const that = this;

	// 全选选择框
	let checkAll = dom.child(that.config.elem,'thead .open-table-checkbox');

	// 单个选择器集合
	let checks = dom.childs(that.config.elem,'tbody .open-table-checkbox');

	that.config.elem.addEventListener('click', function(e){

		let target = e.target;

		// 如果不是input元素，停止程序
		if(target.tagName != 'INPUT')return false;

		// 如果是input选择框
		if(utils.existClass(target,'open-table-checkbox')){

			// 如果是总选择器
			if(utils.existClass(target,'checkbox-all')){

				// 如果全选设置未选择
				if(target.checked == false){

					// 清空选择数据
					that.config.checkboxData = [];

					// 取消单选列表选中
					that.updateChecksStatus(checks,target);

				}else{
					// 如果全选设置选中
					that.updateChecksStatus(checks,target);
				}

			}else{
				// 单选按钮

				// 如果选择框是true
				if(target.checked){
					// 添加数据到选择对象
					that.config.checkboxData.push(target.value);
				}else{

					// 如果是false,查找当前值在选择对象的下标位置
					let index = that.config.checkboxData.indexOf(target.value);
					// 然后删除已添加的值
					that.config.checkboxData.splice(index,1);
				}

				let mark = true;

				// 单选按钮，还要遍历单选列表，更新全选框的状态
				checks.forEach((i,index)=>{
					if(!i.checked){
						mark = false;
					}
					utils.onLoopEnd(index,checks,function(){
						if(mark){
							checkAll.checked = true;
						}else{
							checkAll.checked = false;
						}
					})
				})
			}
		}
	})

};

// 批量设置单选列表状态
tableCore.prototype.updateChecksStatus = function(checks,checkAll){

	const that = this;

	// 批量修改单选列表状态
	checks.forEach(item=>{

		// 设置单个check状态
		item.checked = checkAll.checked;

		// 如果选中状态
		if(item.checked){

			// 添加到选择数据中
			that.config.checkboxData.push(item.value);
		}
	})
}

// 只替换元素标签名，不更改属性和文本
tableCore.prototype.replaceTagName = function(elem,newTagName){

    // 获取小写标签名
    let tag = elem.tagName.toUpperCase().toLowerCase();

    // 获取元素
    let html = elem.outerHTML;   

    // 前置标签匹配
    let rexStart = new RegExp(`<${tag}`, "g");
    // 结尾标签匹配
    let rexEnd = new RegExp(`${tag}>`, "g");

    // 替换标签
    let newHtml = html.replace(rexStart,`<${newTagName}`);
        newHtml = newHtml.replace(rexEnd,`${newTagName}>`);

    // 返回html字段
    return newHtml;
}

// 获取选择框数据
tableCore.prototype.getCheckData = function(){
	return this.config.checkboxData;
}

// 模块对象
const table = {

}

// 渲染组件
table.render = function(options){
	// 生成模版id
	let templateId = utils.randomId();
	// 模版添加到配置参数上
	options.moduleId = templateId;
	// 创建实例
	let instance = new tableCore(options);
	// 缓存实例
	tableModules[templateId] = instance;

	return instance;
}

// 静态组件渲染
table.renderStatic = function(){
	
}



	export default table;


