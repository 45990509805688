/**
 * 模版引擎
*/
const template = function(template,data){

	// 符号
	let symbol = {
		up: '&gt;',
		lo: '&lt;',
	}

	// 同时匹配{{}}和[]
	let rex = /\{\{([^${}]+)\}\}|(\[([^\]]+)\])|open-if="([^"]*)"|open-if='([^']*)'/g;
	let reg = /if\s*(.+?)\s*\{|\}?\s*else\s+if\s*(.+?)\s*\{|\}?\s*if\s*(.+?)\s*\{/g;

	// 去除值两边中括号
    function removeBracket(str){
    	return str.substring(1, str.length - 1);
    }

    // 去除值两边花括号
    function removeBrace(str){
    	return str.substring(2, str.length - 2);
    }

    // 判断是否是for循环
    function isFor(str){

    	let reg = /\{%\s*for\s+(.+?)\s+in\s+(.+?)\s*%\}([\s\S]*?)\{%\s*endfor\s*%\}/g;

    	return str.match(reg);
    }

    // 判断元素是否是if
    function isIf(str){
    	
    	let reg = /\{\{\s*if\s+(.+?)\s*\}\}([\s\S]*?)\{\{\s*else\s*\}\}([\s\S]*?)\{\{\s*endif\s*\}\}/g 
    	return str.match(reg);
    }

    // 判断是否是三元运算符
    function isTernary(str) {
	  const regex = /^\(?(?<condition>[^?]+)\?(?<expr1>[^:]+):(?<expr2>[^]+)$/;
	  return regex.test(str);
	}

	// 编译三元运算符
    let compileTernary = function(val,data){

    	console.log(val)
    	
    	// 转换符号
    	val = val.replace(`${symbol.up}`,'>');
    	val = val.replace(`${symbol.lo}`,'<');
    	val = val.replace('=""',' ');

    	// 分离三元运算符
    	let getValue = val.split(" ");
    	// 获取值的字段
    	let valArr = getValue[0].split(".");

    	// 获取值
    	let value = valArr.reduce(function(prev,current){
        	return prev[current];
        },data)

        // 替换值
        let newVal = val.replace(`${getValue[0]}`, value);

    	let fn =  new Function('data',`return ${newVal}`);

    	return fn(data);
    }

    // 编译文本
    let compileText = function(val,data){

    	// 分离当前传递的匹配值
        let valArr = val.split('.');

        // 遍历赋值
        let value = valArr.reduce(function(prev,current){
        	if(prev[current]){
        		return prev[current];
        	}else{
        		return "";
        	}

        },data)

        // 如果没有找到值，或者值是underfine，默认返回字段
        if(!value){
        	if(value !== 0){
	            value = "{{".concat(val, "}}");

	        }value = `{{${val}}}`;
        }

        // 返回替换值后的html字段
        return value;
    }


    // 编译三元运算符和文本值
    function compileStepOne(template,val){

    	 compile = function(val,type){

			let html;

			// 去除花括号
			if(type == "hua"){
				val = removeBrace(val);
			}

			// 去除中括号
			if(type === "zhong"){
				val = removeBracket(val);
			}
			
			// 如果是三元运算符
			if(isTernary(val)){

				// 编译三元运算符
				html = compileTernary(val,data);

				return html;
			}

			// 编译文本值
			html = compileText(val,data);
			
			return html;
	    }

	    return template.replace(rex, function(match,valOne,valTwo,valThree){

	    	// 匹配open-if
	    	if(match.indexOf('open-if') !== -1){

	    		match = match.replace(/&quot;/g,'"');

	    		let ifValue = match.substring(9,match.length - 1);

	    		return compile(`{{${ifValue}}}`,"hua");
	    	}


	    	if(match.indexOf('if')!== -1)return match;
	    	if(match.indexOf('else')!== -1)return match;
	    	if(match.indexOf('else if')!== -1)return match;
	    	if(match.indexOf('endif')!== -1)return match;
	    	if(match.indexOf('for')!== -1)return match;
	    	if(match.indexOf('endfor')!== -1)return match;


	    	
	    	// 匹配到{{}}的值
	    	if(valOne){
	    		// 编译
	        	return compile(`{{${valOne}}}`,"hua");
	    	}
	    	// 匹配到[]的值
	    	if(valTwo){
	    		// 编译
	    		return compile(valTwo,"zhong");
	    	}
	    });
    }

    // 渲染

    let compile = function(template,data){

    	let html = "";

    	html = compileStepOne(template,data);

    	html = html.replace(/&gt;|&lt;/g,function(match,key,key2){
			if(match == '&gt;'){
				return ">"
			}
			if(match == '&lt;'){
				return "<"
			}
		});

    	// 如果是if
    	if(isIf(html)){

    		function render(template, data) {
			  	// 正则表达式匹配条件判断的语法
			  	const conditionRegex = /\{\{\s*if\s+(.+?)\s*\}\}([\s\S]*?)\{\{\s*else\s*\}\}([\s\S]*?)\{\{\s*endif\s*\}\}/g;
			 
			  	// 替换条件判断语句的函数
			  	function replaceCondition(match, condition, trueContent, falseContent) {

					condition = condition.replace(/&gt;|&lt;/g,function(match,key,key2){
						if(match == '&gt;'){
							return ">"
						}
						if(match == '&lt;'){
							return "<"
						}
					});

					let fn = new Function('data',`return data.${condition}`);
				
			    	// 执行条件判断
			    	if (fn(data)) {
			      		return trueContent;
			    	} else {
			      		return falseContent;
			    	}
			  	}
			 
			  	// 使用正则表达式和replace方法进行替换
			  	return template.replace(conditionRegex, replaceCondition);
			}

			html = render(html,data);
    	}

    	function renderFor(template, data) {
    	
			// 正则表达式匹配条件判断的语法
			// const conditionRegex = /\{\{\s*for\s+(.+?)\s*\}\}([\s\S]*?)\{\{\s*endfor\s*\}\}([\s\S]*?)\{\{\s*endfor\s*\}\}/g;
			const conditionRegex =  /\{\{\s*for\s+(.+?)\s*\}\}([\s\S]*?)\{\{\s*endfor\s*\}\}/g;
			
			// 替换条件判断语句的函数
			function replaceCondition(match, condition, endContent) {


				let forArr = condition.split(' ');

				// 获取循环的对象
				let objData = forArr[2];
				// 获取值
				let keyValue = forArr[0];

				let rex = /\{\{([^${}]+)\}\}|(\[([^\]]+)\])/g;

				let fn = new Function('data',"elems","rex","compileText","removeBrace",`
					let str = ""; 

					data.${objData}.forEach(function${keyValue}{
					
						str += elems.replace(rex, function(match,valOne,valTwo){

					    	// 匹配到{{}}的值
					    	if(valOne){

					    		if(valOne == "i"){

					    			if(i== 0){
					    				i = "0";
					    			}
					    			return compileText(valOne,{i:i})
					    		}else{
					    			// 编译
						        	return compileText(valOne,{item:item})
					    		}
					    	}
				    	});

					})
					console.log(str)
					return str;
				`);

				return fn(data,endContent,rex,compileText,removeBrace);
			 }
			 
			 // 使用正则表达式和replace方法进行替换
			return template.replace(conditionRegex, replaceCondition,rex);

    	}

    	html = renderFor(html,data);;

    	return html;
    }

    // 编译
    return compile(template,data);

}


// const template = {

// }

export default template;

