/**
 * 富文本编辑器
*/
import {dom,utils} from './core.js'

class editer{

	// 插件描述
	static name = "editer";
	static author = "kaijian";
	static version = "v2.0.0";
	static description = "基于js开发的富文本插件";
	static date = "2024-06-10";

	// 所有模块
	static modules = {};

	// 暂存第三方模块
	static #thirdModules = {};

	// 判断模块只加载一次
	static #onceLoadModules = false;

	// 编辑器插件开发api：事件
	static #pluginApi = {
		click: [],
		key: []
	}

	/**
	 * 插件注册全局配置参数或事件中心
	 * 属性可以是默认是空
	 * 前端调用时赋值
	 * 类型可以是各种类型，包括函数
	 * 自己在自己程序里获取值调用
	*/
	static config = {};

	/**
	 * 创建新组件
	 * @param id:string 组件挂载容器id选择器
	 * @param options:object 组件配置参数
	*/
	static create(id,options){
		// 判断id值以及类型
		if(!id || typeof id !== 'string')return false;
		/**
		 * 合并参数
		 * @attr data.custom: object 自定义数据
		 * @attr data.system: object 系统数据
		*/
		let data = {
			custom: {...options,id:id},
			system: {moduleId:editer.#randomId()}
		}

		// 创建实例，并返回实例
		return new editer(data);
	}

	// 生成组件随机id
	static #randomId(){
		return Date.now().toString(36) + Math.random().toString(36).substring(2);
	}

	// 构造函数
	constructor(options){

		// 缓存实例
		const that = this;

		// 实例配置参数
		that.config = {
			id: '', // 组件容器id 
	        elem:'', // 组件容器dom
	        moduleId: '', // 组件id
	        moduleElem: '', // 组件dom
	        toolbarElem: '', // 工具栏dom
	        editorElem: '', // 编辑器dom
	        footerElem: '', // 编辑器底部dom
	        placeholder: '写点啥吧', // 提示信息
	        readOnly: false,// 编辑器只读 
	        addStyle: false,// 添加内联样式
	        focus: true,// 自动聚焦
	        cursor: {
	            elem: '',
	            index: '',
	            line: ''
	        }, // 保存光标信息
	        toolbar: {
	            id: '',
	            modules: '',
	        },
	        menus: [], // 当前实例工具栏菜单
	        onChange: '',
		}

		// 加载内部插件和第三方插件
		if(!editer.#onceLoadModules){
			// 加载系统插件
			that.#loadCorePlugins();
			// 加载第三方插件
			that.#loadPlugins();
			// 修改已调用一次
			editer.#onceLoadModules = true;
		}

		// 初始化组件数据
		that.#init(options);

		// 如果toolbar参数是false，则不渲染toolbar
		if(typeof that.config.toolbar != 'boolean' && that.config.toolbar === false){

		}else{

			// 判断如果不存在toolbar组件，则渲染
			if(!dom.child(that.config.elem,'.open-editor-toolbar')){
				// 添加toolbar工具栏菜单数据
				that.#addPluginMenu();

				// 渲染toolbar元素dom
				that.#renderToolbar();

				// 添加toolbar菜单逻辑
				that.#addPluginHandle();

				// 添加toolbar点击事件监听
				that.#onToolbarClick();
			}
		}

		// 判断如果不存在toolbar组件，则渲染
		if(!dom.child(that.config.elem,'.open-editor-container')){

			// 渲染toolbar元素dom
			that.#renderEdit();
		}

		// 判断如果不存在toolbar组件，则渲染
		if(!dom.child(that.config.elem,'.open-editor-footer')){

			// 渲染toolbar元素dom
			that.#renderFooter();
		}

		// 设置光标
		that.onFocus();

		// 渲染组件
		that.#render();

		// 编辑器输入事件
		that.#onInput();
		// 键盘事件
		that.#onKey();
		// 编辑器点击事件
		that.#onClick();
	}

	// 初始化数据
	#init(options){

		// 缓存实例对象
		const that = this;

		// 自定义配置参数
		let defaults = {
			id: '',
	        placeholder: '写点啥吧',
	        readOnly: false,//只读
	        focus: true,// 自动聚焦
	        addStyle: false,// 是否添加内联样式
	        toolbar: {
	            id: '',
	            modules: '',
	        },
	        onChange: ''
		}

		/** 
		 * 解构参数
		 * custom:object 自定义参数
		 * system:object 系统参数
		*/
		let {custom,system} = options;

		// 合并参数
		defaults = that.#merge(defaults,custom);

		// 合并参数到实例配置参数
		that.config = that.#merge(that.config,{...defaults,...system});

		// 获取编辑器容器dom
		that.config.elem = dom.get(that.config.id);

	}

	// 渲染组件
	#render(){

		// 缓存实例
		const that = this;

		// 设置提示信息
		that.config.placeholderElem.innerText = that.config.placeholder;

		// 设置窗口聚焦
		that.config.focus && that.#focus();

		// 设置只读模式
		that.config.readOnly && that.#read();
	}

	// 设置html内容
	setHtml(html){
	    this.config.editorElem.innerHTML = html;
	}

	// 获取html内容
	getHtml(){

		let childs = this.config.editorElem.children;

		let htmls = '';

		for(let i = 0;i < childs.length; i ++){

			let item = childs[i];

			if(item.tagName == 'IMG'){

				// 获取自定义图片链接
				let href = item.getAttribute('open-editor-href');

				// 清空href属性值
				item.setAttribute('open-editor-href', '');

				// 获取图片元素代码
				let img = item.outerHTML

				// 并清除自定义href属性
				let html = img.replace('open-editor-href=""','')

				// 如果href值在，img添加a标签
				if(href){
					html = `<a href="${href}" target="_blank>${img}</a>`
				}

				htmls += html;
			}else{
				htmls += item.outerHTML;
			}
		}

		// console.log(htmls);
	    return this.#clearLineNumber(htmls).trim();
	}

	// 获取内容文本
	getText(){

	    let text = this.config.editorElem.innerText;

	    // 过滤空格或换行等符号
	    let regex = /(<([^>]+)>|[\r\n\s])/g;

	    return text.replace(regex,function(match){
	    	return '';
	    })
	}

	// 获取图片
	getImage(){

		let urls = [];

		// 获取html内容
	    let imgs = dom.childs(this.config.editorElem,'img');
 
		// 获取每个img url
		imgs.forEach(img=>{

		  	let imgUrl = dom.attr(img,'src');

		  	urls.push(imgUrl);
		})

		return urls;
	}

	// 获取link
	getLink(){

		let urls = [];

		// 获取html内容
	    let links = dom.childs(this.config.editorElem,'a');
 
		// 获取每个link url
		links.forEach(link=>{

		  	let linkUrl = dom.attr(link,'href');

		  	urls.push(linkUrl);
		})

		return urls;
	}

	// 获取所有模块
	getModules(){
	    return editer.modules;
	}

	// 清空编辑器内容
	clear(){
	    this.config.editorElem.innerHTML = "";
	}

	// 插入文本到当前光标
	insertText(text){

	}

	// 插入图片到当前光标
	insertImage(imgUrl,alt=''){

		// 缓存实例
		const that = this;

		let imgElem;

		// 获取当前行
		let currentElem = that.#getCursorElem();

		// 获取当前行号
		let index = dom.attr(currentElem,'open-editor-line');

		// 如果当前行没有内容直接插到当前行，删除当前行
		if(currentElem.innerText.length == 1){

			// 创建dom
			imgElem = createImage(imgUrl,index,alt);
			// 添加到当前光标后一行
			currentElem.after(imgElem);
			// 并删除当前行
			currentElem.remove();

		}else{

			// 如果当前行已有内容，则添加到新行
	    	index = index + 1;
	    	// 创建dom
			imgElem = createImage(imgUrl,index,alt);
			// 添加到当前光标后一行
			currentElem.after(imgElem);

		}

		// 创建图片元素
		function createImage(imgUrl,index,alt=''){
			let imgElem = document.createElement('img');
			imgElem.setAttribute('open-editor-line', index); 
			imgElem.setAttribute('loading', 'lazy'); 
			imgElem.setAttribute('alt', alt); 
			imgElem.src = imgUrl;
			return imgElem;
		}

	    // 获取新添加的行dom
	    let currentDom = dom.child(that.config.editorElem,`[open-editor-line="${index}"]`);

	    // 设置光标到新行
	    that.#setCursor(currentDom,0);

	    that.#sortContentLine();

	    // 添加img扩展事件
	    imgElem.onclick = function(e){

	    	let currentImageElem = e.target;
	    	
	    	let dialog = `<div id="open-editor-image-dialog" class="open-editor-image-dialog" style="position:absolute;top:10px;left:10px;z-index:10;width: 300px;box-shadow:1px 1px 7px rgba(0, 0, 0, .15);box-sizing: border-box;padding: 12px 16px 16px;font-size: 14px;color: #333333;border-radius: 2px;height:auto;background:var(--color-bg-1);">
									<div class="open-editor-dialog-input">
										<label style="display: inline-block;line-height: 36px">图片地址</label>
										<input type="url" id="open-editor-image-adress" value="${currentImageElem.src}" placeholder="图片src地址" style="border: 1px #ddd solid;width: 100%;line-height: 30px;box-sizing: border-box;padding: 0 8px;border-radius: 2px;outline:none;">
									</div> 
									<div class="open-editor-dialog-input">
										<label style="display: inline-block;line-height: 36px">图片描述</label>
										<input type="url" id="open-editor-image-alt" placeholder="图片alt描述"  value="${currentImageElem.getAttribute('alt') || ''}" style="border: 1px #ddd solid;width: 100%;outline:none;line-height: 30px;box-sizing: border-box;padding: 0 8px;border-radius: 2px;">
									</div> 
									<div class="open-editor-dialog-input">
										<label style="display: inline-block;line-height: 36px">图片链接</label>
										<input type="url" id="open-editor-image-link" placeholder="图片a标签跳转链接" value="${currentImageElem.getAttribute('open-editor-href') || ''}" style="outline:none;border: 1px #ddd solid;width: 100%;line-height: 30px;box-sizing: border-box;padding: 0 8px;border-radius: 2px;">
									</div> 
									<div class="open-image-dialog-footer" style="text-align: right;margin-top: 12px;">
										<button id="open-editor-image-cancel" style="border: 1px solid #dddddd;padding: 4px 10px;border-radius: 2px;background: #F2F6FC;cursor:pointer;">取消</button>
										<button id="open-editor-image-confirm" style="border: 1px solid #409EFF;cursor:pointer;color: #ffffff;padding: 4px 10px;border-radius: 2px;background: #409EFF;margin-left: 8px;">确认</button>
									</div> 
								</div>`;

			// 设置编辑器窗口为绝对定位
			that.config.moduleElem.style.position = 'relative';

			// 添加元素到编辑器
			that.config.moduleElem.insertAdjacentHTML('beforeend',dialog);

			// 获取弹出框容器dom
			let dialogElem = document.querySelector("#open-editor-image-dialog");

			// 获取取消按钮
			let cancel = document.querySelector("#open-editor-image-cancel");

			// 获取确定按钮
			let confirm = document.querySelector("#open-editor-image-confirm");

			// 获取图片地址
			let adress = document.querySelector("#open-editor-image-adress");

			// 获取图片链接
			let link = document.querySelector("#open-editor-image-link");

			// 获取图片描述
			let alt = document.querySelector("#open-editor-image-alt");

			// 删除弹出框
			let removeDialog = function(){

				dialogElem.remove();
			}

			// 
			dialogElem.onclick = function(e){

				e.stopPropagation();
				return false;
			}

			// 删除按钮事件
			cancel.onclick = function(){

				removeDialog();

				// 设置光标位置
            	that.#setCursor(currentElem,1);
			}

			// 确定按钮事件
			confirm.onclick = function(){

				// 重新设置adress
				if(adress.value){
					currentImageElem.src = link.value;
				}

				if(alt.value){
					currentImageElem.alt = alt.value;
				}

				if(link.value){
					currentImageElem.setAttribute('open-editor-href', link.value);
				}
			    // 设置光标位置
            	// that.#setCursor(currentElem,1);

            	removeDialog();
			}
	    }
	}

	// 插入文本到当前光标
	insertText(link){
		
	}

	// 插入html到当前编辑器
	insertHtml(currentElem,html){
		dom.addAfter(currentElem,html);
	}



	/********************************* 插件相关 ********************************/

	/**
	 * 添加第三方插件
	 * @param name:string 插件名
	 * @param fn:function 插件执行函数入口
	*/
	static addPlugin(name,fn){
		// 初始化插件对象
		let plugin = {};
		// 添加插件信息到对象
		plugin[name] = fn;
		// 添加对象到插件对象
		editer.#thirdModules = {...editer.#thirdModules,...plugin};

	}

	/**
	 * 添加核心插件
	 * @param name:string 插件名
	 * @param fn:function 插件执行函数入口
	*/
	#addCorePlugin(name,fn){
		// 添加插件到模块
    	editer.modules[name] = fn;
	}

	// 加载第三方插件
	#loadPlugins(){
		editer.modules = {...editer.modules,...editer.#thirdModules};
	}

	// 加载核心插件
	#loadCorePlugins(){
		
		// 缓存实例
		const that = this;

	    // 正文事件
	    that.#addCorePlugin('text',{
	        menu: {
	            text: "正文",
	            items: [
	                {
	                    text: 'H1',
	                    class: 'h1',
	                    handle: 'h1'
	                },
	                {
	                    text: 'H2',
	                    class: 'h2',
	                    handle: 'h2'
	                },
	                {
	                    text: 'H3',
	                    class: 'h3',
	                    handle: 'h3'
	                },
	                {
	                    text: 'H4',
	                    class: 'h4',
	                    handle: 'h4'
	                },
	                {
	                    text: 'H5',
	                    class: 'h5',
	                    handle: 'h5'
	                },
	                {
	                    text: 'H6',
	                    class: 'h6',
	                    handle: 'h6'
	                },
	                {
	                    text: '正文',
	                    class: 'text',
	                    handle: 'text'
	                },
	            ]
	        },
	        handle:function(app){

	            // h1事件
	            let h1 = app.getHandleElem('h1');

	            h1.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();

	                // 替换元素标签名,并赋值给新标签
	                let h1Element = that.#replaceTagName(currentElem,'h1');

	                // 将h1元素插入到p元素的位置
	                dom.addBefore(currentElem,h1Element);
	              
	                // 移除原来的p元素
	                currentElem.remove();
	            }

	            // h2事件
	            let h2 = app.getHandleElem('h2');

	            h2.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();
	              
	                // 替换元素标签名,并赋值给新标签
	                let h2Element = that.#replaceTagName(currentElem,'h2');
	              
	                // 将h2元素插入到当前元素的位置
	                dom.addBefore(currentElem,h2Element);
	              
	                // 移除原来的元素
	                currentElem.remove();

	            }

	            // h3事件
	            let h3 = app.getHandleElem('h3');

	            h3.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();
	                
	                // 替换元素标签名,并赋值给新标签
	                let h3Element = that.#replaceTagName(currentElem,'h3');
	              
	                // 将h3元素插入到当前元素的位置
	                dom.addBefore(currentElem,h3Element);
	              
	                // 移除原来的元素
	                currentElem.remove();

	            }

	            // h4事件
	            let h4 = app.getHandleElem('h4');

	            h4.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();
	              
	                // 替换元素标签名,并赋值给新标签
	                let h4Element = that.#replaceTagName(currentElem,'h4');
	              
	                // 将h4元素插入到当前元素的位置
	                dom.addBefore(currentElem,h4Element);
	              
	                // 移除原来的元素
	                currentElem.remove();

	            }

	            // h5事件
	            let h5 = app.getHandleElem('h5');

	            h5.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();
	              
	                // 替换元素标签名,并赋值给新标签
	                let h5Element = that.#replaceTagName(currentElem,'h5');
	              
	                // 将h5元素插入到当前元素的位置
	                dom.addBefore(currentElem,h5Element);
	              
	                // 移除原来的元素
	                currentElem.remove();

	            }

	            // h6事件
	            let h6 = app.getHandleElem('h6');

	            h6.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();
	              
	                // 替换元素标签名,并赋值给新标签
	                let h6Element = that.#replaceTagName(currentElem,'h6');
	              
	                // 将h6元素插入到当前元素的位置
	                dom.addBefore(currentElem,h6Element);
	              
	                // 移除原来的元素
	                currentElem.remove();

	            }

	            // text事件
	            let text = app.getHandleElem('text');

	            text.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();
	                
	                // 替换元素标签名,并赋值给新标签
	                let textElement = that.#replaceTagName(currentElem,'p');
	              
	                // 将text元素插入到当前元素的位置
	                dom.addBefore(currentElem,textElement);
	              
	                // 移除原来的元素
	                currentElem.remove();

	            }
	        }
	    })

	    // 加粗事件
	    that.#addCorePlugin('bold',{
	        menu: {
	            text: 'B',
	            group: true,
	            handle: 'test'
	        },
	        handle:function(app){

	            let elem = app.getHandleElem('test');

	            elem.onclick = function(e){

	                let currentElem = app.getCursorElem();

	                if(currentElem.style.fontWeight == "bold"){
	                    currentElem.style.fontWeight = "";
	                }else{
	                    currentElem.style.fontWeight = "bold";
	                }

	                //  // 设置光标位置
	                // app.#setCursor(currentElem,1);
	            }
	        }
	    })

	    // 斜体事件
	    that.#addCorePlugin('italic',{
	        menu: {
	            text: "I",
	            class:"open-italic",
	            handle: 'italic'
	        },
	        handle:function(app){

	            let elem = app.getHandleElem('italic');

	            elem.onclick = function(e){

	                let currentElem = app.getCursorElem();

	                if(currentElem.style.fontStyle == 'italic'){
	                    currentElem.style.fontStyle = '';
	                }else{
	                    currentElem.style.fontStyle = 'italic';
	                }

	                // // 设置光标位置
	                // app.#setCursor(currentElem,1);
	            }
	        }
	    })

	    // 下划线事件
	    that.#addCorePlugin('underline',{
	        menu: {
	            text: "U",
	            class:"open-underline",
	            handle: 'underline'
	        },
	        handle:function(app){

	            let elem = app.getHandleElem('underline');

	            elem.onclick = function(e){

	                let currentElem = app.getCursorElem();

	                if(currentElem.style.textDecoration == 'underline'){

	                    currentElem.style.textDecoration = '';
	                }else{
	                    currentElem.style.textDecoration = 'underline';
	                }

	                // // 设置光标位置
	                // app.#setCursor(currentElem,1);
	            }
	        }
	    })

	    // 中划线事件
	    that.#addCorePlugin('through',{
	        menu: {
	            text: "S",
	            class:"open-through",
	            handle: 'through'
	        },
	        handle:function(app){

	            let elem = app.getHandleElem('through');

	            elem.onclick = function(e){

	                let currentElem = app.getCursorElem();

	                if(currentElem.style.textDecoration == 'line-through'){

	                    currentElem.style.textDecoration = '';
	                }else{
	                    currentElem.style.textDecoration = 'line-through';
	                }

	                // // 设置光标位置
	                // app.#setCursor(currentElem,1);
	            }
	        }
	    })


	    // 文本排列
	    that.#addCorePlugin('textAlign',{
	        menu: {
	            text: "对齐",
	            group: true,
	            items: [
	                {
	                    text: "左对齐",
	                    handle: 'text-left'

	                },
	                {
	                    text: "居中对齐",
	                    handle: 'text-center'

	                },
	                {
	                    text: "右对齐",
	                    handle: 'text-right'
	                },
	            ]
	        },
	        handle:function(app){

	            // 左对齐事件
	            let left = app.getHandleElem('text-left');

	            left.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();
	                
	                currentElem.style.textAlign = 'left';

	                // // 设置光标位置
	                // app.#setCursor(currentElem,1);
	            }

	            // 居中对齐事件
	            let center = app.getHandleElem('text-center');

	            center.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();

	                currentElem.style.textAlign = 'center';

	                // // 设置光标位置
	                // app.#setCursor(currentElem,1);
	            }

	            // 右对齐事件
	            let right = app.getHandleElem('text-right');

	            right.onclick = function(e){

	                // 获取当前元素
	                let currentElem = app.getCursorElem();

	                currentElem.style.textAlign = 'right';

	                // // 设置光标位置
	                // app.#setCursor(currentElem,1);
	            }
	        }
	    })


	    // 分割线事件
	    that.#addCorePlugin('divider',{
	        menu: {
	            text: "—",
	            group: true,
	            handle: 'divider'
	        },
	        handle:function(app){

	            let elem = app.getHandleElem('divider');

	            elem.onclick = function(e){

	                let currentElem = app.getCursorElem();

	                let html = `<div contenteditable="false"><hr></div>`;

	                that.insertHtml(currentElem,html);
	                
	            }
	        }
	    })

	    // 清除样式事件
	    that.#addCorePlugin('clearStyle',{
	        menu: {
	            text: "格式样式",
	            group: true,
	            handle: 'clear'
	        },
	        handle:function(app){

	            let elem = app.getHandleElem('clear');

	            elem.onclick = function(e){

	                let currentElem = app.getCursorElem();

	                currentElem.style = "";

	                // // 设置光标位置
	                // app.#setCursor(currentElem,1);
	            }
	        }
	    })

	    // 添加连接
	    that.#addCorePlugin('link',{
			menu: {
			 	text: "链接",
			 	class: '',
                handle: 'link'
			},
			handle:function(app){

				let elem = app.getHandleElem('link');

				elem.onclick = function(e){

					let dialog = `<div id="open-editor-link-dialog" class="open-editor-link-dialog" style="position:absolute;top:10px;left:10px;z-index:10;width: 300px;box-shadow:1px 1px 7px rgba(0, 0, 0, .15);box-sizing: border-box;padding: 12px 16px 16px;font-size: 14px;color: #333333;border-radius: 2px;height:auto;background:var(--color-bg-1)">
									<div class="open-editor-dialog-input">
										<label style="display: inline-block;line-height: 36px">链接文本</label>
										<input type="text" id="open-editor-link-text" style="outline:none;border: 1px #ddd solid;width: 100%;line-height: 30px;box-sizing: border-box;padding: 0 8px;border-radius: 2px;">
									</div> 
									<div class="open-editor-dialog-input">
										<label style="display: inline-block;line-height: 36px">链接地址</label>
										<input type="url" id="open-editor-link-url" style="outline:none;border: 1px #ddd solid;width: 100%;line-height: 30px;box-sizing: border-box;padding: 0 8px;border-radius: 2px;">
									</div> 
									<div class="open-link-dialog-footer" style="text-align: right;margin-top: 12px;">
										<button id="open-editor-link-cancel" style="border: 1px solid #dddddd;padding: 4px 10px;border-radius: 2px;background: #F2F6FC;">取消</button>
										<button id="open-editor-link-confirm" style="border: 1px solid #409EFF;color: #ffffff;padding: 4px 10px;border-radius: 2px;background: #409EFF;margin-left: 8px;">确认</button>
									</div> 
								</div>`;

					// 设置编辑器窗口为绝对定位
					app.data.moduleElem.style.position = 'relative';

					// 添加元素到编辑器
					app.data.moduleElem.insertAdjacentHTML('beforeend',dialog);

					// 获取弹出框容器dom
					let dialogElem = document.querySelector("#open-editor-link-dialog");

					// 获取取消按钮
					let cancel = document.querySelector("#open-editor-link-cancel");

					// 获取确定按钮
					let confirm = document.querySelector("#open-editor-link-confirm");

					// 获取链接文本
					let text = document.querySelector("#open-editor-link-text");

					// 获取链接地址
					let url = document.querySelector("#open-editor-link-url");

					// 删除弹出框
					let removeDialog = function(){

						dialogElem.remove();
					}

					// 
					dialogElem.onclick = function(e){

						e.stopPropagation();
						return false;
					}

					// 删除按钮事件
					cancel.onclick = function(){

						removeDialog();

						// 获取当前光标元素
						let currentElem = app.getCursorElem();

						// 设置光标位置
	                	app.setCursor(currentElem,1);
					}

					// 确定按钮事件
					confirm.onclick = function(){

						// 获取文本值
						let textValue = text.value;

						// 获取url
						let urlValue = url.value;

						// 验证数据
						if(textValue == "" || urlValue == ""){
							console.log("不能为空")
						}

						let html = `<p><a style="text-decoration:underline!important;" contenteditable="false" href="${urlValue}" target="_blank" >${textValue}</a></p>`;

						// 获取当前光标元素
						let currentElem = app.getCursorElem();
						
					    currentElem.insertAdjacentHTML('afterend',html);

					    // 设置光标位置
	                	app.setCursor(currentElem,1);

	                	removeDialog();
					}
				
					// 点击编辑器时关闭弹窗
					app.onClick(function(){

						removeDialog();

						// 获取当前光标元素
						let currentElem = app.getCursorElem();

						// 设置光标位置
	                	app.setCursor(currentElem,1);
					})
			
				}
			}
		})

		// 添加图片
		that.#addCorePlugin('image',{
			menu: {
				text: '图片',
				items: [
					{
						text: '网络图片',
						handle: 'lineimage'
					},
					{
						text: '本地图片',
						handle: 'localimage'
					},
				]
			},
			handle: function(app){

				// 添加全局自定义参数对象
				app.addConfig('image');

				// image默认参数
				// let image = {
				// 	server:"",//服务器地址
				// 	size: 1024*2 *2, // 图片大小
				// 	name: "",// 数据库图片字段名
				// 	type: '', // 图片类型
				// 	// 自定义图片插入
				// 	// @param insertImage
				// 	// @param res
				// 	customInsert: function(insertImage,res){

				// 	}
				// }
				
				let local = app.getHandleElem('localimage');

				// 本地图片
				local.onclick = function(e){

					// 获取全局自定义的属性值
					let input = document.createElement('input');
					input.type = 'file';
					input.accept = 'image/png|image/jpeg|image/webp';

					// 添加一个事件监听器来处理文件选择
					input.addEventListener('change', function(event) {

					    let file = event.target.files[0]; // 获取选中的文件

					    // 获取插件自定义配置参数
					    let imgConfig = app.config.image;

					    // 判断是否配置图片大小限制验证
					    if(imgConfig.size && file.size > imgConfig.size ){
					    	throw new Error("图片超过2m");
					    	return false;
					    }

					    // 判断是否配置图片上传服务器
					    if(imgConfig.server && imgConfig.name){

					    	// 创建FormData对象
					    	const formData = new FormData();

					    	// 图片对象添加到FormData对象
					    	formData.append(imgConfig.name,file);

					    	fetch(imgConfig.server,{
					    		method: 'POST',
					    		body: formData
					    	})
					    	.then(response =>{ 
					    		
					    		response.json().then(res=>{
					    			
					    			if(res.status == 1 ){

					    				// 拼接路径
					    				let imgUrl = res.data.image;

					    				// 如果自定义插入方法
					    				if(imgConfig.customInsert && typeof imgConfig.customInsert === 'function'){
					    					app.config.image.customInsert(app.insertImage,imgUrl);
					    				}else{
					    					// 没有自定义插入方法，默认插入到编辑器
	            							app.insertImage(imgUrl);
					    				}
					    			}
					    		})
					    	}) 
    						.catch(error => {
    							console.error(error)
    						});
					    }else{

					    	// 默认插入base64格式图片
					    	let reader = new FileReader();

						    // 读取图片本地预览
	            			reader.readAsDataURL(file);

	            			// 获取图片信息
	            			reader.onload = function(e){
	            				// 获取图片base64文件
	            				let img = e.target.result;
	            				// 插入到编辑器
	            				app.insertImage(img);
	            			}
					    }
					});

					input.click();

				}


				// 网络图片
				let line = app.getHandleElem('lineimage');

				line.onclick = function(){

					let dialog = `<div id="open-editor-image-dialog" class="open-editor-image-dialog" style="position:absolute;top:10px;left:10px;z-index:10;width: 300px;box-shadow:1px 1px 7px rgba(0, 0, 0, .15);box-sizing: border-box;padding: 12px 16px 16px;font-size: 14px;color: #333333;border-radius: 2px;height:auto;background:var(--color-bg-1);">
									<div class="open-editor-dialog-input">
										<label style="display: inline-block;line-height: 36px">图片地址</label>
										<input type="url" id="open-editor-image-adress" value="" placeholder="图片src地址" style="border: 1px #ddd solid;width: 100%;line-height: 30px;box-sizing: border-box;padding: 0 8px;border-radius: 2px;outline:none;">
									</div> 
									<div class="open-editor-dialog-input">
										<label style="display: inline-block;line-height: 36px">图片描述</label>
										<input type="text" id="open-editor-image-alt" placeholder="图片alt描述"  value="" style="border: 1px #ddd solid;width: 100%;outline:none;line-height: 30px;box-sizing: border-box;padding: 0 8px;border-radius: 2px;">
									</div> 
									<div class="open-image-dialog-footer" style="text-align: right;margin-top: 12px;">
										<button id="open-editor-image-cancel" style="border: 1px solid #dddddd;padding: 4px 10px;border-radius: 2px;background: #F2F6FC;cursor:pointer;">取消</button>
										<button id="open-editor-image-confirm" style="border: 1px solid #409EFF;cursor:pointer;color: #ffffff;padding: 4px 10px;border-radius: 2px;background: #409EFF;margin-left: 8px;">确认</button>
									</div> 
								</div>`;

					// 设置编辑器窗口为绝对定位
					that.config.moduleElem.style.position = 'relative';

					// 添加元素到编辑器
					that.config.moduleElem.insertAdjacentHTML('beforeend',dialog);

					// 获取弹出框容器dom
					let dialogElem = document.querySelector("#open-editor-image-dialog");

					// 获取取消按钮
					let cancel = document.querySelector("#open-editor-image-cancel");

					// 获取确定按钮
					let confirm = document.querySelector("#open-editor-image-confirm");

					// 获取图片地址
					let adress = document.querySelector("#open-editor-image-adress");

					// 获取图片描述
					let alt = document.querySelector("#open-editor-image-alt");

					// 删除弹出框
					let removeDialog = function(){

						dialogElem.remove();
					}

					// 
					dialogElem.onclick = function(e){

						e.stopPropagation();
						return false;
					}

					// 删除按钮事件
					cancel.onclick = function(){

						removeDialog();

						// 设置光标位置
		            	that.#setCursor(currentElem,1);
					}

					// 确定按钮事件
					confirm.onclick = function(){

						let imgUrl = '';

						// 重新设置adress
						if(adress.value){
							imgUrl = adress.value;
						}

						let alt = '';

						if(alt.value){
							alt = alt.value;
						}

						app.insertImage(imgUrl,alt);

		            	removeDialog();
					}
				}
			}
		})
	}


	// 添加工具栏菜单
	#addMenu(tool){

		// 缓存实例
		const that = this;

	    // 默认参数配置
	    let menu = {
	        text: '', // 文本名
	        group: false, // 是否是小组
	        class: '', // 样式类名
	        style: {},// 自定义css样式
	        handle: '', // 事件
	    }

	    // 判断参数值和类型
	    if(!tool || !that.#typeObject(tool))return false;

	    // 合并参数
	    let config = that.#merge(menu,tool);

	    if(tool.items){
	        config.items = tool.items;
	    }

	    // 添加到menus配置参数
	    that.config.menus.push(config);
	}

	// 添加插件菜单数据添加到实例上
	#addPluginMenu(){

		// 缓存实例
		const that = this;

		// 遍历插件，提取菜单信息，添加到菜单对象
		Object.keys(editer.modules).forEach(function(module){

			// 如果菜单对象存在，则添加
	        if(editer.modules[module].menu){
	            that.#addMenu(editer.modules[module].menu)
	        }
	    })
	}

	// 插件菜单事件添加到实例上
	#addPluginHandle(){

		// 缓存实例
		const that = this;

		// 插件可调用api
		let api = {
			config: editer.config,
			data: that.config,
			setCursor: (currentElem,index)=>that.#setCursor(currentElem,index),
			getCursorElem: ()=>that.#getCursorElem(),
			getHandleElem: (handleName)=>that.#getHandleElem(handleName),
			insertText: (text)=>{that.insertText(text)},
			insertHtml: (html)=>{that.insertHtml(html)},
			insertImage: (url)=>{that.insertImage(url)},
			insertLink: (link)=>{that.insertLink(link)},
			addConfig: (name)=>{that.#addConfig(name)},
			on: (type,fn)=>{that.on(type,fn)},
			onClick: (fn)=>{that.on('click',fn)},
			onKey: (fn)=>{that.on('key',fn)},
		}

		// 遍历模块添加菜单事件
	    Object.keys(editer.modules).forEach(function(module){

	        if(editer.modules[module].handle){

	            editer.modules[module].handle(api);
	        }
	        
	    })
	}

	// 插件添加全局自定义参数
	#addConfig(name){

		let attr = name;

		// 添加到全局配置参数
		editer.config[attr] = '';
	}

	/**
	 * 插件订阅事件：编辑器点击事件
	 * @param type:string 事件类型：click,key,
	 * @param fn:function 回调执行函数
	*/
	on(type,fn){

		// 事件类型
		let types = ['click','key'];

		// 检测参数类型正确是否正确
		if(typeof type === 'string' && types.includes(type) && typeof fn === 'function'){

			switch (type) {
				// 点击类型
				case 'click':
					// 添加事件到click数组等待触发
					editer.#pluginApi.click.push(fn);
					break;
				case 'key':
					// 添加事件到key数组等待触发
					editer.#pluginApi.key.push(fn)
					break;
			}
		}
	}

	/***************************** 编辑器事件处理 *****************************/

	// input输入事件
	#onInput(){

		// 缓存实例
		const that = this;

	    // 编辑器添加输入监听事件
	    that.config.editorElem.addEventListener('input', function(e){
	        
	        // 显示隐藏提示信息
	        that.#togglePlaceholder();

	        // 如果当前内容是第一个行
	        that.#insertFirstLine();

	        // 保存光标数据
	        that.#saveCursorData();

	        // 实时更新字数
	        that.#setCount();

	        // 清除当前行的span
	        that.#clearSpan();

	        // 延迟更新元素组件id
	        setTimeout(function(){

	        	// 重新排序内容行号
	            that.#sortContentLine();

	       }, 150)

	       if(typeof that.config.onChange === 'function'){
	       		
	       		let html = that.getHtml();

	       		that.config.onChange(html);
	       }
	    })
	}

	// 键盘事件处理
	#onKey(){

		// 缓存实例
	    const that = this;

	    // 添加键盘事件监听
	    that.config.editorElem.addEventListener('keydown', function(e){

	    	/************************** 获取光标所在元素 ****************************/
	    	// 获取当前选区
            const selection = window.getSelection();

            // 当前元素
            let currentElem = '';
 
            // 如果选区不为空，获取光标所在的元素
            if (selection.rangeCount > 0) {

                var currentNode = selection.getRangeAt(0).startContainer;
                // console.log('当前光标所在的元素:', currentNode);

                currentElem = currentNode;
            }

            if(!currentElem instanceof HTMLElement){
            	currentElem = dom.parent(currentElem);
            }

	        // 删除按钮事件
	        if(e.key == "Backspace" || e.keyCode == '8'){
	            
	        }

	        // 换行
	        if (e.key == "Enter" || e.keyCode == "13") {

	        	// 添加自定义新内容行
	        	that.#insertLine();

	        	// 阻止默认换行时添加新行
	        	e.preventDefault();

	            // 换行时，更新新行和前一行的非p标签
	            setTimeout(function(){

	            	if(currentElem instanceof HTMLElement){

	            		// 获取当前行号
		            	let index = dom.attr(currentElem,'open-editor-line');

		            	/***************************** 对前一行处理 *****************************/
		            	// 获取前一个兄弟元素
		            	let prevElem = dom.prev(currentElem);

		            	if(prevElem && prevElem.tagName === 'DIV'){

		            		// 创建新行dom
		            		let newLine = that.#newLine(index - 1);

		            		// 插入新行
		            		dom.addAfter(prevElem,newLine);

		            		// 删除兄弟元素
		            		prevElem.remove();
		            	}
	            	}

	            	/*********************************** 对当前行处理 **********************************/



	            	// 如果当前元素不是p元素
            		if(currentElem.tagName !== 'P'){

            			if(currentElem.tagName === "DIV" && utils.existClass(currentElem,'open-editor-container-main')){

            				// 获取所有子元素
	            			let childs = currentElem.children;

	            			// 遍历子元素
	            			for(let i = 0; i < childs.length; i ++){
	            				
	            				// 如果子元素有div标签
	            				if(childs[i].tagName === 'DIV'){

	            					// 创建新行dom
				            		let newLine = that.#newLine(i + 1);

				            		// 插入新行
				            		dom.addAfter(childs[i],newLine);

				            		// 如果div默认会添加p元素
				            		if(dom.childs(childs[i],'p').length > 0){

				            			// 获取子元素，并清空br符号
				            			let html = childs[i].innerHTML.replace('<br>','');

				            			// 取出p元素添加到编辑器元素末尾
				            			currentElem.innerHTML += html;
				            		}

				            		// 删除当前div标签
				            		childs[i].remove();

				            		// 重置光标
				            		let newInsertElem = dom.child(currentElem,`[open-editor-line="${i + 1}"]`);

				            		if(newInsertElem){
				            			 // 设置光标到新行
		    							that.#setCursor(newInsertElem,0);
				            		} 
	            				}
	            			}
            			}else{

            				let type = ['H1','H2','H3','H4','H5','H6'];

            				// 创建新行dom
		            		let newLine = that.#newLine(0);

		            		if(type.includes(currentElem.tagName)){

		            			currentElem = currentElem.nextElementSibling;

		            			// 插入新行
			            		dom.addAfter(currentElem,newLine);

			            		currentElem.remove();
		            		}
            			}
            		}

	            	// 重新排序内容行号
	            	that.#sortContentLine();

	            },50)
	        }

	        // 执行插件开发api自定义的键盘事件
	        editer.#pluginApi.key.forEach(item=>{
	        	item();
	        })

	        that.#saveCursorData();

	        // 阻止默认行为
	    	// e.preventDefault();
	    })
	}

	// 光标聚焦事件
	onFocus(){

		// 缓存实例
	    const that = this;

	    // 添加聚焦事件监听
	    that.config.editorElem.addEventListener('focus', function(){

	        // 如果当前编辑器空白，则自动添加一行文本元素
	        that.#insertFirstLine();

	        // 保存光标数据
	        that.#saveCursorData();
	    })
	} 

	// 设置只读模式
	onRead(){
		this.#read();
	}

	// 点击事件
	#onClick(){

		// 缓存实例
	    const that = this;

	    // 添加编辑器点击事件
	    that.config.editorElem.addEventListener('click', function(e){

	        // 保存光标数据
	        that.#saveCursorData();

	        // 获取最后一个元素
	        let childs = that.config.editorElem.children;
	        let lastChild = childs[childs.length - 1];

	        if(dom.attr(lastChild,"contenteditable") == 'false'){

	            // 获取当前点击的是否是编辑器窗口
	            let target = e.target;

	            // 如果是编辑器窗口，则自动添加一行编辑元素到子元素最后
	            if(utils.existClass(target,'open-editor-container-main')){

	                // 插入新的编辑行
	                that.#insertLine();
	            }
	        }

	        // 执行插件开发api自定义的点击事件
	        editer.#pluginApi.click.forEach(item=>{
	        	item();
	        })
	    })
	} 


	/********************************** 编辑器内部api ********************************/

	// 初始化创建第一行内容
	#insertFirstLine(){

		// 缓存实例
		const that = this;

	    // 获取所有子元素
	    let childs = that.config.editorElem.children;

	    // 如果没有元素
	    if(childs.length < 1){

	        // 创建第一行文本元素
	        that.#insertLine();
	    }
	}

	// 创建新行
	#newLine(index){
		return `<p open-editor-line="${index}" style=""><br></p>`;
	}

	// 插入新的内容行
	#insertLine(){

		// 缓存实例
		const that = this;

	    // 获取所有内容元素
	    let childs = that.config.editorElem.children;

	    // 获取新的行数
	    let index = childs.length + 1;

	    // 添加新行
	    that.config.editorElem.innerHTML += `<p open-editor-line="${index}" style=""><br></p>`;

	    // 获取新添加的行dom
	    let currentDom = dom.child(that.config.editorElem,`[open-editor-line="${index}"]`);

	    // 设置光标到新行
	    that.#setCursor(currentDom,0);
	}

	// 清除删除键时自动添加的span标签
	#clearSpan(){

		// 缓存实例
	    const that = this;

	    // 获取当前元素
	    let currentElem = that.#getCursorElem();

	    if(!currentElem) return false;

	    // 获取元素内的span标签
	    let childSpan = dom.child(currentElem,'span');

	    if(childSpan){

	        // 获取文本
	        let text = childSpan.innerText;

	        // 清除span标签
	        childSpan.remove();

	        // 文本重新添加到p元素
	        currentElem.innerText += text;

	        // 设置光标
	        that.#setCursor(currentElem.lastChild,that.config.cursor.index);
	    }
	}

	// 编辑器api
	#focus(){
		this.config.editorElem.focus();
	}

	// 设置只读
	#read(){
		// 设置编辑器contenteditable为false
		dom.attr(this.config.editorElem,'contenteditable',"false");
	    // 修改底部模式提示文本
	    this.#setMode('只读');
	}

	// 设置底部显示模式信息
	#setMode(name){
		dom.child(this.config.footerElem,'.open-editor-mode-text').innerText = name;
	}

	// 更新底部显示文本长度
	#setCount(){
		// 获取文本长度
		let count = this.getText().length;
		// 获取文本dom
    	let countDom = dom.child(this.config.footerElem,'.open-editor-count-text');
    	// 修改文本
    	countDom.innerText = count;
	}

	// 去除内容的行号
	#clearLineNumber(html){

		// 正则表达式匹配open-editor-line属性，并将其替换为空字符串
	    const rex1 = /open-editor-line="[^"]*"/gi;

	    // 正则表达式匹配contenteditable属性，并将其替换为空字符串
	    const rex2 = /contenteditable="[^"]*"/gi;

	    html = html.replace(rex1,'');
	    html = html.replace(rex2,'');

	    return html;
	}

	// 控制提示信息显示隐藏
	#togglePlaceholder(){

		// 缓存实例
		const that = this;

	    // 获取子元素数量
	    let childs = that.config.editorElem.children;

	    // 获取文本长度
	    let textLength = that.getText().length;

	    if(textLength > 0 || childs.length > 1){

	        if(!utils.existClass(that.config.placeholderElem,'open-editor-placeholder-hide')){
	            
	            dom.addClass(that.config.placeholderElem,'open-editor-placeholder-hide');
	        }

	    }else{

	        // 显示
	        dom.removeClass(that.config.placeholderElem,'open-editor-placeholder-hide');
	    }
	}

	// 只替换元素标签名，不更改属性和文本
	#replaceTagName = function(elem,newTagName){

	    // 获取小写标签名
	    let tag = elem.tagName.toUpperCase().toLowerCase();

	    // 获取元素
	    let html = elem.outerHTML;   

	    // 前置标签匹配
	    let rexStart = new RegExp(`<${tag}`, "g");
	    // 结尾标签匹配
	    let rexEnd = new RegExp(`${tag}>`, "g");

	    // 替换标签
	    let newHtml = html.replace(rexStart,`<${newTagName}`);
	        newHtml = newHtml.replace(rexEnd,`${newTagName}>`);

	    // 返回html字段
	    return newHtml;
	}

	// 重新排序内容行号
	#sortContentLine(){

		// 缓存实例
		const that = this;

		// 获取所有子元素
        let childs = dom.childs(that.config.editorElem,'[open-editor-line]');

        // 修改元素组件id
        if(childs.length > 1){
            childs.forEach(function(item,index){
                dom.attr(item,'open-editor-line',`${index + 1}`);
            })
        }
        // 保存光标数据
        that.#saveCursorData();
	}

	/************************************ toolbar相关方法 **********************************/

	// 渲染toolbar工具栏
	#renderToolbar(){

		// 缓存实例
		const that = this;

		// 工具栏容器dom
	    let toolbarContainer = function(content){
	        return `<div class="open-editor-toolbar">
	                    <ul class="open-editor-toolbar-wrapper">${content}</ul>
	                </div>`;
	    }

	    // 有二级菜单的菜单的元素模版
	    let liContent = function(text,content){
	        return `<li class="open-editor-toolbar-item" open-tool="title">
	                    <span class="open-editor-toolbar-dropdown">${text}</span>
	                    <ul class="open-editor-toolbar-subitem">${content}</ul>
	                </li>`;
	    }

	    // toolbar元素
	    let toolbarElem = '';

	    // 遍历工具栏数据
	    that.config.menus.forEach(function(item){

	        // 判断当前菜单是否设置为组标识
	        if(item.group){
	            toolbarElem += `<li class="open-editor-left-line"></li>`;
	        }

	        if(item.items){

	            // 二级菜单元素
	            let subitems = '';
	            item.items.forEach(function(subitem){
	                subitems += `<li><span class="${subitem.class}" open-editor-handle="${subitem.handle}">${subitem.text}</span></li>`;
	            })
	            toolbarElem += liContent(item.text,subitems);
	        }else{
	            toolbarElem += `<li class="open-editor-toolbar-item" open-editor-handle="${item.handle}">
	                                <span class="${item.class}">${item.text}</span>
	                            </li>`;
	        }
	    })

	    // 组合完的toolbar完整元素
	    toolbarElem = toolbarContainer(toolbarElem);

	    // 如果自定义toolbar容器，并且容器存在，则渲染到自定义容器
	    if(that.config.toolbar.id !== "" && dom.get(that.config.toolbar.id)){

	        // 获取自定义容器dom对象
	        let toolbarContainer = dom.get(that.config.toolbar.id);

	        dom.addChild(toolbarContainer,toolbarElem);

	        // 设置toolbar元素对象
	        that.#setToolbarElem(toolbarContainer);

	        return false;
	    }

	    // 如果没有自定义容器，默认添加到当前编辑器
        dom.addFirstChild(that.config.elem,toolbarElem);
        // 设置toolbar元素对象
        that.#setToolbarElem();
	}

	/**
	 * 设置toolbar元素dom
	*/
	#setToolbarElem(){

		if(this.config.toolbar.id){
			this.config.toolbarElem = dom.get(this.config.toolbar.id);
		}else{
			this.config.toolbarElem = dom.child(this.config.elem,'.open-editor-toolbar');
		}
	}

	// toolbar工具栏事件监听
	#onToolbarClick(){

		// 缓存实例
		const that = this;

	    // 监听toolbar点击事件
	    that.config.toolbarElem.addEventListener('click',function(){

	        // 设置提示信息
	        that.#togglePlaceholder();
	        
	        // 工具栏点击菜单设置后，延迟复位光标位置
	        setTimeout(function(){

	            // 获取当前光标元素
	            let currentElem = that.#getCursorElem();

	            // 重新设置光标位置
	            that.#setCursor(currentElem,1);

	        }, 300)
	    })
	}

	/******************************** 编辑器操作 ***********************************/

	// 渲染编辑区域
	#renderEdit(){

		const that = this;

		let editElem = `<div class="open-editor-container">
			<div class="open-editor-placeholder"></div>
            <div class="open-editor-container-wrapper">
            	<div contenteditable="true" class="open-editor-container-main">
            </div>
            </div>
        </div>`;

        // 添加到页面组件容器
        dom.addChild(that.config.elem,editElem);

        // 获取组件dom对象
        that.#setEditElem();
	}

	// 设置编辑器区域dom对象
	#setEditElem(){

		const that = this;
		// 获取编辑器dom
		that.config.moduleElem = dom.child(that.config.elem,'.open-editor-container');
		// 获取编辑器窗口dom
		that.config.editorElem = dom.child(that.config.elem,'.open-editor-container-main');
		// 获取编辑器提示信息dom
		that.config.placeholderElem = dom.child(that.config.elem,'.open-editor-placeholder');

	}


	/******************************** 编辑器footer栏操作 ***********************************/

	// 渲染编辑区域
	#renderFooter(){

		const that = this;

		let footerElem = `<div class="open-editor-footer">
            <div class="open-editor-count">字数：<span class="open-editor-count-text">0</span></div>
            <div class="open-editor-mode">当前模式：<span class="open-editor-mode-text">编辑</span></div>
            <div>openEditor</div>
        </div>`;

        // 添加到页面组件容器
        dom.addChild(that.config.elem,footerElem);

        // 获取组件dom对象
        that.#setFooterElem();
	}

	// 设置编辑器区域dom对象
	#setFooterElem(){

		// 获取编辑器footer栏dom
		this.config.footerElem = dom.child(this.config.elem,'.open-editor-footer');
	}



	/**************************************** 光标操作 **************************************/

	// 设置光标位置
	#setCursor = function(element,index){

	    // 保存当前的选区
	    var selection = window.getSelection();
	    var range = document.createRange();

	    // 选中目标元素
	    range.selectNodeContents(element);

	    range.setStart(element, index);
	    range.setEnd(element, index);

	    // 清除当前选区并设置新的选区
	    selection.removeAllRanges();
	    selection.addRange(range);

	    // 保存光标
	    this.#saveCursorData();

	}

	// 获取光标
	#getCursor(){

		// 缓存实例
		const that = this;

	    // 获取光标对象
	    let selection = window.getSelection();

	    // 获取光标所在元素
	    let anchorNode = selection.anchorNode;

	    // 确保选中的节点是父元素也就是编辑器窗口元素内的一个子节点
	    if (anchorNode && that.config.editorElem.contains(anchorNode)) {

	        // 返回光标偏移量
	        return selection.anchorOffset;

	    } else {

	        // 如果没有找到就返回0
	        return 0;
	    }
	}

	// 快捷获取光标所在的元素
	#getCursorElem(){
		// 获取光标所在元素
	    return dom.child(this.config.editorElem,`[open-editor-line="${this.config.cursor.line}"]`);
	}

	// 保存光标数据
	#saveCursorData(){

		// 缓存实例
		const that = this;

		// 保存当前光标,行号和元素
	    that.config.cursor = {
	        elem: that.#getElemOfCursor(),
	        line: dom.attr(that.#getElemOfCursor(),'open-editor-line'),
	        index: that.#getCursor()
	    }
	}

	// 内部使用获取光标所在的元素
	#getElemOfCursor = function(){

		// 缓存实例
	    const that = this;

	    // 获取光标对象
	    let selection = window.getSelection();

	    // 获取光标所在元素
	    let anchorNode = selection.anchorNode;

	    // 确保选中的节点是父元素也就是编辑器窗口元素内的一个子节点
	    if (anchorNode && that.config.editorElem.contains(anchorNode)) {

	        let elem = '';

	        if(anchorNode instanceof HTMLElement){
	            elem = anchorNode;
	        }else{
	            elem = dom.parent(anchorNode);
	        }

	        // 返回光标所在元素
	        return elem;

	    } else {

	        // 如果没有找到就返回false
	        return false;
	    }
	}

	// 获取tool工具栏事件元素
	#getHandleElem(handleName){
	    return dom.child(this.config.toolbarElem,`[open-editor-handle="${handleName}"]`);
	}



	/************************************ 工具方法 ***********************************/

	/**
	 * 合并新对象到原对象
	 * @param obj: object 原对象
	 * @param newObj: object 新的合并对象
	*/
	#merge(obj,newObj){

		// 缓存实例
		const that = this;

		// 如果参数类型不对,返回对象
		if(!that.#typeObject(obj) || !that.#typeObject(newObj))return {};

		// 如果obj存在，newObj不存在，返回obj
		if(that.#typeObject(obj) && !that.#typeObject(newObj))return obj;

		// 合并数组
		function mergeArray(arr,newArr){

			// 数据缓存
			let arrData = [];

			arr.forEach((item,index)=>{
				if(newArr[i]){
					arrData.push(newArr[i])
				}else{
					arrData.push(item);
				}
			})
			return arrData;
		}

		// 合并对象
		function mergeObject(obj,newObj){

			// 遍历源对象
			for(let key in obj){

				// 判断目标对象是否有当前key值
				if(newObj.hasOwnProperty(key)){

					// 合并对象值
					if(that.#typeObject(obj[key])){
						obj[key] = mergeObject(obj[key],newObj[key]);

					// 合并数组值
					}else if(that.#typeArray(obj[key])){
						obj[key] = mergeArray(obj[key],newObj[key]);

					// 合并文本值
					}else{
						obj[key] = newObj[key];
					}
				}
			}
			// 返回值
			return obj;
		}
		return mergeObject(obj,newObj);
	}

	// 验证是否是object类型
	#typeObject(val){
		return Object.prototype.toString.call(val) === '[object Object]';
	}

	// 验证是否是array类型
	#typeArray(val){
		return Array.isArray(val);
	}

}

export default editer;