/**
 * 树组件
*/
import app from './core.js'

	let dom = app.dom,
		utils = app.utils;

	/**
	 * 查找元素第一子级儿子元素集合
	 * @param node: HTMLElement 树状列表容器对象
	 * @return HTMLElement 返回找到的第一子级元素数组
	*/
	let findFirst = function(node){

		// 克隆当前元素
		let vclone = node.cloneNode(true);

		// 获取所有子元素列表
		let vgroups = dom.childs(vclone,'.open-tree-group');

		// 遍历列表，清除列表内的元素
		vgroups.forEach(group=>{
			group.innerHTML = '';
		})

		// 返回当前父元素仅剩下的最近的子元素
		let vitems = dom.childs(vclone,'.open-tree-item');

		return vitems;
	}

	/**
	 * 树状图组件
	*/
	function tree(){
		this.config = {
			trees: dom.getAll('.open-tree')
		}
	}

	tree.prototype.init = function(){

		let trees = this.config.trees;

		let id = 1;

		/**
		 * 添加自定义标签元素id
		 * @param node: HTMLElement 树状列表对象
		 * @param tree: HTMLElement 树状列表对象,同上是同一个对象
		*/
		let addAttrId = function(node,tree){

			let vitems = findFirst(node);

			vitems.forEach((vitem,index)=>{

				let items = dom.childs(tree,'.open-tree-item');

				// item标签添加pid
				items.forEach(item=>{

					// 判断元素dom属性值是否和克隆元素dom属性值相同
					if(dom.attr(item,'data-v-dom') == dom.attr(vitem,'data-v-dom')){

						let parentGroup = dom.parent(item);

						// 判断当前元素是不是已经到树状列表顶级标签
						if(utils.existClass(parentGroup,'open-tree-group')){

							let parentLi = dom.parent(parentGroup);

							let parentId = dom.attr(parentLi,'data-id');

							dom.attr(item,'data-id',`${parentId}-${id}`);

						}else{

							dom.attr(item,'data-id',`${id}`);
						}

						id ++;
					}
				})

				let vgroup = dom.child(vitem,'.open-tree-group');

				if(vgroup){

					let groups = dom.childs(tree,'.open-tree-group');

					groups.forEach(group=>{

						if(dom.attr(group,'data-v-dom') == dom.attr(vgroup,'data-v-dom')){

							addAttrId(group,tree);
						}
					})
				}
			})
		}

		// // 遍历选项设置原始高度
		// trees.forEach(tree=>{
		// 	let checkboxs = dom.childs(tree,'.open-tree-checkbox');

		// 	checkboxs.forEach(checkbox=>{
		// 		let group = dom.next(checkbox);

		// 		if(group && utils.existClass(group,'open-tree-group')){

		// 			let height = `${group.scrollHeight}`;

		// 			dom.attr(group,'data-height',height);
		// 		}

		// 	})

		// })
		
		/**
		 * 添加列表group标签dom属性
		 * @param trees:HTMLElement 树状列表容器数组集合
		*/
		let addAttrDom = function(trees){
			
			// 遍历树状列表数组
			trees.forEach(tree=>{

				// 获取树状列表下的所有子列表
				let groups = dom.childs(tree,'.open-tree-group');

				// 遍历子列表集合
				groups.forEach(group=>{

					// 向列表添加自定义dom属性值
					dom.attr(group,'data-v-dom',utils.randomId());
				})

				// 获取树状图item元素集合
				let items = dom.childs(tree,'.open-tree-item');

				// 遍历items
				items.forEach(item=>{

					// 添加自定义dom属性值
					dom.attr(item,'data-v-dom',utils.randomId());
				})
			})
		}


		/**
		 * 列表展开和收起
		 * @param checkboxs: array 选择元素数组集合
		*/
		let toggleUpDown = function(checkboxs){

			/**
			 * 复选元素点击展开和收纳
			 * @param checkbox:HTMLElement 当前点击的选择框元素对象
			*/
			let toggle = function(checkbox){

				// 获取兄弟列表
				let group = dom.next(checkbox,'.open-tree-group');
				
				// 图标
				let icon = dom.child(checkbox,'.open-tree-icon');

				// 如果没有当前元素下没有列表，则停止执行，如果有列表，继续执行
				if(!group){
					return false;
				}

				// 判断列表是否展开，如果展开则关闭，如果关闭则展开
				if(utils.existClass(group,'open-tree-group-open')){

					// 关闭

					// 关闭子列表
					dom.removeClass(group,'open-tree-group-open');

					// 点击时修改图片箭头方向
					dom.setClass(icon,'open-tree-icon')

				}else{

					// 展开

					// 点击时修改图片箭头方向
					dom.addClass(icon,'open-tree-icon-open');

					// 展开列表
					dom.addClass(group,'open-tree-group-open');
				}
			}

			// 遍历选择框元素数组
			checkboxs.forEach(checkbox=>{

				// 选择框添加点击事件
				checkbox.onclick = function(){

					// 展开关闭
					toggle(checkbox);
				};
			})
		}

		// 切换选择框状态样式
		let toggleCheckboxStatus = function(tree){

			// 获取所有checkbox选择框旁的label
			let labels = dom.childs(tree,'.open-tree-label');

			/**
			 * 点击事件触发checkbox框checked状态改变
			 * @param e:object 事件对象
			 * @param label: 当前点击dom对象
			*/
			let toggleStatus = function(e,label){

				// 获取上一个兄弟元素，input复选框
				let input = dom.prev(label);

				// 修改复选框状态
				input.checked = !input.checked;

				// 如果选中，判断上次及子级选择框，修改选择框状态
				if(input.checked){

					// 查找父级选择框，修改状态
					let setParentStatus = function(input){

						let parent = dom.parent(input);
						let parentItem = dom.parent(parent);
						let parentGroup = dom.parent(parentItem);
						let parentsItem = dom.parent(parentGroup);

						if(utils.existClass(parentsItem,'open-tree-item')){

							let checkbox = dom.child(parentsItem,'.open-tree-checkbox .open-tree-check');
							checkbox.checked = true;

							setParentStatus(checkbox);
						}
					}

					setParentStatus(input)
					
				}else{
					
					// 查找子级选择框，修改状态
					let setChildStatus = function(input){
						let parent = dom.parent(input);
						let parentItem = dom.parent(parent);

						let inputs = dom.childs(parentItem,'.open-tree-check');

						inputs.forEach(item=>{
							item.checked = false;
						})

					}

					setChildStatus(input)
				}

				// 防止点击冒泡
				e.stopPropagation();
			}

			// 遍历labels
			labels.forEach(label=>{

				// lable添加点击事件
				label.onclick = function(e){

					// 切换checkbox状态样式
					toggleStatus(e,label);
				}
			})
		}

		/**
		 * 隐藏没有列表元素的前置箭头图标
		 * @param trees:HTMLElement 树状列表容器dom对象集
		*/
		let hideIcon = function(trees){

			// 遍历trees对象数组
			trees.forEach(tree=>{

				// 获取单个树状列表下所有子选择器集合
				let items = dom.childs(tree,'.open-tree-item');

				// 遍历子选择器集合
				items.forEach(item=>{

					// 获取子选择器下列表
					let group = dom.child(item,'.open-tree-group');

					// 判断列表是否存在
					if(!group){

						// 如果不存在，则隐藏图标

						// 查找子元素内的图标元素
						let icon = dom.child(item,'.open-tree-checkbox .open-tree-icon');

						// 隐藏图片元素
						dom.hide(icon);
					}
				})
			})
		}

		trees.forEach((tree)=>{

			// 获取所有复选元素
			let checkboxs = dom.childs(tree,'.open-tree-checkbox');
			
			// 列表的展开和关闭
			toggleUpDown(checkboxs);

			// 点击选择框的选中未选中状态切换
			toggleCheckboxStatus(tree);
		})

		// 添加属性值
		addAttrDom(trees);

		// 添加pid,获取值操作嵌套父子数据数组
		trees.forEach(item=>{
			addAttrId(item,item);
		})

		hideIcon(trees);
	}

	tree.prototype.val = function(elem){

		// 获取树状列表对象
		let tree = dom.get(elem);

		// 选择值
		let values = [];

		// 
		let index = 1;

		let getVal = function(node){

			// 获取子组件合集
			let items = dom.childs(node,'.open-tree-item');

			// 遍历子组件
			items.forEach(item=>{

				// 获取选择框
				let checkbox = dom.child(item,'.open-tree-checkbox .open-tree-check');

				// 获取label
				let label = dom.child(item,'.open-tree-checkbox .open-tree-label');

				// 获取元素属性id
				let attrId = dom.attr(item,'data-id');

				// 临时数据对象
				let data = {
					id: '',
					aid: attrId,
					title: label.innerText,
					checked: checkbox.checked,
					child: []
				};

				// 判断attrId是否存在
				if(attrId){

					// 分解id为数组
					let attr = attrId.split('-');

					// 获取数组对象最后一位值的长度
					let attrLength = attr[attr.length - 1].length;

					// 获取pid
					let pid = attrId.substring(0,attrId.length - (attrLength + 1));

					if(values.length > 0){

						let mark = false;

						let setVal = function(value){

							value.forEach(item=>{

								if(item.aid == pid){
									item.child.push(data);

									mark = true;
								}else{
									setVal(item.child,pid);
								}
							})
						}

						setVal(values,pid);

						if(!mark){
							
							values.push(data);
						}
	
					}else{
						values.push(data);
					}
					
				}
			})

			// 去掉values值里aid临时计算属性
			let removeAid = function(value){
				value.forEach(item=>{
					if(item.aid){
						delete item.aid;
					}
					removeAid(item.child)
				})
			}
			removeAid(values);
		}

		getVal(tree);

		return values;

	}

	// 动态渲染，或静态初始化
	tree.prototype.render = function(options){

		// data存在则动态渲染，否则静态初始化
		if(options.data){

		}else{
			// data不存在静态初始化
		}
	}


export default tree;