/**
 * 分页器插件
*/

import { dom,utils } from './core.js'

class paginationer{

	// 插件描述
	static name = "paginationer";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发的分页器插件";
	static date = "2024-07-03";

	// 缓存实例
	static #modules = {};

	// 动态渲染
	static render(options={}){

		let paginationElem = dom.get(options.id);

		// 如果元素不存在
		if(!paginationElem)return false;

		// 检测当前组件是否涉及重复渲染
		let openuiId = dom.attr(paginationElem,'openui-id');

		// 应对vue框架等组件切换重复渲染处理
		if(openuiId)return false;

	    options.elem = paginationElem;

	    paginationer.#create(options);
	}

	// 事件监听
	static on(id,fn){

		let elem = dom.get(id)

		// id获取元素不存在
		if(!id || !elem) return false;

		if(fn && typeof fn !== 'function') return false;

		// 获取组件模版id
		let openId = dom.attr(elem,'openui-id');

		// 静态模式未获取，则动态模式下获取
    	if(!openId){
        	openId = dom.attr(dom.child(elem,'.open-pagination'),'openui-id');
    	}

		let instance = paginationer.#modules[openId];

		instance.config.on = fn;

	}

	// 获取当前页面
	static value(id){

		let elem = dom.get(id)

		// id获取元素不存在
		if(!id || !elem) return false;

		// 获取组件模版id
		let openId = dom.attr(elem,'openui-id');

		// 静态模式未获取，则动态模式下获取
    	if(!openId){
        	openId = dom.attr(dom.child(elem,'.open-pagination'),'openui-id');
    	}

		let instance = paginationer.#modules[openId];

		return instance.config.currentPage;
	}

	/**
	* 创建实例
	* @param options:object 参数
	* @param elem: object 静态组件dom对象
	*/
	static #create(options={}){

		// 生成组件id
	    const templateId = utils.randomId();

	    /**
		 * 合并参数
		 * @attr data.custom: object 自定义数据
		 * @attr data.system: object 系统数据
		*/
	    const data = {
	    	custom: options,
	    	system: {
	    				moduleId:templateId,
	    			}
	    };

	    // 创建实例,保存实例
		paginationer.#modules[templateId] = new paginationer(data);
	}

	// 构造函数
	constructor(options){

		const that = this;

		// 配置参数
		that.config = {
			id: '',
			elem:'',
			moduleId: '', // 组件id
			moduleElem: '',//组件dom对象
			pageSize: 10,
			pageCount: 0,
			currentPage: 1,
			total: 0,
			btnCount: 5,
			background: false,
			click: '',
			on: '',
			language: {
				first: '首页',
				prev: '上一页',
				next: '下一页',
				last: '尾页'
			},
			show: {
				first: true,
				last: true,
				total: true,
				prev: true,
				next: true
			}
		}

		// 初始化
		that.#init(options);

		// 渲染dom
		that.#renderElem();

		that.#onClick();

	}

	// 初始化数据
	#init(options){

		const that = this;

		let defaults = {
			id: '',
			pageSize: '',
			total: '',
			click: '',
			background: false,
			language: {
				first: '首页',
				prev: '上一页',
				next: '下一页',
				last: '尾页'
			},
			show: {
				first: true,
				last: true,
				total: true,
				prev: true,
				next: true
			},
		}

		/** 
		 * 解构参数
		 * custom:object 自定义参数
		 * system:object 系统参数
		*/
		let {custom,system} = options;

		// 合并自定义参数
		defaults = utils.merge(defaults,custom);

		// 合并组件配置参数
		that.config = utils.merge(that.config,{...defaults,...system});

		// 计算总页数
		that.config.pageCount = Math.ceil(that.config.total / that.config.pageSize);
		
		// 获取容器对象
		that.config.elem = dom.get(that.config.id);

		// 如果选择的页面大于当前总页码或者小于1，则默认第一页
		let current = that.config.currentPage;

		if(current < 1 || current > that.config.pageCount){
			that.config.currentPage = 1;
		}
	}

	// 渲染组件
	#renderElem(){

		let that = this;

		// 首页按钮
		let firstButton = that.#createFirst();

		// 尾页按钮
		let lastButton = that.#createLast();

		// 上一页
		let prevPage = that.#createPrev();

		// 下一页 
		let nextPage = that.#createNext();

		// 总页码 
		let totalPage = that.#createTotal();

		// 页码
		let pagelist = that.#createPageNumber();

		// 拼接分页器dom
		let content = firstButton + prevPage + pagelist + nextPage + lastButton + totalPage;

		let html = that.#createContainer(content);

		// 清空元素
		that.config.elem.innerHTML = '';

		// 添加dom到页面
		dom.addChild(that.config.elem,html)

		// 获取组件对象
		that.config.moduleElem = dom.child(that.config.elem,'.open-pagination');

		// 设置组件id
		dom.attr(that.config.moduleElem,'openui-id',that.config.moduleId);

	}

	// 创建页码列表
	#createPageNumber = function(){

		const that = this;

		const config = that.config;

		// 显示的按钮数
		let btns = config.btnCount;

		// 页码总数
		let pages = config.pageCount;

		// 前置按钮数量
		let prevBtns = Math.floor(btns/2);

		// 后置按钮数量
		let nextBtns = Math.ceil(btns/2);

		// 显示按钮数组
		let lists = [];

		// 如果当前页数小于显示按钮数
		if(pages <= btns){

			for(let i = 1;i < pages + 1;i ++){

				lists.push(i);
			}

		// 如果当前页数已经到最后,则永远保持最后几个数字固定不动
		}else if(parseInt(config.currentPage) + parseInt(nextBtns) > pages){

			let index = pages - btns + 1;

			for(let i = index;i < pages + 1;i ++){

				lists.push(i);
			}
		}else{

			// 当前的页面减去前置的按钮数量，得到当前第一个按钮的下标
			let index = parseInt(config.currentPage) - parseInt(prevBtns);

			// 判断如果第一个索引小于1，则默认为1
			if(index < 1){
				index = 1;
			}

			for(let i = index;i < index + btns;i ++){

				lists.push(i);
			}
		}

		// 拼接dom
		let btnList = '';

		lists.forEach(list=>{

			// 如果i等于当前页码
			if(config.currentPage == list){

				btnList += that.#createChecked(list);
			}else{

				btnList += that.#createLink('',`open-page=${list}`,`${list}`);
			}
		})

		return btnList;
	}


	// 创建总页码
	#createTotal(){

		const that = this;
		const config = that.config;

		let total = '';

		if(!config.show.total) return total;

		total = that.#createNotLink(`共${config.pageCount}页`);

		return total;
	}

	// 创建下一页按钮
	#createNext(){

		const that = this;
		const config = that.config;

		let next = '';

		if(!config.show.next) return next;

		if(config.currentPage >= config.pageCount){

			next = that.#createLink('open-pagination-disabled','',config.language.next);
		}else{

			// 下一页页码
			let nextNumber = parseInt(config.currentPage) + 1;

			next = that.#createLink('',`open-page="${nextNumber}"`,config.language.next);
		}

		return next;
	}

	// 创建上一页按钮
	#createPrev(){

		const that = this;
		const config = that.config;

		let prev = '';

		if(!config.show.prev) return prev;

		if(config.currentPage <= 1){

			prev = that.#createLink('open-pagination-disabled','',config.language.prev);
		}else{

			// 上一页页面
			let prevNumber = parseInt(config.currentPage) - 1;

			prev = that.#createLink('',`open-page="${prevNumber}"`,config.language.prev);
		}
		
		return prev;
	}

	// 创建首页按钮
	#createFirst(){
		const that = this;
		const config = that.config;

		let first = '';

		if(!config.show.first) return first;

		if(config.currentPage <= 1){

			first = that.#createLink('open-pagination-disabled','',config.language.first);
		}else{
			first = that.#createLink('',`open-page="1"`,config.language.first);
		}
		
		return first;
	}

	// 创建尾页按钮
	#createLast(){
		const that = this;
		const config = that.config;

		let last = '';

		if(!config.show.last) return last;

		if(config.currentPage >= config.pageCount){

			last = that.#createLink('open-pagination-disabled','',config.language.last);
		}else{
			last = that.#createLink('',`open-page="${config.pageCount}"`,config.language.last);
		}

		return last;
	}

	/**
	 * 创建链接按钮
	 * @param className:string 样式名
	 * @param attr:string 属性
	 * @param text:string 文本名
	*/
	#createLink(className,attr,text){
		return `<a class="open-pagination-link ${className}" ${attr}>${text}</a>`;
	}

	/**
	 * 创建选中按钮
	 * @param text:string 文本
	*/
	#createChecked(text){
		return `<span class="open-pagination-link open-pagination-active">${text}</span>`;
	}

	/**
	 * 创建分页器容器
	 * @param content:string 模版字符串
	*/
	#createContainer(content){
		const that  = this;
		return `<div class="open-pagination ${that.config.background ? 'open-pagination-bg':''}" openui-id="${that.config.moduleId}"><div class="open-pagination-wrapper">${content}</div></div>`;
	}

	/**
	 * 创建非点击按钮
	 * @param text:string 按钮文本
	*/
	#createNotLink(text){
		return `<span>${text}</span>`;
	}


	// 点击事件
	#onClick(){

		let that = this;

		let links = dom.childs(that.config.elem,'a');

		links.forEach(link=>{

			if(dom.attr(link,'open-page')){

				link.onclick = function(){

					let number = dom.attr(link,'open-page');

					that.config.currentPage = number;

					that.#renderElem();

					that.#onClick();

					setTimeout(function(){

						// 执行回调函数
						that.config.click && that.config.click(number);
					
						// 执行全局回调函数
						that.config.on && that.config.on(number);
					}, 100)
				}
			}
		})
	}

}

export default paginationer;