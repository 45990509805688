/**
 * 面包屑导航
*/
import { dom,utils } from './core.js'

class breadcrumber{

	// 插件描述
	static name = "breadcrumb";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发的面包屑组件";
	static date = "2024-07-04";

	// 缓存实例
	static #modules = {};

	// 渲染
	static render(options){

		// 获取挂载dom对象
		let breadcrumbElem = dom.get(options.id);

		// 如果dom对象不存在
		if(!breadcrumbElem)return false;

		// 检测当前组件是否涉及重复渲染
		let openid = dom.attr(breadcrumbElem,'openui-id');

		// 应对vue框架等组件切换重复渲染处理
		if(openid)return false;

		// 创建实例
        breadcrumber.#create(options,breadcrumbElem);
	}

	// 重新渲染
	static reload(id,data){

		// 获取元素对象
		let elem = dom.get(id);

		// 如果元素不存在获取数据不是数组格式
		if(!elem || !Array.isArray(data))return false;

		// 获取组件id
		let openid = dom.attr(elem,'openui-id');

		// 如果不存在
		if(!openid){

			// 获取
			let moduleElem = dom.child(elem,'.open-breadcrumb');

			// 获取组件id
			openid = dom.attr(moduleElem,'openui-id');
		}

		// 如果openid不存在
		if(!openid)return false;

		// 获取实例
		let instance = breadcrumber.#modules[openid];

		// 修改数据
		instance.config.data = data;

		// 重新渲染
		instance.#renderElem();

		// 监听单页面模式下点击事件
		if(instance.config.mode === 'single'){
			instance.#onSingleClick();
		}
		
	}

	/**
	* 创建实例
	* @param options:object 参数
	* @param elem: object 静态组件dom对象
	*/
	static #create(options={},elem=''){

		// 生成组件id
	    const templateId = utils.randomId();

	    /**
		 * 合并参数
		 * @attr data.custom: object 自定义数据
		 * @attr data.system: object 系统数据
		*/
	    const data = {
	    	custom: options,
	    	system: {
				moduleId:templateId,
				elem: elem
			}
	    };

	    // 创建实例,缓存实例
		breadcrumber.#modules[templateId] = new breadcrumber(data);
	}

	// 构造方法
	constructor(options){

		// 缓存实例
		const that = this;

		// 配置参数
		that.config = {
			id: '', // 容器id
			elem: '', // 容器dom对象
			moduleId: '', // 组件id
			moduleElem: '', // 组件dom对象
			data: [], // 数据
			mode:'multi', // 类型:single单页模式|multi多页模式 ,默认多页面
			click: '',// 回调方法
		}

		// 初始化
		that.#init(options);

		// 渲染dom
		that.#renderElem();

		// 监听单页面模式下点击事件
		if(that.config.mode === 'single'){
			that.#onSingleClick();
		}
	}

	// 初始化
	#init(options){

		const that = this;

		// 默认参数
		let defaults = {
			id: '',
			mode: 'multi',
			data: [],
			click: '',// 回调方法
		}

		/** 
		 * 解构参数
		 * custom:object 自定义参数
		 * system:object 系统参数
		*/
		let {custom,system} = options;

		// 合并自定义参数
		defaults =  utils.merge(defaults,custom);

		// 缓存配置参数
		let config = {
			...defaults,
			moduleId: system.moduleId,
			elem: system.elem,
		}

		// 合并配置参数
		that.config = utils.merge(that.config,config);

	}

	// 渲染dom
	#renderElem(){
		
		// 缓存实例
		const that = this;

		// 默认多页面获取dom字符串
		let elem = that.#createMultiElem();

		// 如果是单页面模式
		if(that.config.mode === 'single'){
			elem = that.#createSingleElem();
		}

		// 清空元素
		that.config.elem.innerHTML = '';

		// 添加到页面
		dom.addChild(that.config.elem,elem);

		// 获取组件dom
		that.config.moduleElem = dom.child(that.config.elem,`[openui-id=${that.config.moduleId}]`);
	}

	// 单页面模式下事件监听
	#onSingleClick(){

		// 缓存实例
		const that = this;

		// 获取当前组件下可点击链接
		let routes = dom.childs(that.config.moduleElem,'[open-breadcrumb-href]');

		// 遍历链接，添加点击事件
		routes.forEach((elem, index)=>{
			
			// 添加点击事件
			elem.onclick = ()=>{

				// 获取url
				let url = {
					name: elem.innerText,
					url: dom.attr(elem,'open-breadcrumb-href')
				};

				// 调用回调函数
				if(that.config.click && typeof that.config.click === 'function'){
					that.config.click(url);
				}
			}
			
		});
	}


	// 创建多页面dom
	#createMultiElem(){

		// 缓存实例
		const that = this;

		// 缓存dom元素
		let routes = '';

		// 遍历路由路径数据，拼接dom
		that.config.data.forEach((item,index)=>{

			// 路径最后一个默认无法点击
			if(index == that.config.data.length - 1){
				routes += `<li class="open-breadcrumb-item active">${item.name}</li>`
			}else{
				routes += `<li class="open-breadcrumb-item"><a href="${item.url}">${item.name}</a></li>`
			}
		})

		// 返回完整dom字符串
		return `<ul class="open-breadcrumb" openui-id="${that.config.moduleId}">${routes}</ul>`;
	}

	// 创建单页面dom
	#createSingleElem(){

		// 缓存实例
		const that = this;

		// 缓存dom元素
		let routes = '';

		// 遍历路由路径数据，拼接dom
		that.config.data.forEach((item,index)=>{

			// 路径最后一个默认无法点击
			if(index == that.config.data.length - 1){
				routes += `<li class="open-breadcrumb-item active">${item.name}</li>`
			}else{
				routes += `<li class="open-breadcrumb-item"><a open-breadcrumb-href="${item.url}">${item.name}</a></li>`
			}
		})

		// 返回完整dom字符串
		return `<ul class="open-breadcrumb" openui-id="${that.config.moduleId}">${routes}</ul>`;
	}
}

export default breadcrumber;