/**
 * 下拉框组件
*/
import app from './core.js'

// 工具函数对象
const dom = app.dom;
const utils = app.utils;

// 缓存实例
const dropdownModules = {};

// 视图对象
const dropdownView = {
	// 创建容器
	createElem: function(options){

		let list = dropdownView.createList(options);

		return `<div class="open-dropdown" openui-id="${options.moduleId}">
					<div class="open-dropdown-title">${options.title}</div>
					<ul class="open-dropdown-list">${list}</ul>
				</div>`;
	},
	// 创建列表
	createList: function(options){

		if(options.data.length < 1)return false;

		let list = '';

		options.data.forEach((item,index)=>{
			list += `<li class="open-dropdown-item" open-value="${item.id || index}">${item.text}</li>`;
		})

		return list;
	}
}


// 模块核心
const dropdownCore = function(options){

	// 配置参数
	this.config = {
		id: '',
		elem: '',
		moduleId: '',
		moduleElem: '',
		listElem:'',// 下拉列表容器
		title:'',
		static: false,//静态模式，默认不是
		data: [],
		click: '',
		on: ''
	}

	// 初始化
	this.init(options);

	// 如果不是静态模式，动态渲染模版
	if(!this.config.static){
		// 渲染
		this.render();
	}
	// 添加事件
	this.addEvent();
}

dropdownCore.prototype.init = function(options){
	const that = this;

	// 默认参数
	let defaults = {
		id: '',
		moduleId: '',
		moduleElem: '',
		title:'',
		data: [],
		static: false,
		click: ''
	}

	// 合并参数
	defaults = utils.merge(defaults,options);

	// 合并配置参数
	that.config = utils.merge(that.config,defaults);

	// 如果是静态渲染
	if(that.config.static){

		// 获取容器对象
		that.config.elem = that.config.moduleElem;

		// 获取列表元素
		that.config.listElem = dom.child(that.config.moduleElem,'.open-dropdown-list');

		// 配置组件id
		dom.attr(that.config.moduleElem,'openui-id',that.config.moduleId);

	}else{

		// 容器dom对象
		that.config.elem = dom.get(that.config.id);

		if(options.data && Array.isArray(options.data)){
			that.config.data = options.data;
		}
	}

}

// 渲染
dropdownCore.prototype.render = function(){

	const that = this;

	let vdom = dropdownView.createElem(that.config);

	dom.addChild(that.config.elem,vdom);

	// 获取组件dom对象
	that.config.moduleElem = dom.child(that.config.elem,`[openui-id=${that.config.moduleId}]`);

	// 获取下拉列表dom
	that.config.listElem = dom.child(that.config.elem,'.open-dropdown-list');
}


// 添加事件
dropdownCore.prototype.addEvent = function(){

	const that = this;

	// 添加列表的hover事件
	that.config.moduleElem.onmouseenter = function(){

		dom.addClass(that.config.elem,'open-dropdown-show');

	}
	// 添加列表的leave事件
	that.config.moduleElem.onmouseleave = function(){

		dom.removeClass(that.config.elem,'open-dropdown-show');
	}

	// 列表点击事件
	that.config.listElem.onclick = function(e){

		let target = e.target;

		if(utils.existClass(target,'open-dropdown-item')){

			// 获取当前点击的数据
			let data = {
				text: target.innerText,
				value: dom.attr(target,'open-value')
			}

			setTimeout(function(){
				// 当前组件回到函数
				that.config.click  && that.config.click(data);
			
				// 组件级监听事件
				that.config.on  && that.config.on(data);

			}, 500)
		}

	}

}

// 模块对象
const dropdown = {

	
}

// 动态渲染
dropdown.render = function(options){

	// 生成模版id
	let templateId = utils.randomId();

	options.moduleId = templateId;

	let instance = new dropdownCore(options);

	dropdownModules[templateId] = instance;
}

// 静态渲染
dropdown.renderStatic = function(){
	
	// 获取页面静态下拉菜单
	let drops = dom.getAll('.open-dropdown');

	// 如果没有静态组件，则停止渲染
	if(drops.length < 1)return false;

	// 遍历静态组件集
	drops.forEach(item=>{

		// 创建组件id
		let templateId = utils.randomId();

		let options = {
			moduleId: templateId,
			moduleElem: item,
			static: true,
		}

		let instance = new dropdownCore(options);

		dropdownModules[templateId] = instance;
	})

};

// 自定义下拉框内容
/**
 * 自定义下拉框内容
 * @param options.id:string 触发显示的元素
 * @param options.template: string 显示的内容id
*/
dropdown.box = function(options){

	const that = this;

	// 获取点击dom
	let button = dom.get(options.id);

	// 获取容器相对页面位置
	let buttonElem = button.getBoundingClientRect();
	let top = button.offsetTop;

	// 获取容器相对页面的位置
	let position = {
		top: top + parseInt(buttonElem.height) + 5,
		left: buttonElem.left
	}

}



// 组件全局监听
dropdown.on = function(id,fn){

	// 判断类型
    if(!id || typeof id !== 'string') return false;

    // 判断回到函数类型
    if(fn && typeof fn !== 'function') return false; 

    // 格式化id
	id = utils.formatId(id);

	// 获取组件dom对象
	let template = dom.get(id);

	// 获取组件id
	let openid = dom.attr(template,'openui-id');

	// 静态模式未获取，则动态模式下获取
    if(!openid){
        openid = dom.attr(dom.child(template,'.open-dropdown'),'openui-id');
    }

    // 获取实例
    let instance = dropdownModules[openid];

    // 添加监听函数到实例
    instance.config.on = fn;

}

export default dropdown;
