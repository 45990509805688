/**
 * 图片上传预览
*/
import { dom,utils } from './core.js'

class uploader{

	// 插件描述
	static name = "upload";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发的图片上传预览组件";
	static date = "2024-07-03";

	// 缓存实例
	static #modules = {};

	// 自动渲染
	static auto(id){

		let elem = dom.get(id);

		if(elem){
			uploader.#autoSingle(id);
		}else{
			uploader.#autoAll();
		}
	}

	// 自动渲染单个
	static #autoSingle(id){

		// 检测id是否是dom对象类型 
		let isDom = id instanceof HTMLElement;

		// 获取元素对象
		let uploadElem;

		// 如果id不是dom对象
		if(!isDom){
			uploadElem = dom.get(id)
		}else{
			uploadElem = id;
		}

		if(!uploadElem && !isDom)return false;

		// 检测当前组件是否涉及重复渲染
    	let openuiId = dom.attr(uploadElem,'openui-id');
    
    	// 应对vue框架等组件切换重复渲染处理
    	if(openuiId) return false;

    	// 获取元素子组件
    	let childs = uploadElem.children;

    	if(childs.length < 1)return false;

        // 获取组件用户自定义id
        let customId = dom.attr(uploadElem,'id') || '';

        // 配置参数
        let options = {
        	id: customId, // 用户自定义属性id
        }

        // 创建实例
        uploader.#create(options,uploadElem);

	}
	// 自动渲染全部
	static #autoAll(){

		// 获取页面所有静态组件
        let uploadList = dom.getAll('.open-upload');

        // 如果组件数量少于1，不渲染
        if(uploadList.length < 1)return false;

        // 遍历静态组件
        uploadList.forEach(upload=>{

        	uploader.#autoSingle(upload);

        })
	}

	// 动态渲染
	static render(options){

		let uploadElem = dom.get(options.id);

		if(!uploadElem)return false;

		// 检测当前组件是否涉及重复渲染
		let openuiId = dom.attr(uploadElem,'openui-id');

		// 应对vue框架等组件切换重复渲染处理
		if(openuiId)return false;

        options.elem = uploadElem;

        uploader.#create(options);
	}

	// 事件监听
	static on(id,fn){

        let elem = dom.get(id)

		// id获取元素不存在
		if(!id || !elem) return false;

		if(fn && typeof fn !== 'function') return false;

		// 获取组件模版id
		let templateId = dom.attr(elem,'openui-id');

		let instance = uploader.#modules[templateId];

		instance.config.on = fn;
	}

	/**
	* 创建实例
	* @param options:object 参数
	* @param elem: object 静态组件dom对象
	*/
	static #create(options={},elem=''){

		// 生成组件id
	    const templateId = utils.randomId();

	    /**
		 * 合并参数
		 * @attr data.custom: object 自定义数据
		 * @attr data.system: object 系统数据
		*/
	    const data = {
	    	custom: options,
	    	system: {
	    				moduleId:templateId,
	    				staticElem: elem
	    			}
	    };

	    // 创建实例,保存实例
		uploader.#modules[templateId] = new uploader(data);
	}


	// 构造函数
	constructor(options){

		const that = this;

		// 配置参数
		that.config = {
            id: '', // 组件id
            elem: '',
            moduleId: '',
            moduleElem: '',// 组件dom
            inputElem:'',
            imgsElem: '',
            static: false,// 是否静态渲染，默认false
            multiple: false, // 是否支持多图上传
            limit: 9,
            type: 'image/png|image/jpeg|image/webp|image/gif', // 图片类型
            size: '80px', // 图片和上传框容器宽高
            files: {
                // 单图片显示缓存
                file: "",
                // 单图片路径缓存
                fileData: "",
                // 多图显示缓存
                fileList: [],
                // 多图上传缓存
                fileListData: [],
            },
            click: '',
            before: '', // 配置参数前置检测
            on: '', // 全局监听
            globalBefore: '', // 全局前置检测
		}

		// 初始化
		that.#init(options);

		if(!options.system.staticElem){

			// 渲染dom
			that.#renderElem();

			that.#initAfter()
		}

		that.#onInput();

	}

	// 初始化数据
	#init(options){

		const that = this;

    	// 默认参数
        let defaults = {
            id: '',
        	elem: '',
            multiple: false, // 是否支持多图上传
            limit: 9,
            size: '80px', // 图片和上传框容器宽高
            before: '',
            click: ''
        };

        /** 
		 * 解构参数
		 * custom:object 自定义参数
		 * system:object 系统参数
		*/
		let {custom,system} = options;

        // 合并用户自定义参数
        defaults = utils.merge(defaults,custom);

        // 如果是静态渲染
        if(system.staticElem){

        	// 当前组件为静态组件
        	that.config.static = true;

        	let config = {
				...defaults,
				moduleId: system.moduleId,
				elem: system.staticElem,
			}

			// 设置其他参数
			config.moduleElem = config.elem;
			config.inputElem = dom.child(config.elem,'.open-upload-input');
			config.imgsElem = dom.child(config.elem,'.open-upload-img');
			config.multiple = config.inputElem.multiple;
			config.limit = dom.attr(config.inputElem,'open-upload-limit');

			// 合并参数到实例配置参数
			that.config = utils.merge(that.config,config);

        }else{

        	// 合并参数到实例配置参数
			that.config = utils.merge(that.config,{...defaults,...system});

			// 获取元素dom
			that.config.elem = dom.get(that.config.id); 
        }

        // 获取组件dom对象
        that.config.moduleElem = that.config.elem;

        // 添加组件id
        dom.attr(that.config.elem,'openui-id',that.config.moduleId);

        // 图片最多上传9张
        if(that.config.limit > 9){ that.config.limit = 9};

        // 图片类型字符串转换数组
        that.config.type = that.config.type.split('|');

	}

	// 渲染后的数据初始化
	#initAfter(){

		const that = this;

		// 获取输入框元素
    	that.config.inputElem = dom.child(that.config.elem,'.open-upload-input');

   	 	// 图片列表元素
    	that.config.imgsElem = dom.child(that.config.elem,'.open-upload-img');

	}

	// 组件图片选择事件
	#onInput(){
		let that = this;

         // 获取配置参数
        let config = that.config;

        // input输入框dom
        let input = config.inputElem;

        // 添加事件
        input.addEventListener('change',function(e){

            let data = new FormData();
            let reader = new FileReader();

            // 读取图片信息
            let files = e.target.files[0];

            // 图片类型
            let types = config.type;

            // 判断图片类型是否是png或jpeg/jpg格式
            let type = types.includes(files.type);

            if(!type){
                alert("图片格式不正确: 只支持png、jpeg、webp格式")
                return false;
            }

            // 全局钩子函数，图片上传前自定义函数检测
            if(that.config.globalBefore && that.config.globalBefore(files) === false)return false;

            // 钩子函数，图片上传前自定义函数检测
            if(that.config.before && that.config.before(files) === false)return false;

            // 读取图片本地预览
            reader.readAsDataURL(files);

            // 获取图片信息
            reader.onload = function(e){

                // 图片预览
                that.#preview(this,files);
            }
        })
	}


	// 渲染dom
	#renderElem(){

		const that = this;

		let elem =  `<div class="open-upload-img"></div>
                    <div class="open-upload-box" style="width:${that.config.size};height:${that.config.size}">
                        <input type="file" class="open-upload-input" ${that.config.multiple ? 'multiline':''}>
                        <p class="open-upload-icon"></p>
                    </div>`;
        dom.addChild(that.config.elem,elem);
	}

	// 创建单张图片预览
	#createImage(){

		const that = this;

		// 获取图片数据
        let file = that.config.files.file;

        // 清空图片列表
        that.config.imgsElem.innerHTML = '';

        // 如果没有图片，则中止后续程序
        if(!file) return false;

        // 创建img元素
        let imgDom = `<div class="open-upload-img-box"  style="width:${that.config.size};height:${that.config.size}">
                        <img class="open-upload-img-item" style="width:${that.config.size};height:${that.config.size}" src="${file}">
                            <p class="open-upload-img-cover"><span class="open-upload-remove">×</span></p>
                        </div>`;

        // 添加图片预览到组件
        dom.addChild(that.config.imgsElem,imgDom);

        that.#removeImage();

	}

	// 创建多张图片预览
	#createImageList(){

		const that = this;

        let fileList = that.config.files.fileList;

        // 清空图片列表
        that.config.imgsElem.innerHTML = '';

        // 元素列表容器
        let imgList = '';

        if(fileList.length < 1) return false;

        for (var i = 0; i < fileList.length; i ++) {

            // 累计添加img元素
            imgList += `<div class="open-upload-img-box" style="width:${that.config.size};height:${that.config.size}">
                            <img class="open-upload-img-item" style="width:${that.config.size};height:${that.config.size}" src="${fileList[i]}">
                            <p class="open-upload-img-cover"><span class="open-upload-remove" data-index="${i}">×</span></p>
                        </div>`;
        
        }

        // 添加图片元素列表到图片容器
        dom.addChild(that.config.imgsElem,imgList);

        that.#removeImageList();
	}

	// 删除单张图片
	#removeImage(){

		const that = this;

		let file = that.config.files.file;

        // 获取删除按钮元素
        let removeDom = dom.child(that.config.imgsElem,'.open-upload-remove');

        // 添加点击事件
        removeDom.onclick = function(){

            // 从路径列表删除图片
            that.config.files.file = '';

            // 从图片信息列表删除图片
            that.config.files.fileData = '';

            // 重新创建图片元素
            that.#createImage();

            that.config.inputElem.parentNode.style.display = 'block';

            // 回调函数
            that.config.click && that.config.click(that.config.files.fileData);

            // 全局监听函数
            that.config.on && that.config.on(that.config.files.fileData);

        }
	}

	// 删除图片列表
	#removeImageList(){

		const that = this;

        let fileList = that.config.files.fileList;

        // 添加删除事件
        let removes = dom.childs(that.config.imgsElem,'.open-upload-remove');

        removes.forEach(remove=>{
            // 添加点击事件
            remove.onclick = function(){

                let index = dom.attr(this,'data-index');

                // 从路径列表删除图片
                fileList.splice(index,1);

                // 从图片信息列表删除图片
                that.config.files.fileListData.splice(index,1);

                // that.config.inputElem.parentNode.style.display = 'block';

                // 回调函数
                that.config.click && that.config.click(that.config.files.fileListData);

                // 全局监听函数
                that.config.on && that.config.on(that.config.files.fileListData);

                 // 重新创建图片列表元素
                that.#createImageList();

	            // 多图片上传时判断是否超过限制图片
	            if(that.config.files.fileList.length < that.config.limit){
	                that.config.inputElem.parentNode.style.display = 'block';
	            }
            }
        })

	}

	 /**
     * 图片预览
     * @param _this:object FileReader对象
     * @param files: object formdata图片读取对象
    */
    #preview(_this,files){

    	const that = this;

        let config = that.config;

        // 图片数据对象
        let fileData = config.files;

        // 单图模式
        if(!that.config.multiple){

            fileData.file = _this.result;

            fileData.fileData = files;

            // 创建图片
            that.#createImage();

            // 单图片上传时判断是否超过限制图片
            if(fileData.file){
                config.inputElem.parentNode.style.display = 'none';
            }else{
                config.inputElem.parentNode.style.display = 'block';
            }

            // 回调函数
            config.click && config.click(fileData.fileData);

            // 全局监听函数
            config.on && config.on(fileData.fileData);

        }else{

            // 多图模式
            // 添加图片信息到数组
            fileData.fileListData.push(files);

            // 添加图片路径到数组
            fileData.fileList.push(_this.result);

            // 创建图片元素
            that.#createImageList(config);

            // 多图片上传时判断是否超过限制图片
            if(fileData.fileList.length >= config.limit){
                config.inputElem.parentNode.style.display = 'none';
            }else{
                config.inputElem.parentNode.style.display = 'block';
            }

            // 回调函数
            config.click && config.click(fileData.fileListData);

            // 全局监听函数
            config.on && config.on(fileData.fileListData);
        }

        // 初始化input值
        that.config.inputElem.value = null;
    }

}

export default uploader;