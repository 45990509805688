// 表单操作

import app from './core.js'

// 工具函数对象
let dom = app.dom,
	utils = app.utils;

	/**
	 * 表单组件
	*/
	function form() {

	    let form = {
	        name: 'form',
	        version: 'v1.0.0',
	        description: '基于JavaScript实现form组件',
	        author: 'liukaijian',
	        date: '2024-03-17 16:02'
	    }
	}

	/**
	 * 表单提交事件
	 * @param options.elem:string 按钮元素id或class
	 * @param options.click:function 回调函数，返回表单值
	 */
	form.prototype.submit = function(options) {

	    // 获取dom元素
	    let el = dom.get(options.elem);

	    /**
		 *	查找父级form元素 
		 * @param elem : HTMLElement 当前元素对象
		 * @return HTMLElement 返回查找的父元素对象
		*/
		let findFirstFormInParent = function(elem) {

		    // 获取当前元素的父元素
		    var currentParent = elem.parentElement;
		 
		    // 如果父元素存在，则向上查找直到找到form或者到达根元素
		    while (currentParent && currentParent.tagName !== 'FORM') {
		        currentParent = currentParent.parentElement;
		    }
		 
		    // 如果找到form，则返回它，否则返回null
		    return currentParent || null;
		}

	    // 添加点击事件
	    el.addEventListener('click', function() {

	        // 查找dom元素form父组件
	        let form = findFirstFormInParent(el);

	        // 获取表单所有输入元素
	        let forms = dom.childs(form, 'input,select,textarea');

	        let data = loopFormVal(forms);

	        if (options.click && typeof options.click == 'function') {
	            options.click(data);
	        }
	    })

	}

	/**
	 * 表单赋值取值
	 * @param options.elem:string 表单id
	 * @param options.data:function 回到函数，返回表单值
	 */
	form.prototype.val = function(options) {

	    // 查找dom元素form父组件
	    let form = dom.get(options.elem);

	    // 获取表单所有输入元素
	    let forms = dom.childs(form,'input,select,textarea');

	    // 判断如果是赋值
	    if (options.data) {
	        // 循环遍历元素获取值
	        forms.forEach((item, index) => {

	            if (!options.data[item.name]) return false;

	            item.value = options.data[item.name];

	            if (item.type == 'checkbox' || item.type == 'radio') {
	                if (options.data[item.name] == true) {
	                    item.checked = true;
	                } else {
	                    item.checked = false;
	                }
	            }
	        })
	    }

	    return loopFormVal(forms);

	}

	/**
	 * 初始化页面原生select标签
	 * @param options.type:string 渲染类型
	 * @param fn:function 回到函数，返回表单值
	 */
	form.prototype.initSelect = function() {

		const selects = dom.getAll('select');

		let newSelects = [];

		selects.forEach(item=>{
			if(!dom.child(dom.parent(item),'.open-v-select')){
				newSelects.push(item);
			}
		})

		let selectsData = [];

		newSelects.forEach(item=>{

			let select = {
				parent: item.parentElement,
				name: item.name,
				data: []
			}

			let options = dom.childs(item,'option');

			options.forEach(option=>{
				let item = {
					value: option.value,
					title: option.innerText,
					selected: option.selected
				}
				select.data.push(item);
			})

			selectsData.push(select);
		});

		selectsData.forEach(item=>{
			// 清除子元素
			item.parent.innerHTML = '';

			// 创建虚拟dom
		    let html = '';

		    item.data.forEach((i, index) => {
		        html += selectOptionDom(i.value,i.title,i.selected);

		    });
		    let container = createSelect(item.name,item.placeholder,html);

		    // 添加dom到页面
		    dom.addAfter(item.parent, container);

		    // js生成虚拟dom元素
		    let vdom = {
		    	select: dom.child(item.parent,'select'), // 原生select标签元素
		    	vselect: dom.child(item.parent,'.open-v-select'), // 虚拟select列表元素
		    	vselectTitle: dom.child(item.parent,'.open-v-select-title'), // 虚拟select选择框
		    	option: dom.childs(item.parent,'.open-v-select-item'), // 虚拟下拉列表元素
		    }

		    let selected = '';

		    item.data.forEach((item,index)=>{
		    	if(item.selected){
		    		selected = index;
		    	}
		    })

		    // 虚拟选择框添加点击事件
		    vdom.vselect.onclick = function(e){

		    	// 获取下拉列表
		    	let list = dom.child(this,'ul');

		    	// 判断下拉列表是否显示
		    	if(utils.existClass(list,'open-v-select-show')){
		    		// 显示则隐藏
		    		dom.setClass(list,'open-v-select-list open-v-select-hide');
		    	}else{
		    		hideSelect();

		    		// 隐藏则显示
		    		dom.setClass(list,'open-v-select-list open-v-select-show');
		    	}

		    	e.stopPropagation();
		    }

		    vdom.option.forEach(item=>{

		    	item.onclick = ()=>{

		    		// 隐藏选中状态
		    		vdom.option.forEach(i=>{
		    			dom.setClass(i,'open-v-select-item');
		    		});

		    		// 添加选中状态
		    		dom.addClass(item,'open-v-select-active');

		    		// 修改虚拟选择框文本颜色
		    		dom.addClass(vdom.vselectTitle,'open-vselect-title-active');

		    		// 设置虚拟选择框文本
		    		vdom.vselectTitle.innerText = item.innerText;
		    		
		    		// 设置选中的值到select标签
		    		checkSelect(vdom.select,item.dataset.value)
		    	}
		    });

		    // 设置选中的值到select标签
		    if(selected !== ''){
		    	// 设置虚拟选择框文本
		    	vdom.vselectTitle.innerText = item.data[selected].title;
		    	// 修改虚拟选择框文本颜色
	    		dom.addClass(vdom.vselectTitle,'open-vselect-title-active');
		    	checkSelect(vdom.select,item.data[selected].value);
		    }

		});

		// 监听页面点击事件，隐藏展开列表
		// window.addEventListener('click',function(){
		// 	hideSelect();
		// })
	}

	/**
	 * 动态渲染form元素
	 * @param options.type:string 渲染类型
	 * @param fn:function 回到函数，返回表单值
	 */
	form.prototype.render = function(options) {

	}

	/**
	 * 动态渲染复选框
	 * @param options.elem:string 元素容器选择器
	 * @param options.data:array 渲染数据
	 */
	form.prototype.renderCheckbox = function(options) {
	    // 获取checxbox元素
	    let checkboxDom = dom.get(options);
	    // 添加class
	    dom.addClass(checkboxDom, 'open-checkbox-group');

	    // 默认数据
	    let data = [{
	            value: 'checkbox1',
	            name: 'checkbox1',
	            title: '选择一',
	            checked: true,
	            disabled: true
	        },
	        {
	            value: 'checkbox2',
	            name: 'checkbox2',
	            title: '选择一',
	        },
	        {
	            value: 'checkbox3',
	            name: 'checkbox3',
	            title: '选择一',
	        },
	        {
	            value: 'checkbox4',
	            name: 'checkbox4',
	            title: '选择一',
	        },
	    ];

	    let html = '';

	    data.forEach((item, index) => {

	        // 生成随机id
	        let id = utils.randomId();

	        let createCheckbox = function(data){
	        	return `<div class="open-checkbox">
							<input type="checkbox" id="open_${data.id}" name="${data.name}" value="${data.value}" class="open-check" ${data.checked ? 'checked' : ''} ${data.disabled ? 'disabled' : ''}>
							<label class="open-check-label" for="open_${data.id}">${data.name}</label>
						</div>`;
	        };

	        let data = {
	        	id: id,
	        	...item
	        };

	        html += createCheckbox(data);

	    });

	    dom.addAfter(checkboxDom, html);

	}

	/**
	 * 动态渲染单选框
	 * @param options.elem:string 元素容器选择器
	 * @param options.data:array 渲染数据
	 */
	form.prototype.renderRadio = function(options) {
	    // 获取radio元素
	    let radioDom = dom.get(options);
	    // 添加class
	    dom.addClass(radioDom, 'open-radio-group');

	    // 默认数据
	    let data = [{
	            value: '1',
	            title: '选择一',
	            name: 'radio',
	            checked: true,
	            disabled: true
	        },
	        {
	            value: '2',
	            title: '选择二',
	            name: 'radio',
	        },
	        {
	            value: '3',
	            title: '选择三',
	            name: 'radio',
	        },
	        {
	            value: '4',
	            title: '选择四',
	            name: 'radio',
	        },
	    ];

	    let html = '';

	    data.forEach((item, index) => {

	        // 生成随机id
	        let id = utils.randomId();

	        let createRadio = function(data){
	        	return `<div class="open-radiobox">
							<input type="radio" id="open_${data.id}" name="${data.name}" value="${data.value}" class="open-radio" ${data.checked ? 'checked' : ''} ${data.disabled ? 'disabled' : ''}>
							<label class="open-radio-label" for="open_${data.id}">${data.title}</label>
						</div>`;
	        }

	        let data = {
	        	id: id,
	        	...item
	        }
	       
	        html += createRadio(data);

	    });

	    dom.addAfter(radioDom, html);

	}


	/**
	 * 动态渲染select
	 * @param options.elem:string 元素容器选择器
	 * @param options.name:string name值
	 * @param options.data:array 渲染数据
	 */
	form.prototype.renderSelect = function(options) {

	    // 获取select元素
	    let selectDom = dom.get(options.elem);

	    // 添加class
	    dom.addClass(selectDom, 'open-select-group');

	    let selected = '';

	    options.data.forEach((item,index)=>{
	    	if(item.selected){
	    		selected = index;
	    	}
	    })

	    // 创建虚拟dom
	    let html = '';
	    options.data.forEach((item, index) => {
	        html += selectOptionDom(item.value,item.title,item.selected);
	    });
	    let container = createSelect(options.name,options.placeholder,html);

	    // 添加dom到页面
	    dom.addAfter(selectDom, container);

	    // js生成虚拟dom元素
	    let vdom = {
	    	select: dom.child(selectDom,'select'), // 原生select标签元素
	    	vselect: dom.child(selectDom,'.open-v-select'), // 虚拟select列表元素
	    	vselectTitle: dom.child(selectDom,'.open-v-select-title'), // 虚拟select选择框
	    	option: dom.childs(selectDom,'.open-v-select-item'), // 虚拟下拉列表元素
	    }
	    

	    // 虚拟选择框添加点击事件
	    vdom.vselect.onclick = function(e){

	    	// 获取下拉列表
	    	let list = dom.child(this,'ul');

	    	// 判断下拉列表是否显示
	    	if(utils.existClass(list,'open-v-select-show')){
	    		// 显示则隐藏
	    		dom.setClass(list,'open-v-select-list open-v-select-hide');
	    	}else{
	    		// 显示则隐藏
	    		hideSelect();
	    		// 隐藏则显示
	    		dom.setClass(list,'open-v-select-list open-v-select-show');

	    	}
	    	e.stopPropagation();
	    }

	    vdom.option.forEach(item=>{

	    	item.onclick = ()=>{

	    		// 隐藏选中状态
	    		vdom.option.forEach(i=>{
	    			dom.setClass(i,'open-v-select-item');
	    		});

	    		// 添加选中状态
	    		dom.addClass(item,'open-v-select-active');

	    		// 修改虚拟选择框文本颜色
	    		dom.addClass(vdom.vselectTitle,'open-vselect-title-active');

	    		// 设置虚拟选择框文本
	    		vdom.vselectTitle.innerText = item.innerText;
	    		
	    		// 设置选中的值到select标签
	    		checkSelect(vdom.select,item.dataset.value)
	    	}
	    });


	    // 设置选中的值到select标签
	    if(selected !== ''){
	    	// 设置虚拟选择框文本
	    	vdom.vselectTitle.innerText = options.data[selected].title;
	    	// 修改虚拟选择框文本颜色
	    	dom.addClass(vdom.vselectTitle,'open-vselect-title-active');
	    	checkSelect(vdom.select,options.data[selected].value);
	    }

	}

	/**
	 * select容器html
	 * @param name:string 选择器name
	 * @param options:html option子元素内容
	*/
	function createSelect(name,placeholder,options){

		let place = placeholder || '请选择';

		return `<select class="open-select select" name="${name}">
				</select>
				<div class="open-select open-v-select">
					<div class="open-v-select-title">${place}</div>
					<ul class="open-v-select-list">${options}</ul>
				</div>`;
	}

	/**
	 * select子元素option
	 * @param value:string option值
	 * @param title:string option文本
	*/
	function selectOptionDom(value,title,selected){
		return `<li class="open-v-select-item ${selected ? 'open-v-select-active' : ''}" data-value="${value}">${title}</li>`;
	}

	/**
	 * 设置select选中值
	*/
	function checkSelect(select,value){

		let createCheck = function(data){
			return `<option value="${data}" selected}></option>`;
		}
		
		select.innerHTML = '';

		dom.addAfter(select,createCheck(value));
	}


	// 关闭select列表
	function hideSelect(){
		// 监听页面点击事件，隐藏展开列表
		let selects = dom.getAll('.open-v-select-list');

		selects.forEach(item=>{
			dom.setClass(item,'open-v-select-list open-v-select-hide');
		})
	}

	/**
	 * 遍历表单子元素获取值
	 * @param formList: 表单子元素列表
	 */
	function loopFormVal(formList) {

	    // 表单数据
	    let data = {};

	    let checkbox = [];

	    // 循环遍历元素获取值
	    formList.forEach((item, index) => {

	        // 判断是否有name属性
	        if (!item.name) {
	            // onLoopEnd(index, formList, function() {
	            //     data.checkbox = checkbox;
	            // })
	            return false;
	        }
	        // 判断input类型
	        switch (item.type) {

	            // 复选框
	            case 'checkbox':
	                // if (item.checked && !existsClass(item, 'open-switch')) {
	                //     checkbox.push(item.value);
	                // }


	                // if (existsClass(item, 'open-switch')) {
	                //     data[item.name] = item.checked;
	                // }

	                if(item.checked){
	                	data[item.name] = item.checked;
	                }
	                break;
	                // 单选框
	            case 'radio':
	                if (item.checked) {
	                    data[item.name] = item.value;
	                }
	                break;
	            default:
	                if (item.type != 'radio' && item.type != 'checkbox') {
	                    data[item.name] = item.value;
	                }
	                break;
	        }

	        // onLoopEnd(index, formList, function() {
	        //     data.checkbox = checkbox;
	        // })
	    })
	    return data;
	}

	export default form;