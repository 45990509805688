/**
 * 进度条插件
*/
import { dom,utils } from './core.js'

class progresser{

	// 插件描述
	static name = "progresser";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发的progress进度条";
	static date = "2024-07-04";

	// 缓存实例
	static #modules = {};

	/**
	 * 静态渲染
	 * @param id:string|null 元素选择器id,或者不填
	*/
	static auto(id){

		let elem = dom.get(id);

		if(elem){
			progresser.#autoSingle(id);
		}else{
			progresser.#autoAll();
		}
	}

	// 渲染指定静态组件
	static #autoSingle(id){

		// 检测id是否是dom对象类型 
		let isDom = id instanceof HTMLElement;

		// 获取元素对象
		let progressElem;

		// 如果id不是dom对象
		if(!isDom){
			progressElem = dom.get(id)
		}else{
			progressElem = id;
		}

		if(!progressElem && !isDom)return false;

		// 检测当前组件是否涉及重复渲染
    	let openuiId = dom.attr(progressElem,'openui-id');
    
    	// 应对vue框架等组件切换重复渲染处理
    	if(openuiId) return false;

    	// 获取元素子组件
    	let childs = progressElem.children;

    	if(childs.length < 1)return false;

        // 获取组件用户自定义id
        let customId = dom.attr(progressElem,'id') || '';

        // 配置参数
        let options = {
        	id: customId, // 用户自定义属性id
        }

        // 创建实例
        progresser.#create(options,progressElem);

	}

	// 自动渲染全部静态组件
	static #autoAll(){

		// 获取页面所有静态组件
        let progressList = dom.getAll('.open-progress');

        // 如果组件数量少于1，不渲染
        if(progressList.length < 1)return false;

        // 遍历静态组件
        progressList.forEach(progress=>{

        	progresser.#autoSingle(progress);

        })
	}

	// 动态渲染
	static render(options){

		let progressElem = dom.get(options.id);

		if(!progressElem)return false;

		// 检测当前组件是否涉及重复渲染
		let openuiId = dom.attr(progressElem,'openui-id');

		// 应对vue框架等组件切换重复渲染处理
		if(openuiId)return false;

        options.elem = progressElem;

        progresser.#create(options);
	}

	/**
	* 创建实例
	* @param options:object 参数
	* @param elem: object 静态组件dom对象
	*/
	static #create(options={},elem=''){

		// 生成组件id
	    const templateId = utils.randomId();

	    /**
		 * 合并参数
		 * @attr data.custom: object 自定义数据
		 * @attr data.system: object 系统数据
		*/
	    const data = {
	    	custom: options,
	    	system: {
	    				moduleId:templateId,
	    				staticElem: elem
	    			}
	    };

	    // 创建实例,保存实例
		progresser.#modules[templateId] = new progresser(data);
	}

	// 构造函数
	constructor(options){

		const that = this;

		// 配置参数
		that.config = {
			id: '', // 容器id
			elem: '', // 容器dom对象
			moduleId: '', // 组件id
			moduleElem: '', // 组件dom对象
			barElem: '',
			value: 0, // 值
			max: 100, // 最大值
			size: '', // sm,lg大小
			type: '', // 默认获取斑马线：striped
			width:'',
			percent: '', // 显示的文本
			static: false,
		}

		// 初始化
		that.#init(options);

		// 渲染
		that.#renderElem();

	}

	// 初始化数据
	#init(options){

		const that = this;

		// 默认参数
		let defaults = {
			id: '', // 容器id
			value: 0, // 值
			max: 100, // 最大值
			size: '', // sm,lg大小
			type: '', // 默认获取斑马线：striped
			percent: '',
		}

		/** 
		 * 解构参数
		 * custom:object 自定义参数
		 * system:object 系统参数
		*/
		let {custom,system} = options;

		// 合并参数
		defaults = utils.merge(defaults,custom);

		// 如果静态组件
		if(system.staticElem){

			// 标记当前组件为静态组件
			that.config.static = true;

			let config = {
				...defaults,
				moduleId: system.moduleId,
				elem: system.staticElem
			}

			// 合并参数到实例配置参数
			that.config = utils.merge(that.config,config);

			// 获取bardom
			that.config.barElem = dom.child(that.config.elem,'.open-progress-bar'); 

			// 获取元素value值
            that.config.value = dom.attr(that.config.barElem,'value') || 0;

            // 获取元素max值
            that.config.max = dom.attr(that.config.barElem,'max') || 100;

            // 获取显示的文本
            that.config.percent = dom.attr(that.config.barElem,'open-progress-percent') || '';

		}else{

			// 合并参数到实例配置参数
			that.config = utils.merge(that.config,{...defaults,...system});

			// 获取元素dom
			that.config.elem = dom.get(that.config.id); 
		}

		// dom元素添加组件id
		dom.attr(that.config.elem,'openui-id',that.config.moduleId);

		// 计算对应的宽度
		that.config.width = Math.floor(parseInt(that.config.value) / parseInt(that.config.max) * 100);
	}

	/**
	 * 动态渲染progress组件
	 * @param options: object 参数[id,size,type,value,max];
	*/
	#renderElem(){
		const that = this;

		// 静态渲染模式下，直接设置bar宽度即可
		if(that.config.static){
			if(that.config.percent !== ''){
				dom.addChild(that.config.barElem,that.#createShowText());
			}
		}else{
			dom.addChild(that.config.elem,that.#createElem());

			that.config.moduleElem = dom.child(that.config.elem,that.config.moduleId);
			that.config.barElem = dom.child(that.config.elem,'.open-progress-bar'); 
		}

		if(that.config.percent !== ''){

			// 设置宽度
			that.config.barElem.style.position = 'relative';
			that.config.elem.style.marginTop = '24px';
		}

		// 设置进度条宽度
		setTimeout(function(){
			that.config.barElem.style.width = `${that.config.width}%`;
		}, 200)

	}

	// 创建dom
	#createElem(){
		const that = this;
		
		// 类型
		let size = '';
		// 判断参数赋值
		switch (that.config.size) {
			case 'sm':
				size = "sm";
				break;
			case 'lg':
				size = "lg";
				break;
			case 'default':
				size = "";
				break;
		};

		let percent = '';
		if(that.config.percent !== ''){
			percent = that.#createShowText();
		}

		return `<div class="open-progress" ${size} ${that.config.type} openui-id="${that.config.moduleId}">
					<div class="open-progress-bar" value="${that.config.value}" max="${that.config.max}">${percent}</div>
				</div>`;

	}

	// 创建显示文本
	#createShowText(){

		const that = this;

		return `<span class="open-progress-percent">${that.config.percent}</span>`;
	}

}

export default progresser;