/**
 * 选项卡
*/
import { dom,utils } from './core.js'

class taber{

	// 插件描述
	static name = "tab";
	static author = "kaijian";
	static version = "v1.1.0";
	static description = "基于js开发的tab选项卡";
	static date = "2024-07-04";

	// 缓存实例
	static #modules = {};

	// 静态组件渲染
	static render(id){

		let elem = dom.get(id);

		if(elem){
			taber.#renderSingle(id);
		}else{
			taber.#renderAll();
		}
	}

	// tab事件监听
	static on(id,fn){

		let tabElem = dom.get(id);

	    if(!tabElem) return false;

	    // 判断回到函数类型
	    if(fn && typeof fn !== 'function') return false; 

	    // 静态模式下获取id
	    let openid = dom.attr(tabElem,'openui-id');

	    // 静态模式未获取，则动态模式下获取
	    if(!openid){
	        openid = dom.attr(dom.child(tabElem,'.open-tab'),'openui-id');
	    }

	    // 获取实例
	    let instance = taber.#modules[openid];

	    // 添加回调函数到实例配置参数
	    instance.config.on = fn;
	}

	// 渲染指定静态组件
	static #renderSingle(id){

		// 检测id是否是dom对象类型 
		let isDom = id instanceof HTMLElement;

		// 获取元素对象
		let tabElem;

		// 如果id不是dom对象
		if(!isDom){
			tabElem = dom.get(id)
		}else{
			tabElem = id;
		}

		if(!tabElem && !isDom)return false;

		// 检测当前组件是否涉及重复渲染
    	let openuiId = dom.attr(tabElem,'openui-id');
    
    	// 应对vue框架等组件切换重复渲染处理
    	if(openuiId) return false;

    	// 获取元素子组件
    	let childs = tabElem.children;

    	if(childs.length < 1)return false;

        // 获取组件用户自定义id
        let customId = dom.attr(tabElem,'id') || '';

        // 配置参数
        let options = {
        	id: customId, // 用户自定义属性id
        }

        // 创建实例
        taber.#create(options,tabElem);
	}

	// 渲染所有静态组件
	static #renderAll(){

		 // 获取页面所有静态组件
	    let tabs = dom.getAll('.open-tab');

	    // 如果页面没有静态组件，则中止渲染
	    if(tabs.length < 0) return false;

	    // 遍历静态组件
	    tabs.forEach(tab=>{

	    	taber.#renderSingle(tab);
	    })
	}

	/**
	* 创建实例
	* @param options:object 参数
	* @param elem: object 静态组件dom对象
	*/
	static #create(options={},elem=''){

		// 生成组件id
	    const templateId = utils.randomId();

	    /**
		 * 合并参数
		 * @attr data.custom: object 自定义数据
		 * @attr data.system: object 系统数据
		*/
	    const data = {
	    	custom: options,
	    	system: {
	    				moduleId:templateId,
	    				staticElem: elem
	    			}
	    };

	    // 创建实例,保存实例
		taber.#modules[templateId] = new taber(data);
	}


	// 构造函数
	constructor(options){

		const that = this;

		// 配置参数
		that.config = {
			id: '', // 元素id
			elem: '', // 元素dom
			moduleId: '', // 组件id
			moduleElem: '', // 组件dom
			elems: [],// 用来记录组件选项下标数量，删除删除和增加的下标值
			titles: [],
			contents: [],
			type: '',// 默认无，tag标签类型
			click: '',
			on:'',
			static: false
		}

		// 初始化
		that.#init(options);

		// 渲染dom
		that.#renderElem();

	}

	// 初始化
	#init(options){

		const that = this;

		// 默认参数
		let defaults = {
			id: '',
			titles:'',// 选项列表
			contents:'',// 内容列表
			click: '',
		}

		/** 
		 * 解构参数
		 * custom:object 自定义参数
		 * system:object 系统参数
		*/
		let {custom,system} = options;

        // 合并用户自定义参数
        defaults = utils.merge(defaults,custom);

        if(system.staticElem){

        	// 当前组件为静态组件
        	that.config.static = true;

        	let config = {
				...defaults,
				moduleId: system.moduleId,
				moduleElem: system.staticElem,
				elem: system.staticElem,

			}

        	that.config = utils.merge(that.config,config); 

        	// 获取title列表
			that.config.titles = dom.childs(that.config.moduleElem,'.open-tab-title li');

			// 获取内容列表
			that.config.contents = dom.childs(that.config.moduleElem,'.open-tab-content .open-tab-content-item');


        }

        // 如果标题数量和内容数量不一样，则不执行
		if(that.config.titles.length !== that.config.contents.length){
			throw "选项数量和内容数量不一样";
			return false;
		};

		// 获取类型 
		that.config.type = dom.attr(that.config.moduleElem,'type') || '';

		// 保存下标
		for(let i = 0; i < that.config.titles.length; i ++){
			that.config.elems.push(i);
		}
	}

	// 单个元素渲染
	#renderElem = function(){

		const that = this;

		// 如果type是标签类型
		if(that.config.type == 'tag'){

			// 组件添加标签类
			dom.addClass(that.config.moduleElem,'open-tab-tag');

			// 遍历标题,添加关闭按钮
			that.config.titles.forEach(title=>{

				// 如果不存在删除图标再添加图标
				if(!dom.child(title,'.open-tab-icon')){
					dom.addChild(title,that.#createIcon());
				}
			})

			// 添加删除选项事件
			that.#onClose();
		}

		// 标题切换监听
		that.#onTab();
		
	}

	// 添加选项点击事件
	#onTab(){

		const that = this;

		// 循环title列表
		that.config.titles.forEach((title,index)=>{

			title.onclick = function(){

				// 去除标题状态
				that.#hideTitlesActive();

				// 添加当前点击选项活跃样式
				dom.addClass(title,'open-tab-title-active');

				// 隐藏所有内容模块
				that.#hideContents();

				// 显示当前内容模块
				dom.addClass(that.config.contents[index],'open-tab-content-active');


				let parentTitle = dom.parent(title); 
				let parentContent = dom.parent(parentTitle); 
				// 回调函数
				if(that.config.click && typeof that.config.click == 'function'){

					that.config.click({
						id: parentContent.id,
						type: 'change',
						index: index
					})
				}

				// 全局回调函数
				that.config.on && that.config.on({
						id: parentContent.id,
						type: 'change',
						index: index
					})
			}
		})
	}

	// 隐藏所有标题选中状态
	#hideTitlesActive(){

		const that = this;

		that.config.titles.forEach(title=>{

			dom.removeClass(title,'open-tab-title-active')
		})
	}

	// 隐藏所有内容模块
	#hideContents(){
		const that = this;
		that.config.contents.forEach(title=>{
			dom.removeClass(title,'open-tab-content-active')
		})
	}		

	// 添加标签关闭按钮点击事件
	#onClose(){

		const that = this;

		// 遍历标题添加图标点击事件
		that.config.titles.forEach((title,index)=>{
			
			// 获取图标元素
			let icon = dom.child(title,'.open-tab-icon');

			// 添加关闭事件
			icon.onclick = function(e){

				// 获取当前选项元素的父元素
				let parent = dom.parent(title);

				// 删除当前元素
				dom.removeChild(parent,title);

				// 获取内容dom容器
				let contentParent = dom.child(that.config.moduleElem,'.open-tab-content');
				
				// 删除对应元素
				dom.removeChild(contentParent,that.config.contents[index]);

				// 从dom下标删除当前删除的下标
				let i = that.config.elems.indexOf(index);
				that.config.elems.splice(i,1);

				// 获取父容器
				let parentContent = dom.parent(parent); 

				// 回调函数
				if(that.config.click && typeof that.config.click == 'function'){

					that.config.click({
						id: parentContent.id,
						type: 'delete',
						index: index
					})
				}

				// 全局回调函数
				that.config.on && that.config.on({
					id: parentContent.id,
					type: 'delete',
					index: index
				})

				// 删除后重新渲染组件，默认显示剩下内容模块的第一个

				// 判断是否还有选中的模块
				let isActive = false;

				// 遍历选项，判断是否有选中的选项
				that.config.elems.forEach(index=>{
					if(utils.existClass(that.config.titles[index],'open-tab-title-active')){
						isActive = true;
					}
				})

				// 如果没有选中的模块且选项数量大于0
				if(isActive === false && that.config.elems.length > 0){

					// 设置默认选中剩余数组中的第一个
					dom.addClass(that.config.titles[that.config.elems[0]],'open-tab-title-active');

					// 设置默认选中剩余数组中的第一个
					dom.addClass(that.config.contents[that.config.elems[0]],'open-tab-content-active');
				}

				e.stopPropagation();
			}
		})
	}


	// 创建图表
	#createIcon(){
		return `<span class="open-tab-icon"></span>`;
	}

}

export default taber;