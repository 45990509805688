/**
 * 插槽组件
*/
const slot = {
	// 保存slot元素
	slotData: {},
	// 添加slot
	add: function(elem){

		// 查找当前元素下符合插槽的元素
		let slots = elem.querySelectorAll('[open-slot]');

		// 如果插槽数量小于0，则停止运行
		if(slots.length < 1) return false;

		// 如果大于0，遍历插槽
		slots.forEach(function(item){

			// 获取插槽元素名字
			let name = item.getAttribute('open-slot');

			// 判断是否有同名插槽
			if(slot.slotData[name]){

				// 控制台打印错误通知
				console.warn(`open-slot警示：插槽名${name},检测到已有同名插槽！<br/>建议重新设置新的插槽名，程序会自动覆盖之前同名插槽`);

			}

			// 保存插槽元素
			slot.slotData[name] = item.outerHTML.replace("open-slot=","");

			// 自动删除插槽
			item.remove();
		})
	},
	// 获取slot
	get: function(name){

		// 获取插槽
		let slotElem = slot.slotData[name];

		// 判断插槽
		if(!slotElem){

			// 控制台打印错误通知
			console.warn(`open-slot警示：插槽名${name},未检测到对应的dom元素,请检测插槽名是否正确？`);
		}
		return slotElem;
	},
	// 向插槽传值
	prop: function(name,data){

		// 获取插槽
		let slotElem = slot.slotData[name];

		if(slotElem){

			let index = slotElem.indexOf('open-slot-value');

			let template = ""

			// 如果查询到
			if(index !== -1){
				
				let startSlot = slotElem.substring(0,index + 17);
				let endSlot = slotElem.substring(index + 17,slotElem.length);

				// 获取值
				let newData = JSON.stringify(data).replace(/"/g, "'");
				
				template = startSlot + newData + endSlot;
			}else{
				// 如果没有open-slot-value,自动添加open-slot-value
				let index = slotElem.indexOf('>');

				let startSlot = slotElem.substring(0,index);
				let endSlot = slotElem.substring(index,slotElem.length);

				// 获取值
				let newData = JSON.stringify(data).replace(/"/g, "'");
				
				// template =  startSlot + ' open-slot-value="' + newData + '"' + endSlot;
				template =  startSlot + endSlot;

			}
			// 替换模版变量
			template = slot.template(template,{slot: data});

			// 转换open-src为src
			template = template.replace(/open-slot-src/g, 'src');

			return template;

		}

	},
	// 模版引擎
	template: function(template,data){

		// 符号
		let symbol = {
			up: '&gt;',
			lo: '&lt;',
		}
		
		 // 判断是否是三元运算符
	    function isTernary(str) {
		  const regex = /^\(?(?<condition>[^?]+)\?(?<expr1>[^:]+):(?<expr2>[^]+)$/;
		  return regex.test(str);
		}

		// 编译三元运算符
	    let compileTernary = function(val,data){
	    	
	    	// 转换符号
	    	val = val.replace(`${symbol.up}`,'>');
	    	val = val.replace(`${symbol.lo}`,'<');

	    	// 分离三元运算符
	    	let getValue = val.split(" ");
	    	// 获取值的字段
	    	let valArr = getValue[0].split(".");
	    	// 获取值
	    	let value = valArr.reduce(function(prev,current){
	        	return prev[current];
	        },data)

	        // 替换值
	        let newVal = val.replace(`${getValue[0]}`, value);

	    	let fn =  new Function('data',`return ${newVal}`);

	    	return fn(data);
	    }

	    // 编译文本
	    let compileText = function(val,data){

	    	// 分离当前传递的匹配值
	        let valArr = val.split('.');

	        // 遍历赋值
	        let value = valArr.reduce(function(prev,current){

	        	let val;

	        	if(current == 'slot'){

	        		// 经过编译的对象数据
	        		val = JSON.stringify(prev[current]).replace(/"/g, "'");

	        	}else{

	        		// 恢复编辑过的数据
	        		let formtValue = JSON.parse(prev.replace(/'/g, '"'));

	        		val = formtValue[current];

	        	}
	        	return val;
	        },data)

	        // 如果没有找到值，或者值是underfine，默认返回字段
	        if(!value){
	        	  if(value !== 0){
		            value = "{{".concat(val, "}}");

		          }
	        }

	        // 返回替换值后的html字段
	        return value;
	    }

	    const compile = function(val){

	    	let html;

	        // 如果是三元运算符
			if(isTernary(val)){
			
				// 编译三元运算符
				html = compileTernary(val,data);

				return html;
			}

			// 编译文本值
			html = compileText(val,data);
			
			return html;

	    }

	    // let rex = /\{\{([^${}]+)\}\}/g;
	    let rex = /\{\{([^${}]+)\}\}|(\[([^\]]+)\])|open-if="([^"]*)"|open-if='([^']*)'/g;

	    return template.replace(rex, function(match, key,key2) {

	    	// 匹配open-if
	    	if(match.indexOf('open-if') !== -1){

	    		match = match.replace(/&quot;/g,'"');

	    		let ifValue = match.substring(9,match.length - 1);

	    		return compile(ifValue);
	    	}
	    	if(key){
	        	return compile(key);
	    	}

	    	if(key2){
	    		key2 = key2.substring(1, key2.length - 1);

	    		return compile(key2);
	    	}
	    });
	},
	// slot添加事件
	addEvent: function(name,fn){

		if(name && typeof name == 'string' && fn && typeof fn == 'function'){
			window[name] = fn;
		}

		setTimeout(function(){

			let slotClickElems = document.querySelectorAll('[open-slot-click]');
			// let slotClickElems = document.querySelectorAll('[open-click]');
			// let slotClickElems = document.querySelectorAll('[@click]');
			
			if(slotClickElems.length < 1)return false;

			slotClickElems.forEach(function(item){

				// 获取静态dom元素，并去除空格和双引号
				let html = item.outerHTML.replace(/\s+|"/g,'');
				// 查找当前元素是否匹配当前事件名

				let index = html.indexOf(`open-slot-click=${name}`);
				let htmlLength = `open-slot-click=${name}`.length;
				if(index != -1){
					let endHtml = html.substring(index + htmlLength,html.length);

					let paramStart = endHtml.indexOf('(');

					// 事件参数
					let param = "";

					// 有参数
					if(paramStart == 0){

						let paramEnd = endHtml.indexOf(')');

						let paramData = endHtml.substring(1,paramEnd);

						// 编译参数
						param = JSON.parse(paramData.replace(/'/g, '"'));

					}

					item.onclick = function(){

						// 传参给事件
						fn(param);
					}

					
				}
			});
		}, 1000)
	}
	/**
	 * 获取传参值
	 * @param target:HTMLElement 目标元素
	*/
	// getProp: function(target){

	// 	let elem = target.closest('[open-slot]');
			
	// 	let value = elem.getAttribute('open-slot-value');

	// 	let data = JSON.parse(value.replace(/'/g, '"'));

	// 	return data;
	// } 
}

// // 自定义插槽功能
// function openSlot(){

// 	// 单例模式
// 	if (!(this instanceof openSlot)) {
//         return false;
//     }
//     if (!openSlot._instance) {

//         // 保存solt元素
// 		this.slot = {};

// 		openSlot._instance = this;
//     }

//     return openSlot._instance;
// }

// /**
//  * 添加插槽
//  * @param elem:HTMLElement dom元素对象
// */
// openSlot.prototype.add = function(elem){

// 	const that = this;

// 	// 查找当前元素下符合插槽的元素
// 	let slots = elem.querySelectorAll('[open-slot]');

// 	// 如果插槽数量小于0，则停止运行
// 	if(slots.length < 1) return false;

// 	// 如果大于0，遍历插槽
// 	slots.forEach(function(slot){

// 		// 获取插槽元素名字
// 		let name = slot.getAttribute('open-slot');

// 		// 判断是否有同名插槽
// 		if(that.slot[name]){

// 			// 控制台打印错误通知
// 			console.warn(`open-slot警示：插槽名${name},检测到已有同名插槽！<br>建议重新设置新的插槽名，程序会自动覆盖之前同名插槽`);

// 		}

// 		// 保存插槽元素
// 		that.slot[name] = slot.innerHTML;

// 		// 自动删除插槽
// 		slot.remove();
// 	})
// }

// // 获取插槽元素
// openSlot.prototype.get = function(name){

// 	const that = this;

// 	// 获取插槽
// 	let slotElem = that.slot[name];

// 	// 判断插槽
// 	if(!slotElem){

// 		// 控制台打印错误通知
// 		console.warn(`open-slot警示：插槽名${name},未检测到对应的dom元素,请检测插槽名是否正确？`);
// 	}
// 	return slotElem;
// }
export default slot;