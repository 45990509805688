/**
 * 标签栏组件
*/
import app from './core.js'

// 工具函数对象
const dom  = app.dom,
	  utils = app.utils;

// 缓存实例
const tagModules = {};

// 视图对象
const tagView = {
	// 创建dom
	createElem: function(options){

		// 创建tag列表
		let list = tagView.createList(options);

		// 返回dom元素
		return tagView.createContainer(list,options.moduleId);
	},
	// 首页dom
	createHome: function(home,type=true){

		let vhome = `<li class="open-tag-item open-tag-active" openui-tag-url="${home.url}">${home.name}</li>`;

		if(!type){
			vhome = `<li class="open-tag-item" openui-tag-url="${home.url}">${home.name}</li>`
		}
		return vhome;
	},
	// 单个标签dom
	createItem: function(data){
		return `<li class="open-tag-item" openui-tag-url="${data.url}">${data.name}<span class="open-icon-close"></span></li>`;
	},
	// 创建选中标签
	createActive: function(data){
		return `<li class="open-tag-item open-tag-active" openui-tag-url="${data.url}">${data.name}<span class="open-icon-close"></span></li>`;
	},
	// 渲染列表
	createList: function(options){

		const data = options.data;

		let list = tagView.createHome(options.home);

		if(data.length < 1) return list;

		// 如果当前tag标签大于1,判断当前路由是否是首页，如果不是，首页清除选中样式
		if(options.currentUrl.name !== options.home.name || options.currentUrl.url !== options.home.url){
			list = tagView.createHome(options.home,false);
		}

		data.forEach(item=>{

			if(item.name === options.currentUrl.name && item.url === options.currentUrl.url){
				list += tagView.createActive(item);
			}else{
				list += tagView.createItem(item);
			}
		})

		return list;
	},
	// 创建容器
	createContainer: function(content,templateId){
		return `<section class="open-tag" openui-id="${templateId}">
					<div class="open-tag-prev"><span class="open-icon-left"></span></div>
        				<div class="open-tag-wrapper">
            				<ul class="open-tag-list">${content}  </ul>
        				</div>
        			<div class="open-tag-next"><span class="open-icon-right"></span></div>
    			</section>`;
	}
}

// tag核心
const tagCore = function(options){

	// 配置参数
	this.config = {
		id: '',
		elem: '',
		moduleId: '',
		moduleElem: '',
		data: [],
		history: [],
		home: {
			name:'首页',
			url: '/'
		},
		currentUrl: {
			name:'',
			url: ''
		},//当前路由
		listElem: '',// tag列表容器
		add: '',
		remove: '',
		click: ''
	}

	// 初始化
	this.init(options);

	// 添加初始路由
	this.config.currentUrl = this.config.home;

	// 初始化路由记录
	this.addHistory(this.config.home);

	// 渲染
	this.render();

	// 添加事件
	this.addEvent();
}

// 初始化
tagCore.prototype.init = function(options){

	const that = this;

	// 默认参数
	let defaults = {
		id: '',
		moduleId: '',
		data: [],
		home: {
			name:'首页',
			url: '/'
		},
		click: ''
	}

	// 合并默认参数
	defaults = utils.merge(defaults,options);

	// 合并配置参数
	that.config = utils.merge(that.config,defaults);

	if(options.data && Array.isArray(options.data)){
		that.config.data = options.data
	};

	// 获取容器对象
	that.config.elem = dom.get(that.config.id);

	// 获取组件对象
	that.config.elem = dom.get(that.config.id);

}

// 渲染
tagCore.prototype.render = function(){

	const that = this;

	let vdom = tagView.createElem(that.config);

	dom.addChild(that.config.elem,vdom);

	// 获取组件对象
	that.config.moduleElem = dom.child(that.config.elem,`[openui-id=${that.config.moduleId}]`);

	// 获取tag列表容器dom对象
	that.config.listElem = dom.child(that.config.elem,'.open-tag-list');

}

// 添加事件
tagCore.prototype.addEvent = function(){

	const that = this;

	that.onTag();
	that.onLeft();
	that.onRight();
	that.onClose();
}

// 添加tag点击事件
tagCore.prototype.onTag = function(){

	const that = this;

	// 获取所有tag元素
	let items = dom.childs(that.config.listElem,'.open-tag-item');

	if(items.length < 1)return false;

	items.forEach((item,index)=>{

		item.onclick = function(){

			// 路由信息
			let itemUrl = {
				url: dom.attr(item,'openui-tag-url'),
				name: item.innerText
			}
		
			// 如果点击路由和当前路由一致，不跳转
			if(itemUrl.name === that.config.currentUrl.name && itemUrl.url === that.config.currentUrl.url)return false;
			
			that.config.currentUrl = itemUrl;

			// 滚动事件
			that.onScroll(item);


			that.reloadList();

			// 重新添加事件
			that.onTag();
			that.onClose();

		}
	})
}

// 添加滚动事件,保持active元素始终在中间区域
tagCore.prototype.onScroll = function(elem){

	const that = this;

	// 当前tag标签的left距离
	let left = elem.offsetLeft;

	// 元素宽度一半
	let width = Math.ceil(elem.offsetWidth / 2);

	// 获取列表left值
	let listLeft = that.config.listElem.offsetLeft;

	// 获取去掉符号-的left值
	listLeft = parseInt(listLeft.toString().replace('-',''));

	// 获取列表容器宽度
	let containerWidth = dom.parent(that.config.listElem).offsetWidth - 60;

	// 居中位置
	let middle = listLeft + Math.ceil(containerWidth / 2);

	// console.log(middle);

	// let positionLeft = 



	// if(listLeft < left && left < middle){
	// 	console.log("显示中")
	// }

}

// 添加right图标事件
tagCore.prototype.onRight = function(container){
	const that = this;
	// 向左按钮
	let left = dom.child(that.config.elem,'.open-tag-next');
	// 添加左移事件
	left.onclick = function(){
		
		// 获取滑动容器
		let container = dom.parent(that.config.elem);
		// 容器宽度
		let containerWidth = container.offsetWidth - 60;
		// 列表宽度
		let listWidth = that.config.listElem.scrollWidth;

		// 如果列表宽度大于容器宽度
		if(listWidth > containerWidth){
			// 差别
			let chabie = listWidth - containerWidth;
			// 每次滑动距离
			let distance = 100;
			// 获取当前元素left值
			let left = that.config.listElem.offsetLeft;
			// 获取去掉符号-的left值
			left = parseInt(left.toString().replace('-',''));
			// 如果差别小于每次滑动的值,滚动距离就等于差别值
			if(chabie < distance){
				that.config.listElem.style.left = `-${chabie}px`;
				return false;
			}
			let juli = chabie - left;
			// 如果left值大于chabie
			if(juli < distance){
				if(juli > 0){
					// 滚动距离就等于差别
					that.config.listElem.style.left = `-${chabie}px`;
				}
				return false;
			}
			// 正常滚动就是left+滚动距离
			that.config.listElem.style.left = `-${distance + left}px`;
		}else{
			// 复位
			that.config.listElem.style.left = '0px';
		}
	}
}
// 添加left图标事件
tagCore.prototype.onLeft = function(container){
	const that = this;
	// 向左按钮
	let right = dom.child(that.config.elem,'.open-tag-prev');
	// 添加左移事件
	right.onclick = function(){
		// 获取滑动容器
		let container = dom.parent(that.config.elem);
		// 容器宽度
		let containerWidth = container.offsetWidth - 60;
		// 列表宽度
		let listWidth = that.config.listElem.scrollWidth;

		// 如果列表宽度大于容器宽度
		if(listWidth > containerWidth){
			// 差别
			let chabie = listWidth - containerWidth;
			// 每次滑动距离
			let distance = 100;
			// 获取当前元素left值
			let left = that.config.listElem.offsetLeft;
			// 获取去掉符号-的left值
			left = parseInt(left.toString().replace('-',''));
			if(left > distance){
				// 正常滚动就是left+滚动距离
				that.config.listElem.style.left = `-${left - distance}px`;
				return false;
			}
			// 复位
			that.config.listElem.style.left = `0px`;
		}else{
			// 复位
			that.config.listElem.style.left = '0px';
		}
	}
}

// 关闭事件
tagCore.prototype.onClose = function(){

	const that = this;

	// 获取所有tag元素
	let items = dom.childs(that.config.listElem,'.open-tag-item');

	if(items.length < 1)return false;

	items.forEach((item,index)=>{

		let closeElem = dom.child(item,'.open-icon-close');

		if(!closeElem)return false;

		// 关闭按钮添加事件
		closeElem.onclick = (e)=>{
			
			// 去除数据
			that.config.data.splice(index - 1,1);

			// 路由信息
			let itemUrl = {
				url: dom.attr(item,'openui-tag-url'),
				name: item.innerText
			}

			// 删除路由记录
			that.removeHistory(itemUrl);

			// 默认显示前一页
			that.config.currentUrl = that.config.history[that.config.history.length - 1];

			// 重新创建tag列表
			that.reloadList();

			// 重新添加事件
			that.onTag();
			that.onClose();

			// 阻止事件冒泡
			e.stopPropagation();
			return false;
		}
	})
}
// 重新渲染tag列表dom
tagCore.prototype.reloadList = function(){

	const that = this;

	// 重新创建tag列表
	let vlist = tagView.createList(that.config);

	that.config.listElem.innerHTML = '';

	dom.addChild(that.config.listElem,vlist);
}

// 添加路由
/**
 * 添加tag标签
 * @param options.name:string 标签名
 * @param options.url :string url名
*/
tagCore.prototype.add = function(options){

	const that = this;

	that.config.currentUrl = {...options};

	// 判断是否存在相同路由，默认不存在
	let hasExists = false;

	// 路由列表大于0条
	if(that.config.data.length > 0){
		// 遍历路由列表
		that.config.data.forEach(item=>{
			// 如果当前路由和路由表内路由重复
			if(item.name === options.name && item.url === options.url){
				// 存在相同路由
				hasExists = true;
			}
		})
	}
	// 如果存在相同路由,不再执行
	if(hasExists) return false;

	// 不存在相同路由，添加路由到列表
	that.config.data.push(options);

	// 重新创建tag列表
	that.reloadList();
	// 重新添加事件
	that.onTag();
	that.onClose();

}

/**
 * 添加历史记录
 * @param router.name:string 路由名
 * @param router.url:string 路径
*/
tagCore.prototype.addHistory = function(router){
	const that = this;
	// 如果当前路由记录大于0
	if(that.config.history.length > 0){
		// 遍历路由内容查看是否与当前添加路由记录相同
		that.config.history.forEach((item,index)=>{
			if(item.name === router.name && item.url === router.url){
				that.config.history.splice(index);
			}
		})
	}
	// 添加新路由到历史记录
	that.config.history.push(router);
}

/**
 * 删除历史记录
 * @param router.name:string 路由名
 * @param router.url:string 路径
*/
tagCore.prototype.removeHistory = function(router){
	const that = this;
	// 如果当前路由记录大于0
	if(that.config.history.length > 0){
		// 遍历路由内容查看是否与当前添加路由记录相同
		that.config.history.forEach((item,index)=>{
			if(item.name === router.name && item.url === router.url){
				that.config.history.splice(index);
			}
		})
	}
}

// tag对象
const tag = {

}

tag.render = function(options){

	if(!options.id || typeof options.id !== 'string') return false;

	options.id = utils.formatId(options.id);

	// 生成组件id
	const templateId = utils.randomId();

	options.moduleId = templateId;

	const instance = new tagCore(options);

	return instance;

}


export default tag;