import app from './core.js'

let dom = app.dom,
	utils = app.utils;

// select下拉框
class select{

	// 插件描述
	static name = "select";
	static author = "kaijian";
	static version = "v1.0.0";
	static description = "基于js开发的select下拉框";
	static date = "2024-06-22";

	// 缓存实例
	static #modules = {};

	// 自动渲染
	static auto(){

		// 获取页面所有静态组件
	    let selectList = dom.getAll('.open-select');

	    // 如果页面没有静态组件，则中止渲染
	    if(selectList.length < 1) return false;

	    // 遍历静态组件
	    selectList.forEach(item=>{

	    	// 根据静态模版下是否有列表子选项，判断是否是静态渲染
	    	let optionList = dom.childs(item,'option');

	  		// 如果列表选项小于1，是动态渲染，不继续渲染
	    	if(optionList.length < 1)return false;

	    	// 检测当前组件是否涉及重复渲染
	    	let openuiId = dom.attr(item,'openui-id');
	    
	    	// 应对vue框架等组件切换重复渲染处理
	    	if(openuiId) return false;

	        // 获取组件用户自定义id
	        let id = dom.attr(item,'id') || '';

	        // 数据
	        let data = [];

	        // 遍历options列表子项
	        optionList.forEach(option=>{
	        	
	         	let item = {
	         		value: dom.attr(option,'value') || '',
	         		text: option.innerText,
	         		selected: option.selected ? true : false,
	         		disabled: option.disabled ? true : false
	         	}

	         	data.push(item);
	        })

	        // 配置参数
	        let options = {
	        	id: id, // 用户自定义属性id
	            data: data
	        }

	        // 创建实例
	        select.#create(options,item);
	    })
	}

	// 动态渲染
	static render(options={}){

		let selectElem = dom.get(options.id);

		if(!selectElem || !options.data)return false;

		// 检测当前组件是否涉及重复渲染
		let openuiId = dom.attr(selectElem,'openui-id');

		// 应对vue框架等组件切换重复渲染处理
		if(openuiId)return false;

	    options.elem = selectElem;

	    select.#create(options);
	}

	// 事件监听
	static on(id,fn){

		let elem = dom.get(id)

		// id获取元素不存在
		if(!id || !elem) return false;

		if(fn && typeof fn !== 'function') return false;

		// 获取组件模版id
		let templateId = dom.attr(elem,'openui-id');

		let instance = select.#modules[templateId];

		instance.config.on = fn;
	}

	/**
	* 创建实例
	* @param options:object 参数
	* @param elem: object 静态组件dom对象
	*/
	static #create(options={},elem=''){

		// 生成组件id
	    const templateId = utils.randomId();

	    /**
		 * 合并参数
		 * @attr data.custom: object 自定义数据
		 * @attr data.system: object 系统数据
		*/
	    const data = {
	    	custom: options,
	    	system: {
	    				moduleId:templateId,
	    				staticElem: elem
	    			}
	    };

	    // 创建实例,保存实例
		select.#modules[templateId] = new select(data);
	}

	// 构造函数
	constructor(options){

		const that = this;

		// 配置参数
		that.config = {
			id: '', // 元素id
			elem: '', // 元素dom
			moduleId: '', // 组件id
			moduleElem: '', // 组件dom
			value: '', // 初始选中值
			static: false, // 是否静态组件
			type: 'multi',//默认单选，只为multi为多选
			data: [], // 数据
			on: '', //
			click: ''
		}

		// 初始化
		that.#init(options);

		// 渲染dom
		that.#renderElem();

		that.#onClick();

		that.#windowHide();
	}

	// 初始化
	#init(options){

		const that = this;

		// 自定义参数
		let defaults = {
			id: '',
			data: [],
			on: '',
			click: ''
		}

		/** 
		 * 解构参数
		 * custom:object 自定义参数
		 * system:object 系统参数
		*/
		let {custom,system} = options;

		// 合并参数
		defaults = utils.merge(defaults,custom);

		// 如果静态组件
		if(system.staticElem){

			// 标记当前组件为静态组件
			that.config.static = true;

			let config = {
				...defaults,
				moduleId: system.moduleId,
				elem: system.staticElem
			}

			// 合并参数到实例配置参数
			that.config = utils.merge(that.config,config);

		}else{

			// 合并参数到实例配置参数
			that.config = utils.merge(that.config,{...defaults,...system});

			// 获取元素dom
			that.config.elem = dom.get(that.config.id); 
		}

		// dom元素添加组件id
		dom.attr(that.config.elem,'openui-id',that.config.moduleId);

	}

	// 渲染dom
	#renderElem(){

		const that = this;

		// 获取dom元素
		let elem = that.#createElem();

		// 动态组件添加原生select内部option列表
		if(!that.config.static){
			that.#createElemOptionList();
		}

		// 添加dom元素到页面
		dom.addAfter(that.config.elem,elem);

		// 静态渲染模式下获取组件元素
		that.config.moduleElem = dom.getAll(`[openui-id=${that.config.moduleId}]`)[1];

		// 设置初始值
		that.config.elem.value = that.config.value;

		// 如果初始化有值，title设置选中样式
		if(that.config.value){
			// 获取组件title元素
			let title = dom.child(that.config.moduleElem,'.open-select-title');
			// 添加title样式
			dom.addClass(title,'open-select-title-active');
		}

	}

	// 动态组件添加默认select组件列表
	#createElemOptionList(){

		const that = this;

		let options = "";

		that.config.data.forEach(item=>{
			options += `<option value="${item.value}"></option>`;
		})

		dom.addChild(that.config.elem,options);
	}

	// 添加事件监听
	#onClick(){
		this.#onSelect();
	}

	// 选择框和列表事件
	#onSelect(){

		const that = this;

		// 获取组件title元素
		let title = dom.child(that.config.moduleElem,'.open-select-title');

		// 获取列表元素
		let list = dom.child(that.config.moduleElem,'.open-select-list');

		// 选择框点击事件
		that.config.moduleElem.addEventListener('click',function(e){

			if(utils.existClass(that.config.moduleElem,'open-select-disabled'))return false;

	    	// 判断下拉列表是否显示
	    	if(utils.existClass(that.config.moduleElem,'open-select-show')){

	    		// 显示则隐藏
	    		that.#hide(that.config.moduleElem);
	    	}else{

	    		// 隐藏则显示
	    		dom.setClass(that.config.moduleElem,'open-select-wrapper open-select-show');
	    	}

	    	e.stopPropagation();
		})

		// 选项点击事件
	    list.onclick = function(e){

	    	let target = e.target;
	    	
	    	// 判断选项是否是选中状态，或者是不可点击状态
	    	if(utils.existClass(target,'open-select-active') || utils.existClass(target,'open-select-option-disabled'))return false;

	    	// 判断当前选项是否有值
	    	// if(dom.attr(target,'open-value')){

	    		let text = target.innerText;

	    		let value = dom.attr(target,'open-value');

	    		// 设置title
	    		title.innerText = text;

	    		that.#initActive();

	    		dom.addClass(target,'open-select-active');

	    		// 如果当前值为空，title框恢复默认样式
	    		if(!value){

	    			dom.removeClass(title,'open-select-title-active');
	    		}else{

	    			// 添加title样式
	    			dom.addClass(title,'open-select-title-active');
	    		}

	    		// 隐藏列表
	    		that.#hide(that.config.moduleElem);

	    		// 设置值
	    		that.config.elem.value = value;

	    		that.config.click && that.config.click({
	    			value: value,
	    			text: text
	    		})

	    		that.config.on && that.config.on({
	    			value: value,
	    			text: text
	    		})
	    	// }

	    	e.stopPropagation();
	    }
	}

	// 全局关闭事件
	#windowHide(){

		const that = this;

		document.querySelector('body').addEventListener('click',function(){
			that.#hide(that.config.moduleElem);
		})
	}

	// 关闭select列表
	#hide(elem){
		dom.addClass(elem,'open-select-hide');
		dom.removeClass(elem,'open-select-show');
	}

	// 初始化选项样式
	#initActive(){

		const that = this;

		// 获取列表元素
		let list = dom.child(that.config.moduleElem,'.open-select-list');

		// 获取列表
		let options = dom.childs(list,'.open-select-option');

		if(options.length < 1) return false;

		// 重新设置类
		options.forEach(item=>{

			// 清除非disabled元素样式
			if(!utils.existClass(item,'open-select-option-disabled')){
				dom.setClass(item,'open-select-option');
			}
		})
	}

	// 创建dom
	#createElem(){

		const that = this;

		// 选框元素
		let title = that.#createTitle(that.config);

		// 列表元素
		let list = that.#createOptionList(that.config.data);

		// 禁用
		let disabled = that.config.elem.disabled ? 'open-select-disabled' : '';

		return `<div class="open-select-wrapper ${disabled}" openui-id="${that.config.moduleId}">${title}${list}</div>`;

	}

	// 创建选框
	#createTitle(options){

		let text = "请选择";

		let title = `<div class="open-select-title">${text}</div>`

		if(options.data.length < 1) return title;

		let isSelected = false;

		options.data.forEach(item=>{
			if(item.selected){
				isSelected = item.text;
			}
		})

		if(isSelected !== false){
			text = isSelected;
		}else{
			text = options.data[0].text || text;
		}

		return `<div class="open-select-title">${text}</div>`
	}

	// 创建option列表
	#createOptionList(data){

		const that = this;

		let optionList = "<ul class='open-select-list'><li class='open-select-not-data'>暂无数据</li></ul>";

		// 如果没有子选项，默认返回
		if(data.length < 1) return optionList;

		let options = "";

		data.forEach(item=>{

			// 设置初始化选中值
			if(item.selected){
				that.config.value = item.value;
			}

			let selected = item.selected ? 'open-select-active' : '';
			let disabled = item.disabled ? 'open-select-option-disabled' : '';
			options += `<li class="open-select-option ${selected} ${disabled}" open-value="${item.value}">${item.text}</li>`;
		})

		optionList = `<ul class="open-select-list">${options}</ul>`;

		return optionList;
	}
}

export default select;