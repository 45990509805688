/**
 * 表单插件
*/
class former{

	// 插件描述
	static name = "former";
	static author = "kaijian";
	static version = "v2.0.0";
	static description = "基于js开发的表单插件";
	static date = "2024-06-10";

	/**
	 * 获取表单数据对象
	 * @param elemOrId: HTMLElement|string 目标dom对象或id
	*/
	static value(elemOrId){

		return former.#getData(elemOrId);

		// // 定义返回数据
		// let result = {
		// 	status: true,
		// 	data: {},
		// 	message: "数据获取成功"
		// }

		// // 检测参数是否是dom对象类型 
		// let isDom = elemOrId instanceof HTMLElement;

		// // 如果不是dom对象
		// if(!isDom){

		// 	// 根据id获取元素对象
		// 	let elem = document.querySelector(elemOrId);

		// 	// 如果元素存在
		// 	if(elem){
		// 		elemOrId = elem;
		// 	}else{
		// 		result.status = false;
		// 		result.message = "未查询到id对象的元素";
		// 	}
		// }

		// // 如果result状态为false
		// if(!result.status){
		// 	return result;
		// }

     	// // 创建FormData数据
     	// let formData =  new FormData(elemOrId);

        // // 获取表单所有组件值
        // for (let [name, value] of formData.entries()) {
        //     result.data[name] = value;
        // }

        // console.log(result)

        // return result;
    }
	// 表单提交
	static onSubmit(id,fn){
	    // promist方式
	    return new Promise((r,j)=>{
	        // 获取form元素
	        let form = document.querySelector(id);

	        // 如果form元素不存在
	        if(!form){

	            // 更新返回状态
	            result.status = false;
	            // 返回信息
	            result.message = "form表单元素不存在";
	        }else{

	            // 表单元素添加提交事件监听
	            form.addEventListener('submit',event=>{

	                // 阻止默认表单跳转
	                event.preventDefault();

	                // 提取表单数据对象
	                result.data = former.value(event.target).data;

	                // 如果回调函数存在，则执行回调函数
	                if(fn && typeof fn === "function"){
	                      fn(result);
	                }

	                // primise响应
	                r(result);
	            })        
	        }
	    }) 
	}

	// 获取表单数据
	static #getData(elemOrId,fn){

		// 定义返回数据
		let result = {
			status: true,
			data: {},
			message: "数据获取成功"
		}

		// 检测参数是否是dom对象类型 
		let isDom = elemOrId instanceof HTMLElement;

		let elem;

		// 如果不是dom对象
		if(!isDom){

			// 根据id获取元素对象
			elem = document.querySelector(elemOrId);

			// 如果元素存在
			if(elem){
				elemOrId = elem;
			}else{
				result.status = false;
				result.message = "未查询到id对象的元素";
			}
		}

		// 如果result状态为false
		if(!result.status){
			return result;
		}

     	let childs = elem.querySelectorAll('[name]');

     	for(let i = 0; i < childs.length; i ++){
     		let child = childs[i];
     		// 获取name
        	let name = child.getAttribute('name');

        	// 处理switch开关
        	if(child.type === 'checkbox' && child.classList.contains('open-switch')){
        		result.data[name] = child.checked;
        	// 处理checkbox复选框
        	}else if(child.type === 'checkbox'){
        		if(!child.checked){
        			continue;
        		}
        		if(result.data[name]){
        			result.data[name].push(child.value);
        		}else{
        			result.data[name] = [child.value];
        		}
        	// 处理单选框
        	}else if(child.type === 'radio'){
        		if(child.checked){
        			result.data[name] = child.value;
        		}else{
        			if(!result.data[name]){
        				result.data[name] = "";
        			}
        		}
        	}else{
        		result.data[name] = child.value;
        	}
     	}

        return result;
	}
}

export default former;