/*
 *  验证码
 *  @param: options  配置参数对象
 *  @param: callback 回调函数，返回验证码数值
*/
import app from './core.js'

// 工具函数对象
const dom = app.dom;
const utils = app.utils;

// 模块核心
const captchaCore = function(options){

    // 配置参数
    this.config = {
        id: '',
        elem: '',
        moduleId:'',
        moduleElem:'',
        ctx: '',//canvas对象
        code:'',//验证码
        fontSize: 30,
        width: 120,
        height: 30,
        bgColor: '#eeeeee',
        lineColor: '#1890ff',
        callback: ''
    }

    // 初始化
    this.init(options);

    // 渲染组件
    this.config.code = this.render();
}

// 初始化
captchaCore.prototype.init = function(options){

    const that = this;

    // 默认参数
    let defaults = {
        id: '',
        fontSize: 30,
        width: 120,
        height: 30,
        bgColor: '#1890ff',
        bgColor: '#eeeeee',
        lineColor: '#1890ff',
        callback: ''
    }

    // 合并默认参数
    defaults = utils.merge(defaults,options);

    // 合并配置参数
    that.config = utils.merge(that.config,defaults);

    // 获取元素容器
    that.config.elem = dom.get(utils.formatId(that.config.id));

    // 设置组件id
    dom.attr('openui-id',that.config.moduleId);

    // 设置组件dom对象
    that.config.moduleElem = that.config.elem;

    // 设置元素鼠标样式
    that.config.elem.style.cursor = 'pointer';

    // 创建画布实例
    that.config.ctx = that.config.elem.getContext("2d");

}

// 渲染组件
captchaCore.prototype.render = function(){

    const that = this;

    // 设置尺寸
    that.setSize();
    // 背景色
    that.setBgColor()
    // 创建验证码
    let code = that.createText()
    // 创建干扰线
    that.createLine()
    // 创建圆圈
    that.createPie()
    // 返回验证码数值
    return code;
}

// 添加点击事件
captchaCore.prototype.onClick = function(){

    const that = this;

    that.config.elem.onclick = function(){
        // 重新绘制验证码
        that.config.code = that.render();
        // 返回验证码数值
        if(that.config.callback && typeof that.config.callback === 'function'){
            that.config.callback(that.config.code);
        }
    }
}

// 获取验证码
captchaCore.prototype.value = function(){
    // 返回代码
    return this.config.code;
}

// 设置背景色
captchaCore.prototype.setBgColor = function(){

    const that = this;

    // 设置背景色
    that.config.elem.style.backgroundColor = that.config.bgColor;
}

// 设置尺寸和像素
captchaCore.prototype.setSize = function(){

    const that = this;

    // 判断标签属性是否设置width
    if(!that.config.elem.getAttribute.width){
        // 判断配置参数是否设置width值，没有默认宽度150
       that.config.elem.setAttribute('width',that.config.width);
    }

    // 如果没有设置height，默认高度50
    if(!that.config.elem.getAttribute.height){
        // 判断配置参数是否设置height值，没有默认宽度50
        that.config.elem.setAttribute('height',that.config.height);
    }

    // 像素纠正
    let dpr = window.devicePixelRatio || window.webkitDevicePixelRatio || window.mozDevicePixelRatio || 1;
    let oldWidth  = that.config.elem.width;
    let oldHeight = that.config.elem.height;

    that.config.elem.width  = Math.round(oldWidth * dpr);
    that.config.elem.height = Math.round(oldHeight * dpr);

    that.config.elem.style.width  = oldWidth + 'px';
    that.config.elem.style.height = oldHeight + 'px';

    that.config.ctx.scale(dpr,dpr);

}   

// 画干扰圆圈
captchaCore.prototype.createPie = function(){

    const that = this;

    for(let i = 0 ; i < 15 ; i ++){
        that.config.ctx.beginPath();
        let width = Math.floor(Math.random() * that.config.elem.width + 1);
        let height = Math.floor(Math.random() * that.config.elem.height + 1);
        
        that.config.ctx.arc(width,height,1,0,2*Math.PI);
        that.config.ctx.fillStyle = '#1890ff';
        that.config.ctx.fill();
        that.config.ctx.stroke()
        that.config.ctx.closePath();
    }
}

// 画文本
captchaCore.prototype.createText = function(){

    const that = this;

    // 根据canvas宽度冬天设置文字间隔位置
    let width = (that.config.elem.width - 20) / 6;
    let text = '';
    let list = [0,'A',1,'B',2,'C',3,'D',4,'E',5,6,7,8,9,];

    // 定义字体大小
    let fontSize = that.config.fontSize || '30';
    that.config.ctx.font=`normal bold ${fontSize}px Arial`;
    that.config.ctx.textBaseline = "middle";

    for(let i = 0; i < 5 ; i ++ ){
        that.config.ctx.beginPath();
        let random = Math.floor(Math.random() * list.length);
        that.config.ctx.fillText(list[random], width * i + 10, that.config.elem.height / 2);
        text += list[random];
        that.config.ctx.stroke();
        that.config.ctx.closePath();
    }

    return text;
}

// 画干扰线
captchaCore.prototype.createLine = function(){

    const that = this;

    // 获取canvas当前宽度,并默认求取3分之的宽度值
    let width = that.config.elem.style.width.slice(0,that.config.elem.style.width.length - 2) / 3;
    // 获取canvas当前高度
    let height = that.config.elem.style.height.slice(0,that.config.elem.style.height.length - 2);

    // 第一条干扰线
    that.config.ctx.beginPath();
    that.config.ctx.moveTo(0,Math.floor(Math.random() * height));  
    that.config.ctx.lineWidth = 2;
    that.config.ctx.bezierCurveTo(width,Math.floor(Math.random() * height ),width * 2,Math.floor(Math.random() * height ),width * 3,Math.floor(Math.random() * height )); 
    that.config.ctx.strokeStyle = that.config.lineColor;    
    that.config.ctx.stroke();   
    that.config.ctx.closePath();

    // 第二条干扰线
    that.config.ctx.beginPath();
    that.config.ctx.moveTo(0,Math.floor(Math.random() * height ));  
    that.config.ctx.lineWidth = 2;
    that.config.ctx.bezierCurveTo(width,Math.floor(Math.random() * height ),width * 2,Math.floor(Math.random() * height ),width * 3,Math.floor(Math.random() * height )); 
    that.config.ctx.strokeStyle = that.config.lineColor;     

    that.config.ctx.stroke();   
    that.config.ctx.closePath();
}


// 模块对象
const captcha = {

}

// 渲染组件
captcha.render = function(options){

    // 创建组件id
    let templateId = utils.randomId();

    // 添加到参数
    options.moduleId = templateId;

    // 创建实例
    let instance = new captchaCore(options);

    // 返回实例
    return instance;
}

// 获取

export default captcha;



//    使用方式
//     let code = captcha.render({
//        id: 'code',
//        fontSize: '20',
//        width: '120',
//        height: '30',
//        bgColor: '#1890ff',
//        lineColor: '#1890ff',
//         callback: function(res){
//             console.log(res)
//         }
//     })
//     // 获取code值
//     code.value();
